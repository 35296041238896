import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
    return (
      <footer className="py-2 mt-auto  ">
        <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
            <Link to={`https://thietkeweb.katec.vn/`} target='_blank' className="text-muted ">Design by Katec</Link>
                <div>
                    <Link className="text-muted" to="#!">Hướng dẫn sử dụng</Link>
                    {/* <span className="text-muted" >&nbsp;|&nbsp;</span>
                    <Link className="text-muted" to="#!">Điều khoản</Link> */}
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Footer;