import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col, Table, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import ModalRegister from "./ModalRegister";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";
import Select from "react-select";
import html2pdf from "html2pdf.js";

class RegisterBlockFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      role: {
        id: "",
        note: "",
        adPriceId: "",
      },
      dataInput: { ...this.props.datarole },
      // dataInput:[],
      isCheckGroup: [],
      // widths: [200, 200]
      width: 700,
      height: 150,
      width1: 950,
      height1: 200,
      listDate: [],
      listDay: [],

      startMonth: this.props?.dateStart
        ? this.props?.dateStart
        : window.location.pathname == "/admin/contract-notification"
        ? new Date()
        : new Date(this.props.datarole?.broadcastStartDate * 1000),
      isSelected: false,
      showModalRegister: false,
      id_Regis: 0,
      isEdit: false,
      idEdit: 0,
      listDatePost: [{ numberdate: 0, date: 0 }],
      listDateRender: [],
      monthChoice: new Date(),
      checkColor: false,
      broadcastCertification: null,
      // listDay: [],
      // listDate: [],
      blockListForCertification: [],
      itemDelete: {},
      listsupplier: [],
      dataSubmit: { ...this.props.datarole },
      checkload: false,
    };
  }

  componentDidMount() {
    // this.showDate(new Date())
    const clearButton = document.querySelector(
      "input[type='month']::-webkit-clear-button"
    );
    if (clearButton) {
      clearButton.style.display = "none";
    }

    this.getBroadcastCastQC();
    this.modalSubmit();
    this.getlistcustomer();
  }

  async getBroadcastCastQC() {
    if (
      window.location.pathname != "/admin/contract-notification" &&
      window.location.pathname != "/admin/schedule-notification-daily"
    ) {
      const response = await axios.get(`contract/broadcast-certification`, {
        params: {
          scheduleId: this.props.datarole?.id,
          month: parseInt(this.state.startMonth / 1000),
        },
      });

      this.setState(
        {
          broadcastCertification: response.data.data,
        },
        () => {
          this.showDate2(this.state.startMonth);
        }
      );
    }
  }

  getRes = (month) => {
    axios
      .get(`/schedule/by-contract/${this.props.datarole.id}?month=${month}`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            dataInput: res.data.data,
            // showRes: true,
            // dataExport: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  checkdayoff = (numberdate) => {
    return new Date(
      this.state.startMonth?.getFullYear(),
      this.state.startMonth?.getMonth(),
      numberdate
    ).getDay();
  };
  soNgayTrongThang(inputStr) {
    try {
      // Chia đầu vào thành tháng và năm
      const [thang, nam] = inputStr.split("/").map(Number);

      // Kiểm tra tháng có hợp lệ hay không
      if (thang < 1 || thang > 12) {
        return "Tháng không hợp lệ";
      }

      // Kiểm tra năm nhuận
      if (thang === 2) {
        if ((nam % 4 === 0 && nam % 100 !== 0) || nam % 400 === 0) {
          return 29; // Năm nhuận có tháng 2 có 29 ngày
        } else {
          return 28; // Năm không nhuận có tháng 2 có 28 ngày
        }
      }

      // Các tháng có 31 ngày
      const thang31Ngay = [1, 3, 5, 7, 8, 10, 12];
      if (thang31Ngay.includes(thang)) {
        return 31;
      }

      // Các tháng còn lại có 30 ngày
      return 30;
    } catch (error) {
      return "Đầu vào không hợp lệ. Xin vui lòng nhập theo định dạng 'tháng/năm'.";
    }
  }
  getlistcustomer = () => {
    var arr = [];
    arr.push({
      value: 0,
      label: "Chọn mã giờ",
      blockQC: "",
      name: "",
    });
    axios
      .get(
        `/ad-price/search?dayApply=${parseInt(
          this.changeStartMonth(this.state.startMonth) / 1000
        )}&dayEnd=${this.changeEndMonth(this.state.startMonth) / 1000}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ checkload: true });
          if (this.props.isSocial) {
            res.data.data.social?.map((e) => {
              arr.push({
                value: e.id,
                label: e.timeCode + " - " + e.name,
              });
            });
          } else if (
            window.location.pathname == "/admin/contract-notification" ||
            window.location.pathname == "/admin/schedule-notification-daily"
          ) {
            res.data.data.notification?.map((e) => {
              arr.push({
                value: e.id,
                label: e.timeCode + " - " + e.name,
                blockQC: e.timeCode,
                name: e.name,
              });
            });
          } else {
            res.data.data.ad?.map((e) => {
              arr.push({
                value: e.id,
                label: e.timeCode + " - " + e.name,
                blockQC: e.timeCode,
                name: e.name,
              });
            });
          }

          this.setState({
            listsupplier: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onResize = (event, { node, size, handle }) => {
    this.setState({ width: size.width, height: size.height });
  };
  onResize1 = (event, { node, size, handle }) => {
    this.setState({ width1: size.width, height1: size.height });
  };
  showDate2 = (month) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });
    this.setState({ listDate: viewDate });
    let arr = this.state.broadcastCertification?.blockList;

    arr?.map((item) => {
      let arrRes = [{ numberdate: 0, date: 0 }];

      viewDate?.map((date) => {
        arrRes?.push({
          numberdate: date.label,
          date:
            this.CheckRes(date.label, item?.broadcastDateList, month) > 0
              ? this.CheckRes(date.label, item?.broadcastDateList, month)
              : "",
        });
        item.arrDate = [...arrRes.filter((i) => i.numberdate > 0)];
      });
    });

    // let a = dataInput
    this.setState({
      blockListForCertification: arr,
    });
  };
  showDate = (month, dataInput) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });
    this.setState({ listDate: viewDate });
    let arr = dataInput?.blockList;
    arr?.map((item) => {
      let arrRes = [{ numberdate: 0, date: 0 }];

      viewDate?.map((date) => {
        arrRes?.push({
          numberdate: date.label,
          date:
            this.CheckRes(date.label, item?.broadcastDateList, month) > 0
              ? this.CheckRes(date.label, item?.broadcastDateList, month)
              : "",
        });
        item.arrDate = [...arrRes.filter((i) => i.numberdate > 0)];
      });
    });

    let a = dataInput;
    this.props.onUpdateItem(a);
    this.setState({ dataInput: a, dataSubmit: dataInput });
  };
  //
  removeDuplicatesByDate(inputArray) {
    const uniqueDates = {};
    const resultArray = inputArray?.filter((item) => {
      if (!uniqueDates[item.date]) {
        uniqueDates[item.date] = true;
        return true;
      }
      return false;
    });
    resultArray?.sort((a, b) => a.date - b.date);

    return resultArray;
  }
  transformDates(inputDates) {
    return inputDates?.map((date, index) => {
      const parts = date?.split("/");
      const formattedDate =
        index < inputDates?.length - 1 ? parts[0] + " ," : date + " ,";

      return this.processString(formattedDate);
    });
  }
  processString(inputString) {
    // Tách chuỗi thành mảng các giá trị
    const valuesArray = inputString?.split(",");

    // Loại bỏ khoảng trắng và giá trị rỗng
    const filteredArray = valuesArray?.filter((value) => value !== "");

    // Gộp lại mảng thành chuỗi mới
    const resultString = filteredArray?.join(" ");

    return resultString;
  }
  changeDateToDay = (date) => {
    var data_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var daysOfWeek = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

    var rs = daysOfWeek[data_.getDay()];

    return rs;
  };
  timestampToDateString = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  timestampToMonthString = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${month}`;
  };
  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (
        data.hasOwnProperty(cellRef) &&
        data[cellRef].t === "s" &&
        data[cellRef].v == targetValue
      ) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };

  extractNumber = (inputString) => {
    const numberPart = inputString.replace(/[^\d]/g, "");
    return parseInt(numberPart, 10);
  };

  numberToWords = (number) => {
    const units = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

    const convertGroup = (num) => {
      const ones = [
        "",
        "một",
        "hai",
        "ba",
        "bốn",
        "năm",
        "sáu",
        "bảy",
        "tám",
        "chín",
      ];
      const teens = [
        "",
        "mười",
        "mười một",
        "mười hai",
        "mười ba",
        "mười bốn",
        "mười lăm",
        "mười sáu",
        "mười bảy",
        "mười tám",
        "mười chín",
      ];

      let words = "";

      const hundred = Math.floor(num / 100);
      const remainder = num % 100;

      if (hundred > 0) {
        words += ones[hundred] + " trăm";
      }

      if (remainder > 0) {
        if (words !== "") {
          words += " ";
        }

        if (remainder < 10) {
          words += ones[remainder];
        } else if (remainder < 20) {
          words += teens[remainder - 10];
        } else {
          const tens = Math.floor(remainder / 10);
          const onesDigit = remainder % 10;
          words += tens > 0 ? `${ones[tens]} mươi` : "";
          words += onesDigit > 0 ? ` ${ones[onesDigit]}` : "";
        }
      }

      return words;
    };

    const numberStr = number.toString();

    if (numberStr === "0") {
      return "không đồng";
    }

    const groups = [];
    for (let i = numberStr.length; i > 0; i -= 3) {
      groups.push(Number(numberStr.slice(Math.max(0, i - 3), i)));
    }

    let result = "";
    for (let i = groups.length - 1; i >= 0; i--) {
      const groupWords = convertGroup(groups[i]);
      if (groupWords !== "") {
        if (result !== "") {
          result += " ";
        }
        result += `${groupWords} ${units[i]}`;
      }
    }

    return this.capitalizeFirstLetter(result + " đồng");
  };
  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  totalValueBroadcastCertification = (broadcastCertification) => {
    let sum = 0;
    broadcastCertification?.map((broadcastItem) => {
      sum +=
        broadcastItem.arrDate.reduce((sum, item) => {
          if (item.date != "") return sum + item.date;
          else return sum;
        }, 0) * broadcastItem?.realPrice;
    });

    return sum;
  };
  soThanhChu(so) {
    const donVi = [
      "",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];
    const hang = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

    if (so === 0) return "Không";

    let chuoi = "";
    let i = 0;

    while (so > 0) {
      let donViHangNghin = so % 1000;
      if (donViHangNghin !== 0) {
        chuoi =
          this.soThanhChuHangNghin(donViHangNghin) +
          " " +
          hang[i] +
          " " +
          chuoi;
      }
      so = Math.floor(so / 1000);
      i++;
    }

    return chuoi.trim() + " đồng";
  }

  soThanhChuHangNghin(so) {
    const donVi = [
      "",
      "một",
      "hai",
      "ba",
      "bốn",
      "năm",
      "sáu",
      "bảy",
      "tám",
      "chín",
    ];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];

    let chuoi = "";

    if (so < 10) {
      chuoi = donVi[so];
    } else if (so < 100) {
      chuoi =
        chuc[Math.floor(so / 10)] + (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    } else if (so < 1000) {
      chuoi =
        donVi[Math.floor(so / 100)] +
        " trăm " +
        chuc[Math.floor((so % 100) / 10)] +
        (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    }

    return chuoi?.trim();
  }
  getDate = (data) => {
    if (data) {
      const date = new Date(data * 1000);
      date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  broadcastCertificationExport = () => {
    const table = document.getElementById("exportBroadcastCertificationRes");
    var ws = XLSX.utils.table_to_sheet(table);
    var ws = XLSX.utils.table_to_sheet(table);

    const applyStyle = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: 12, bold: true },
          alignment: { vertical: "center", horizontal: "center" },
        },
        t: "s",
        v: value,
      };
    };

    function setCellStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "right",
          horizontal: "right",
        },
      };
    }

    function setCellBottomStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
      };
    }

    function setCellItalicStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          italic: true,
        },
      };
    }
    const applyStyleNormal = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: 12 },
        },
        v: value,
      };
    };

    const applyStyleNormalFont = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: 12 },
          alignment: { vertical: "center", horizontal: "center" },
        },
        t: "s",
        v: value,
      };
    };

    const applyStyleSmall = (row, col, value) => {
      ws[`${col}${row}`] = {
        s: {
          ...ws[`${col}${row}`].s,
          font: { name: "Times New Roman", sz: 11.25 },
        },
        v: value,
      };
    };

    applyStyleNormal("6", "C", ws["C6"].v);
    applyStyleNormal("6", "P", ws["P6"].v);

    // Merge A1 to d1 with styling
    ws["!merges"] = ws["!merges"] || [];
    ws["!merges"].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } });
    ws["A1"].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    // Merge A2 to d2 with styling
    ws["!merges"].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 5 } });
    ws["A2"].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    // Merge B3 to E3 with styling
    ws["!merges"].push({ s: { r: 2, c: 0 }, e: { r: 2, c: 5 } });
    ws["A3"].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws["!merges"].push({
      s: { r: 3, c: 0 },
      e: {
        r: 3,
        c:
          4 +
          new Date(
            this.state.startMonth.getFullYear(),
            this.state.startMonth.getMonth() + 1,
            0
          ).getDate(),
      },
    });

    ws[`A4`] = {
      s: {
        font: { name: "Times New Roman", sz: 16, bold: true },
        alignment: { vertical: "center", horizontal: "center" },
      },
      t: "s",
      v: ws["A4"]?.v,
    };

    ws["!merges"].push({
      s: { r: 6, c: 2 },
      e: {
        r: 6,
        c:
          4 +
          new Date(
            this.state.startMonth.getFullYear(),
            this.state.startMonth.getMonth() + 1,
            0
          ).getDate(),
      },
    });
    ws[`C7`] = {
      s: {
        font: { name: "Times New Roman", sz: 12 },
        alignment: {
          wrapText: true,
        },
      },
      t: "s",
      v: ws["C7"]?.v,
    };
    let countDate = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth() + 1,
      0
    ).getDate();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const fontSize = 11.5;
    context.font = fontSize + "px Times New Roman";
    const textWidth = context.measureText(ws["C7"]?.v).width;
    let sizeCol =
      countDate == 31
        ? 576
        : countDate == 30
        ? 547
        : countDate == 29
        ? 547
        : 555;
    let countC7 =
      (textWidth * 17) / sizeCol > 17
        ? Math.ceil(textWidth / sizeCol) * 17
        : 17;

    ws["!rows"][6] = { hpt: countC7 };

    ws["!cols"] = ws["!cols"] || [];

    for (let col = 0; col <= 35; col++) {
      applyStyle(
        10,
        String.fromCharCode(65 + col),
        ws[String.fromCharCode(65 + col) + "10"]?.v
      );

      ws[`${String.fromCharCode(65 + col)}10`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
      };

      if (col >= 5) {
        ws["!cols"][col] = { wch: 2 };

        let colLabel = "";

        if (col <= 25) {
          colLabel = String.fromCharCode(65 + col); // A to Z
        } else {
          const firstLetter = String.fromCharCode(
            65 + Math.floor(col / 26) - 1
          );
          const secondLetter = String.fromCharCode(65 + (col % 26));
          colLabel = firstLetter + secondLetter; // AA to AL
        }

        applyStyle(10, colLabel, ws[colLabel + "10"]?.v);

        ws[`${colLabel}10`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
        };
      }
    }

    ws["!cols"][0] = { wch: 22 };
    ws["!cols"][4] = { wch: 11 };
    ws["!cols"][1] = { wch: 15 };
    ws["!cols"][2] = { wch: 3 };
    ws["!rows"][9] = { hpt: 22 };

    const colContractValueLabel = this.findCellByValue(ws, "TỔNG GIÁ TRỊ:");
    const discountValueLabel = this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:");
    const paymentValueLabel = this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:");

    setCellStyle(colContractValueLabel);
    setCellStyle(discountValueLabel);
    setCellStyle(paymentValueLabel);
    setCellStyle(
      `E` + this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))
    );
    setCellStyle(
      `E` + this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))
    );
    setCellStyle(
      `E` + this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))
    );
    setCellItalicStyle(
      `A` +
        (this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")) +
          1)
    );

    setCellBottomStyle(this.findCellByValue(ws, "ĐẠI DIỆN BÊN A"));
    setCellBottomStyle(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG"));
    setCellBottomStyle(this.findCellByValue(ws, "ĐẠI DIỆN BÊN B"));

    const resentativeA = this.extractNumber(
      this.findCellByValue(ws, "ĐẠI DIỆN BÊN A")
    );
    const resentativeAName =
      this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN BÊN A")) + 5;

    ws["!merges"].push({
      s: { r: resentativeA - 1, c: 0 },
      e: { r: resentativeA - 1, c: 3 },
    });

    ws["!merges"].push({
      s: { r: resentativeAName - 1, c: 0 },
      e: { r: resentativeAName - 1, c: 3 },
    });

    const creatorTable = this.extractNumber(
      this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")
    );
    const creatorTableName =
      this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) + 5;

    ws["!merges"].push({
      s: { r: creatorTable - 1, c: 5 },
      e: { r: creatorTable - 1, c: 14 },
    });

    ws["!merges"].push({
      s: { r: creatorTableName - 1, c: 5 },
      e: { r: creatorTableName - 1, c: 14 },
    });

    const resentativeB = this.extractNumber(
      this.findCellByValue(ws, "ĐẠI DIỆN BÊN B")
    );
    const resentativeBName =
      this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN BÊN B")) + 5;

    ws["!merges"].push({
      s: { r: resentativeB - 1, c: 23 },
      e: { r: resentativeB - 1, c: 32 },
    });

    ws["!merges"].push({
      s: { r: resentativeBName - 1, c: 23 },
      e: { r: resentativeBName - 1, c: 32 },
    });

    applyStyleNormalFont(resentativeA, "A", ws["A" + resentativeA]?.v);
    applyStyleNormalFont(
      resentativeA + 5,
      "A",
      ws["A" + (resentativeA + 5)]?.v
    );
    applyStyleNormalFont(creatorTable, "F", ws["F" + creatorTable]?.v);
    applyStyleNormalFont(
      creatorTable + 5,
      "F",
      ws["F" + (creatorTable + 5)]?.v
    );
    applyStyleNormalFont(resentativeB, "X", ws["X" + resentativeB]?.v);
    applyStyleNormalFont(
      resentativeB + 5,
      "X",
      ws["X" + (resentativeB + 5)]?.v
    );
    ws[`U${resentativeB - 1}`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        italic: true,
      },
    };

    for (let i = 1; i <= resentativeB + 4; i++) ws["!rows"].push({ hpt: "" });
    const count =
      ws["X" + (resentativeB + 5)]?.v.length <= 19
        ? 19
        : ws["X" + (resentativeB + 5)]?.v.length;
    ws["!rows"].push({ hpt: (count * 17) / 19 });

    ws[`X` + (resentativeB + 5)].s = {
      ...ws[`X` + (resentativeB + 5)].s,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
    };

    const startPosition = { r: 9, c: 0 };
    const endPosition = {
      r: this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:")) - 2,
      c: 38,
    }; // Assuming AL corresponds to column 38

    // Add borders to all cells in the range from A9 to AL15
    for (let row = startPosition.r; row <= endPosition.r; row++) {
      for (let col = startPosition.c; col <= endPosition.c; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

        if (ws[cellRef]?.v == "empty") {
          // Delete the cell
          ws[cellRef].v = "";
        }
        // Ensure cell exists
        if (!ws[cellRef]) {
          ws[cellRef] = {};
        }

        // Apply borders to the cell
        ws[cellRef].s = {
          font: {
            name: "Times New Roman",
          },
          ...ws[cellRef].s,
          border: {
            top: { style: "hair", color: { rgb: "000000" } },
            bottom: { style: "hair", color: { rgb: "000000" } },
            left: { style: "hair", color: { rgb: "000000" } },
            right: { style: "hair", color: { rgb: "000000" } },
            inside: { style: "hair", color: { rgb: "000000" } },
          },
          outline: true,
          alignment: {
            wrapText:
              cellRef == XLSX.utils.encode_cell({ r: 9, c: col })
                ? false
                : true,
            vertical: "center",
            horizontal:
              row == 9
                ? "center"
                : col == 0 || col == 1
                ? "left"
                : col == 3 || col == 4
                ? "right"
                : "center",
          },
        };

        if (col === 3 || col === 4) {
          ws[cellRef].z = "#,###,###,###";
          delete ws[cellRef].w;
          XLSX.utils.format_cell(ws[cellRef]);
        }
      }
    }

    ws[`E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`] = {
      ...ws[
        `E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`
      ],
      f: `=SUM(E11:E${this.state.blockListForCertification.length + 10})`,
      s: {
        ...ws[
          `E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`
        ].s,
      },
    };

    ws[`E${this.extractNumber(this.findCellByValue(ws, "TỔNG GIÁ TRỊ:"))}`].z =
      "#,###,###,###";

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].v = ws[
      `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].v
      ? ws[
          `E${this.extractNumber(
            this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:")
          )}`
        ].v + "%"
      : "";

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].s = {
      ...ws[
        `E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
      ].s,
    };

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`
    ].z = "#,###,###,###";

    ws[
      `E${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`
    ] = {
      ...ws[
        `E${this.extractNumber(
          this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")
        )}`
      ],
      f: `=E${this.extractNumber(
        this.findCellByValue(ws, "TỔNG GIÁ TRỊ:")
      )} - E${this.extractNumber(
        this.findCellByValue(ws, "TỔNG GIÁ TRỊ:")
      )}*E${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`,
      s: {
        ...ws[
          `E${this.extractNumber(
            this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")
          )}`
        ].s,
      },
    };

    applyStyleSmall("7", "C", ws["C7"].v);
    applyStyleSmall("8", "E", ws["E8"].v);
    applyStyleSmall("8", "H", ws["H8"].v);
    applyStyleSmall("8", "P", ws["P8"].v);

    ws["!cols"] = [
      { wch: 19.78 },
      {
        wch:
          countDate == 31
            ? 11.44
            : countDate == 30
            ? 14.1
            : countDate == 29
            ? 12.33
            : 11.33,
      },
      // 31:11.44
      { wch: 2.33 },
      { wch: 11.78 },
      { wch: 11.78 },
    ];

    for (let i = 0; i < countDate; i++)
      countDate == 31
        ? ws["!cols"].push({ wch: 1.7 })
        : countDate == 30
        ? ws["!cols"].push({ wch: 1.8 })
        : countDate == 29
        ? ws["!cols"].push({ wch: 1.9 })
        : ws["!cols"].push({ wch: 2 });
    ws["!margins"] = {
      left: 0.8 / 2.54,
      right: 0.8 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };

    return ws;
  };

  timestampToYearString = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();

    return `${year}`;
  };
  listCustomerExport = () => {
    const table = document.getElementById("exportBroadcastQC");
    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D"];

    ws["!cols"] = [{ wch: 15 }, { wch: 10.22 }, { wch: 52.67 }, { wch: 12.67 }];

    ws["!rows"] = [
      // { hpt: 30 },
    ];
    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 14,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A2`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 12,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A4`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A5`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A7`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`A8`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`A9`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`A10`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`C7`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`C8`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`C9`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };
    ws[`C10`].s = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
    };

    for (
      let i = 12;
      i <= 12 + this.state.broadcastCertification?.blockList?.length + 1;
      i++
    ) {
      colAlpha?.map((item) => {
        ws[`${item}${i}`].s = {
          font: {
            name: "Times New Roman",
            bold:
              i == 12 ||
              i == 12 + this.state.broadcastCertification?.blockList?.length + 1
                ? true
                : false,
            sz: 11,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal:
              i == 12 + this.state.broadcastCertification?.blockList?.length + 1
                ? "left"
                : "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      });
    }

    ws[
      `A` + (12 + this.state.broadcastCertification?.blockList?.length + 3)
    ].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 11,
      },
      alignment: {
        // wrapText: true,
        vertical: "center",
        horizontal: "left",
      },
    };
    ws[
      `C` + (12 + this.state.broadcastCertification?.blockList?.length + 3)
    ].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 11,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "left",
      },
    };
    ws[
      `C` + (12 + this.state.broadcastCertification?.blockList?.length + 5)
    ].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 11,
      },
      alignment: {
        // wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[
      `C` + (12 + this.state.broadcastCertification?.blockList?.length + 10)
    ].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 11,
      },
      alignment: {
        // wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[
      `D` +
        (this.extractNumber(
          this.findCellByValue(ws, "Số lần phát (No, of Spots)")
        ) +
          this.state.broadcastCertification?.blockList?.length +
          1)
    ].s = {
      font: {
        name: "Times New Roman",
        bold: true,
        sz: 11,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    const mergePositions = [
      { s: { c: 0, r: 0 }, e: { c: 3, r: 0 } },
      { s: { c: 0, r: 1 }, e: { c: 3, r: 1 } },
      { s: { c: 0, r: 3 }, e: { c: 3, r: 3 } },
      { s: { c: 0, r: 4 }, e: { c: 3, r: 4 } },
      { s: { c: 0, r: 5 }, e: { c: 3, r: 5 } },
      { s: { c: 0, r: 6 }, e: { c: 1, r: 6 } },
      { s: { c: 2, r: 6 }, e: { c: 3, r: 6 } },
    ];

    this.state.broadcastCertification?.blockList?.forEach((_) => {
      mergePositions.push({ s: { c: 0, r: 0 }, e: { c: 3, r: 0 } });
    });

    mergePositions.push({
      s: { c: 0, r: 12 + this.state.broadcastCertification?.blockList?.length },
      e: { c: 2, r: 12 + this.state.broadcastCertification?.blockList?.length },
    });

    // Gộp các ô
    ws["!merges"] = mergePositions;

    ws["!margins"] = {
      left: 1.0 / 2.54,
      right: 1.0 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };
    return ws;
  };

  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (
        data.hasOwnProperty(cellRef) &&
        data[cellRef].t === "s" &&
        data[cellRef].v == targetValue
      ) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };

  extractNumber = (inputString) => {
    const numberPart = inputString.replace(/[^\d]/g, "");
    return parseInt(numberPart, 10);
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
  };
  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa lịch phát sóng?",
      text: `"${item.adPrice?.timeCode}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios.put(`/block/${item?.id}/delete`).then((res) => {
          if (res.data.status === 200) {
            if (
              window.location.pathname != "/admin/contract-notification" &&
              window.location.pathname != "/admin/schedule-notification-daily"
            ) {
              this.getBroadcastCastQC();
            }
            toast(res.data.message, { type: "success", autoClose: 1000 });
            // this.getRoles();
            this.modalSubmit();
          } else {
            toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
          }
        });
      }
    });
  };

  handleHide = () => {
    return this.props.onHide();
  };

  CheckRes(numberdate, arrRes, startMonth) {
    let count = 0;
    for (var i = 0; i < arrRes?.length; i++) {
      if (
        new Date(arrRes[i].date * 1000).getDate() == numberdate &&
        new Date(arrRes[i].date * 1000).getMonth() == startMonth?.getMonth() &&
        new Date(arrRes[i].date * 1000).getFullYear() ==
          startMonth?.getFullYear()
      ) {
        count++;
      }
    }

    return count;
  }
  modalClose = () => {
    this.setState({ showModalRegister: false });
    this.getlistcustomer();
  };
  modalSubmit = () => {
    this.setState({ showModalRegister: false });
    this.getlistcustomer();

    axios
      .get(
        `/schedule/${
          this.props.idRes ? this.props.idRes : this.state.dataInput?.id
        }?month=${parseInt(this.state.startMonth / 1000)}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          // this.setState({ dataInput: res.data.data });
          this.showDate(this.state.startMonth, res.data.data);
        }

        // this.showDate(this.state.monthChoice)
      });
  };
  sumSpot() {
    let count = 0;
    this.state.dataInput?.blockList?.map(
      (item) => (count += this.sumSpotbyitem(item?.arrDate))
    );
    return count;
  }
  sumValue() {
    let sum = 0;
    this.state.dataInput?.blockList?.map(
      (item) => (sum += this.sumSpotbyitem(item?.arrDate) * item?.realPrice)
    );
    return sum;
  }
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
  checkEdit(id) {
    return id == this.state.idEdit;
  }
  updateBlock(id) {
    this.setState({ checkload: true });
    let arr = this.state.dataInput?.blockList?.filter((e) => e.id == id);
    let arrDatePost = [];
    arr[0]?.arrDate?.map((item) => {
      arrDatePost?.push({
        amount: Number(item.date),
        date:
          new Date(
            this.state.startMonth.getFullYear(),
            this.state.startMonth.getMonth(),
            item.numberdate
          ) / 1000,
      });
    });

    let data = {
      realDuration: this.state.dataInput?.blockList?.filter(
        (e) => e.id == id
      )[0]?.realDuration,
      note: this.state.dataInput?.blockList?.filter((e) => e.id == id)[0]?.note,
      content: this.state.dataInput?.blockList?.filter((e) => e.id == id)[0]
        ?.content,
      amount: Number(
        this.state.dataInput?.blockList?.filter((e) => e.id == id)[0]?.amount
      ),
      dates: arrDatePost,
      adPriceId: this.state.dataInput?.blockList?.filter((e) => e.id == id)[0]
        ?.adPrice?.id,
    };
    // swal({
    //   title: "Bạn muốn cập nhật lịch phát sóng?",
    //   icon: "error",
    //   buttons: ["Đóng", "Cập nhật"],
    //   dangerMode: true,
    // }).then((ok) => {
    //   if (ok)
    axios
      .put(`/block/${id}/update`, data)
      .then(async (res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });

          toast(`${res.data.message}`, {
            type: "success",
            autoClose: 1000,
          });
          const { onUpdateItem } = this.props;
          onUpdateItem(this.state.dataInput);

          // return this.props.submit();
          this.modalSubmit();
          this.showDate(this.state.startMonth, this.state.dataInput);
          if (
            window.location.pathname == "/admin/contract-notification" ||
            window.location.pathname == "/admin/schedule-notification-daily"
          ) {
            const response = await axios.get(
              `contract/broadcast-certification`,
              {
                params: {
                  adBlockId: id,
                  month: parseInt(this.state.startMonth / 1000),
                },
              }
            );

            this.setState(
              {
                broadcastCertification: response.data.data,
              },
              () => {
                this.showDate2(this.state.startMonth);
              }
            );
          } else {
            this.getBroadcastCastQC();
            this.showDate2(this.state.startMonth);
          }
        } else {
          this.setState({ err: res.data.errorMessage });
          toast(`${res.data.errorMessage}`, {
            type: "error",
            autoClose: 1000,
          });
          this.modalSubmit();
          this.renderTableRegister();
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
    this.setState({ dataInput: { ...this.props.datarole } });
    // });
  }
  changeNumbertoDate = (number) => {
    return;
  };
  renderTableRegister() {
    var arr = this.state.listDateRender;

    this.state.dataInput?.blockList?.map((itemBlock) => {
      this.state.listDate?.map((item) => {
        arr.push({
          numberdate: item.label,
          date: this.CheckRes(
            item.label,
            itemBlock?.broadcastDateList,
            this.state.startMonth
          ),
        });
        this.setState({ listDateRender: arr });
      });
    });
  }
  loaiBoTrung(mang) {
    let uniqueElements = {};
    mang.forEach((item) => {
      uniqueElements[item.numberdate] = item;
    });
    // Chuyển đổi lại thành mảng
    let result = Object.values(uniqueElements);
    return result;
  }
  changeMonth(date) {
    let data = new Date(date * 1000);

    if (date > 0) {
      return (
        data.getFullYear() + "-" + String(data.getMonth() + 1).padStart(2, "0")
      );
    }
  }
  sumSpotbyitem(itemArr) {
    let sum = 0;
    itemArr?.map((item) => {
      if (item.date != "") sum += item.date;
    });
    return sum;
  }
  exportCNPS = () => {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      window.location.pathname == "/admin/contract-notification" ||
        window.location.pathname == "/admin/schedule-notification-daily"
        ? this.broadcastCertificationExport()
        : this.listCustomerExport(),
      "Chứng nhận phát sóng"
    );

    XLSX.writeFile(
      wb,
      `CNPS_${this.state.broadcastCertification?.contractCode?.replaceAll(
        "/",
        "."
      )}.xlsx`
    );
  };

  async exportPdfBroadcast() {
    const element = document.getElementById("exportBroadcastCertificationPdf2");

    const clonedElement = element.cloneNode(true);

    clonedElement.classList.remove("d-none");

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `CNPS_${this.state.broadcastCertification?.contractCode?.replaceAll(
        "/",
        "."
      )}.pdf`,

      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "landscape",
      },
      html2canvas: {
        dpi: 1000,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      image: { type: "jpeg", quality: 1 },
    };

    await html2pdf().from(clonedElement).set(opt).save();
  }
  customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "unset !important",
      height: "24px",
      color: state.isFocused ? "yellow" : "red",
      boxShadow: state.isFocused ? "0 0 0 4px rgba(199, 246, 255, 1)" : null,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      position: "unset",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  calculateTotalBroadcastLength(data) {
    const totalLength = data?.blockList?.reduce(
      (sum, item) => sum + item.broadcastDateList.length,
      0
    );

    return totalLength;
  }

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="xl"
          backdrop="static"
          keyboard={false}
          className="modal-res"
          //  fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {other.modal.title} - {this.props.datarole?.calendarCode}
            </Modal.Title>
            {new Check().permission(["67"]) ? (
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right", marginLeft: "0.8rem" }}
                onClick={() => this.setState({ showModalRegister: true })}
              >
                <i className="fa-solid fa-plus"></i> Thêm Block
              </Button>
            ) : null}
            {new Check().permission(["69"]) ? (
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.handleDelete(this.state.itemDelete)}
                className="ms-2"
                // hidden={this.checkEdit(itemBlock?.id)}
                // hidden={this.state.isEdit}
                title="Xóa đăng ký phát sóng"
                disabled={!this.state.itemDelete?.id}
              >
                <i className="fas fa-trash"></i> Xóa Block
              </Button>
            ) : null}
            <span className="m-1">
              {this.state.startMonth
                ? `Tháng ${
                    this.state.startMonth?.getMonth() + 1
                  }-${this.state.startMonth?.getFullYear()}`
                : null}
            </span>
            <input
              type="month"
              locale="vi"
              name="month"
              // value={this.state.startMonth ? this.state.startMonth.toISOString().slice(0, 7) : ""}
              // max={this.changeMonth(this.state.dataInput.broadcastEndDate)}
              // min={this.changeMonth(this.state.dataInput.broadcastStartDate)}
              style={{
                border: "none",
                width: 36,
                height: 27,
                backgroundColor: "rgb(16,108,252)",
                borderRadius: 5,
                padding: 0,
              }}
              className="ms-2 hidden-clear-button"
              onChange={(e) => {
                if (e.target.value) {
                  const [year, month] = e.target.value.split("-");

                  this.setState(
                    { startMonth: new Date(year, month - 1) },
                    () => this.modalSubmit()
                    // this.showDate(new Date(year, month - 1), this.state.dataInput)
                  );
                } else {
                  // const [year, month] = e.target.value.split('-')
                  // this.setState({ startMonth: new Date(year, month - 1) })
                  // this.showDate(new Date(year, month - 1), this.state.dataInput)
                }
              }}
            />

            {/* {window.location.pathname == "/admin/contract-notification" ? ( */}
            <Button
              type="button"
              variant="success"
              size="sm"
              disabled={this.state.broadcastCertification == null}
              style={{ float: "right", marginLeft: "0.8rem" }}
              onClick={() => this.exportCNPS()}
            >
              <i className="fa-solid fa-file-export"></i> Xuất CNPS
            </Button>

            {window.location.pathname == "/admin/contract-notification" ||
            window.location.pathname == "/admin/schedule-notification-daily" ? (
              <Button
                type="button"
                variant="danger"
                size="sm"
                disabled={this.state.broadcastCertification == null}
                style={{ float: "right", marginLeft: "0.8rem" }}
                onClick={() => this.exportPdfBroadcast()}
              >
                <i className="fa-solid fa-file-export"></i> Xuất CNPS (PDF)
              </Button>
            ) : (
              ""
            )}
          </Modal.Header>

          <Form>
            <Modal.Body>
              <Row>
                <div className="d-flex">
                  <div
                    style={{
                      overflow: "scroll",
                      paddingLeft: "0",
                      marginLeft: "-8px",
                      zIndex: 2,

                      maxHeight: "700px",
                      minHeight: "100px",
                    }}
                  >
                    <Table
                      bordered
                      striped
                      style={{ border: "1px solid" }}
                      className="table-res"
                      responsive
                    >
                      <thead
                        style={{
                          position: "sticky",
                          background: "#fff",
                          top: 0,
                        }}
                      >
                        <tr
                          style={{
                            height: "29.1px !important",
                            backgroundColor: "pink !important",
                          }}
                        >
                          <th
                            colSpan={
                              window.location.pathname ==
                                "/admin/contract-notification" ||
                              window.location.pathname ==
                                "/admin/schedule-notification-daily"
                                ? 9
                                : 6
                            }
                            style={{ height: "34px" }}
                          >
                            Thời điểm
                            {window.location.pathname ==
                              "/admin/contract-notification" ||
                            window.location.pathname ==
                              "/admin/schedule-notification-daily"
                              ? " thông báo"
                              : " quảng cáo"}
                          </th>
                          {this.state.listDay?.map((item) => {
                            return (
                              <th
                                className={`${
                                  item.label == "T7" || item.label == "CN"
                                    ? "dayoffbg"
                                    : ""
                                }`}
                              >
                                {item.label}
                              </th>
                            );
                          })}
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          <th
                          //  style={{width:'180px'}}
                          >
                            Sửa
                          </th>
                          <th>Mã giờ &emsp;&emsp;&emsp; &emsp;&emsp;&ensp;</th>
                          <th>Block QC</th>
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th>Giá đv </th>
                          ) : null}
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th>SL </th>
                          ) : null}
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? null : (
                            <th>Ghi chú</th>
                          )}
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th>TL</th>
                          ) : null}
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th>Nội dung thông báo </th>
                          ) : null}
                          <th>Spots</th>
                          <th> Đơn giá</th>
                          {this.state.listDate?.map((item) => {
                            return (
                              <th
                                className={`${
                                  this.checkdayoff(item.value) == 6 ||
                                  this.checkdayoff(item.value) == 0
                                    ? "dayoffbg"
                                    : ""
                                }`}
                              >
                                {item.label}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataInput?.blockList?.map((itemBlock) => {
                          return (
                            <tr
                              style={{
                                background:
                                  itemBlock?.id == this.state.checkColor
                                    ? "rgba(0, 123, 255, 0.2)"
                                    : "",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              onClick={async () => {
                                this.setState(
                                  {
                                    checkColor: itemBlock.id,
                                    itemDelete: itemBlock,
                                  },
                                  () => {}
                                );
                                if (
                                  window.location.pathname ==
                                    "/admin/contract-notification" ||
                                  window.location.pathname ==
                                    "/admin/schedule-notification-daily"
                                ) {
                                  const response = await axios.get(
                                    `contract/broadcast-certification`,
                                    {
                                      params: {
                                        adBlockId: itemBlock.id,
                                        month: parseInt(
                                          this.state.startMonth / 1000
                                        ),
                                      },
                                    }
                                  );

                                  this.setState(
                                    {
                                      broadcastCertification:
                                        response.data.data,
                                    },
                                    () => {
                                      this.showDate2(this.state.startMonth);
                                    }
                                  );
                                }
                              }}
                            >
                              <td
                                style={{
                                  whiteSpace: "nowrap",
                                  display: "flex",
                                  alignItems: "center",
                                  background:
                                    itemBlock?.id == this.state.checkColor
                                      ? "rgba(0, 123, 255, 0.2)"
                                      : "",
                                }}
                              >
                                {/* <Button
                                  size="sm"
                                  variant="danger"
                                  type="button"
                                  onClick={() => this.handleDelete(itemBlock)}
                                  style={{
                                    padding: "0 5px ",
                                    height: "20px",
                                    marginTop: "-5px",
                                  }}
                                  hidden={this.checkEdit(itemBlock?.id)}
                                  // hidden={this.state.isEdit}
                                  title="Xóa đăng ký phát sóng"
                                  //  disabled={item.product?.isNotDelete}
                                >
                                  <i className="fas fa-trash"></i>
                                </Button> */}
                                {new Check().permission(["70"]) ? (
                                  <Button
                                    size="sm"
                                    variant="warning"
                                    type="button"
                                    onClick={() =>
                                      this.setState({
                                        idEdit: itemBlock.id,
                                        isEdit: true,
                                      })
                                    }
                                    style={{
                                      padding: "0 5px ",
                                      height: "20px",
                                      marginTop: "-5px",
                                      marginLeft: "0px",
                                    }}
                                    hidden={this.checkEdit(itemBlock?.id)}
                                    // hidden={this.state.isEdit}
                                    title="Cập nhật đăng ký phát sóng"
                                    //  disabled={item.product?.isNotDelete}
                                  >
                                    <i className="fas fa-pencil"></i>
                                  </Button>
                                ) : null}
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  type="button"
                                  onClick={() =>
                                    this.setState(
                                      {
                                        idEdit: "",
                                        isEdit: false,
                                        listDatePost: [
                                          { numberdate: 0, date: 0 },
                                        ],
                                        // dataInput: { ...this.props.datarole },
                                        dataInput: { ...this.state.dataSubmit },
                                      },
                                      () =>
                                        this.showDate(
                                          this.state.startMonth,
                                          this.state.dataInput
                                        )
                                    )
                                  }
                                  style={{
                                    padding: "0 5px ",
                                    height: "20px",
                                    marginTop: "-5px",
                                  }}
                                  // hidden={!this.state.isEdit}
                                  hidden={!this.checkEdit(itemBlock?.id)}
                                  title="Hủy cập nhật"
                                  //  disabled={item.product?.isNotDelete}
                                >
                                  <i className="fas fa-left-long"></i>
                                </Button>
                                <Button
                                  size="sm"
                                  variant="success"
                                  type="button"
                                  onClick={() => {
                                    this.setState({
                                      idEdit: "",
                                      isEdit: false,
                                    });
                                    this.updateBlock(itemBlock.id);
                                  }}
                                  style={{
                                    padding: "0 5px ",
                                    height: "20px",
                                    marginTop: "-5px",
                                    marginLeft: "5px",
                                  }}
                                  // hidden={!this.state.isEdit}
                                  hidden={!this.checkEdit(itemBlock?.id)}
                                  title="Cập nhật đăng ký phát sóng"
                                  //  disabled={item.product?.isNotDelete}
                                >
                                  <i className="fas fa-check"></i>
                                </Button>
                              </td>
                              <td
                                style={{
                                  background:
                                    itemBlock?.id == this.state.checkColor
                                      ? "rgba(0, 123, 255, 0.2)"
                                      : "",
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    width:
                                      window.location.pathname ==
                                        "/admin/contract-notification" ||
                                      window.location.pathname ==
                                        "/admin/schedule-notification-daily"
                                        ? "120px"
                                        : "250px",
                                    display: `${
                                      !this.checkEdit(itemBlock?.id)
                                        ? "block"
                                        : "none"
                                    }`,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                  title={itemBlock?.adPrice?.name}
                                >
                                  {itemBlock?.adPrice?.name}
                                </span>

                                {/* Select ở đây */}
                                <div
                                  style={{
                                    display: `${
                                      this.checkEdit(itemBlock?.id)
                                        ? "block"
                                        : "none"
                                    }`,
                                    maxWidth: "260px",
                                    zIndex: 3,
                                    height: "100%",
                                  }}
                                >
                                  <Select
                                    styles={this.customStyles}
                                    defaultValue={{
                                      value: "0",
                                      label: itemBlock.adPrice?.name,
                                    }}
                                    options={this.state.listsupplier}
                                    placeholder="Chọn mã giờ"
                                    // value={this.state.typeCustomer}
                                    onChange={(choice) => {
                                      this.setState((prevState) => ({
                                        dataInput: {
                                          ...prevState.dataInput,
                                          blockList:
                                            prevState.dataInput?.blockList?.map(
                                              (blockItem) => {
                                                if (
                                                  blockItem.id == itemBlock?.id
                                                ) {
                                                  let arr = {
                                                    ...blockItem,
                                                    adPrice: {
                                                      ...blockItem.adPrice,
                                                      name: choice.name,
                                                      timeCode: choice.blockQC,
                                                      id: choice.value,
                                                    },
                                                  };
                                                  return arr;
                                                }
                                                //  else return blockItem;

                                                return blockItem;
                                              }
                                            ),
                                        },
                                      }));
                                    }}
                                  />
                                </div>
                              </td>

                              <td
                                style={{
                                  background:
                                    itemBlock?.id == this.state.checkColor
                                      ? "rgba(0, 123, 255, 0.2)"
                                      : "",
                                }}
                              >
                                {itemBlock?.adPrice?.timeCode}
                              </td>
                              {window.location.pathname ==
                                "/admin/contract-notification" ||
                              window.location.pathname ==
                                "/admin/schedule-notification-daily" ? (
                                <td
                                  className="text-end"
                                  style={{
                                    background:
                                      itemBlock?.id == this.state.checkColor
                                        ? "rgba(0, 123, 255, 0.2)"
                                        : "",
                                  }}
                                >
                                  {this.formatNumber(
                                    parseInt(itemBlock?.adPrice?.price)
                                  )}
                                </td>
                              ) : null}
                              {window.location.pathname ==
                                "/admin/contract-notification" ||
                              window.location.pathname ==
                                "/admin/schedule-notification-daily" ? (
                                <td
                                  className="text-end"
                                  style={{
                                    background:
                                      itemBlock?.id == this.state.checkColor
                                        ? "rgba(0, 123, 255, 0.2)"
                                        : "",
                                  }}
                                >
                                  {/* Số lượng ở đây */}
                                  {/* {this.formatNumber(item?.amount)} */}
                                  <CurrencyFormat
                                    value={itemBlock?.amount || ""}
                                    displayType={
                                      this.checkEdit(itemBlock?.id)
                                        ? "input"
                                        : "text"
                                    }
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    allowNagetive={false}
                                    onValueChange={(values) => {
                                      itemBlock = {
                                        ...itemBlock,
                                        amount: values.value,
                                        // Number(values.value),
                                      };
                                      this.setState((prevState) => ({
                                        dataInput: {
                                          ...prevState.dataInput,
                                          blockList:
                                            prevState.dataInput?.blockList?.map(
                                              (blockItem) => {
                                                if (
                                                  blockItem.id == itemBlock.id
                                                ) {
                                                  return {
                                                    ...blockItem,
                                                    amount: itemBlock.amount,
                                                  };
                                                }
                                                return blockItem;
                                              }
                                            ),
                                        },
                                      }));
                                    }}
                                  />
                                </td>
                              ) : null}
                              {window.location.pathname ==
                                "/admin/contract-notification" ||
                              window.location.pathname ==
                                "/admin/schedule-notification-daily" ? null : (
                                // <td>{item?.note}</td>
                                <td
                                  className="td-content"
                                  // style={{whiteSpace:'nowrap',width:'150px',textOverflow:'ellipsis'}}
                                  style={{
                                    background:
                                      itemBlock?.id == this.state.checkColor
                                        ? "rgba(0, 123, 255, 0.2)"
                                        : "",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: `${
                                        !this.checkEdit(itemBlock.id)
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    {itemBlock?.note}
                                  </span>
                                  <input
                                    type="text"
                                    displayType="text"
                                    value={itemBlock?.note}
                                    hidden={!this.checkEdit(itemBlock.id)}
                                    onChange={(e) => {
                                      itemBlock = {
                                        ...itemBlock,
                                        note: e.target.value,
                                      };
                                      this.setState((prevState) => ({
                                        dataInput: {
                                          ...prevState.dataInput,
                                          blockList:
                                            prevState.dataInput?.blockList?.map(
                                              (blockItem) => {
                                                if (
                                                  blockItem.id == itemBlock.id
                                                ) {
                                                  return {
                                                    ...blockItem,
                                                    note: itemBlock.note,
                                                  };
                                                }
                                                return blockItem;
                                              }
                                            ),
                                        },
                                      }));
                                    }}
                                    placeholder=""
                                  />
                                </td>
                              )}
                              {window.location.pathname ==
                                "/admin/contract-notification" ||
                              window.location.pathname ==
                                "/admin/schedule-notification-daily" ? (
                                <td
                                  className="text-end"
                                  style={{
                                    background:
                                      itemBlock?.id == this.state.checkColor
                                        ? "rgba(0, 123, 255, 0.2)"
                                        : "",
                                  }}
                                >
                                  <CurrencyFormat
                                    value={itemBlock?.realDuration}
                                    displayType={
                                      this.checkEdit(itemBlock?.id)
                                        ? "input"
                                        : "text"
                                    }
                                    allowNagetive={false}
                                    onValueChange={(values) => {
                                      itemBlock = {
                                        ...itemBlock,
                                        realDuration: Number(values.value),
                                      };
                                      this.setState((prevState) => ({
                                        dataInput: {
                                          ...prevState.dataInput,
                                          blockList:
                                            prevState.dataInput?.blockList?.map(
                                              (blockItem) => {
                                                if (
                                                  blockItem.id == itemBlock.id
                                                ) {
                                                  return {
                                                    ...blockItem,
                                                    realDuration:
                                                      itemBlock.realDuration,
                                                  };
                                                }
                                                return blockItem;
                                              }
                                            ),
                                        },
                                      }));
                                    }}
                                  />
                                </td>
                              ) : null}
                              {window.location.pathname ==
                                "/admin/contract-notification" ||
                              window.location.pathname ==
                                "/admin/schedule-notification-daily" ? (
                                <td
                                  className="td-content "
                                  style={{
                                    background:
                                      itemBlock?.id == this.state.checkColor
                                        ? "rgba(0, 123, 255, 0.2)"
                                        : "",
                                  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      width: "150px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      display: `${
                                        !this.checkEdit(itemBlock?.id)
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                    title={itemBlock?.content}
                                  >
                                    {itemBlock?.content}
                                  </span>
                                  <input
                                    type="text"
                                    displayType="text"
                                    value={itemBlock?.content}
                                    hidden={!this.checkEdit(itemBlock?.id)}
                                    style={{ width: "250px" }}
                                    onChange={(e) => {
                                      itemBlock = {
                                        ...itemBlock,
                                        content: e.target.value,
                                      };
                                      this.setState((prevState) => ({
                                        dataInput: {
                                          ...prevState.dataInput,
                                          blockList:
                                            prevState.dataInput?.blockList?.map(
                                              (blockItem) => {
                                                if (
                                                  blockItem?.id == itemBlock?.id
                                                ) {
                                                  return {
                                                    ...blockItem,
                                                    content: itemBlock.content,
                                                  };
                                                }
                                                return blockItem;
                                              }
                                            ),
                                        },
                                      }));
                                    }}
                                    placeholder=""
                                  />
                                </td>
                              ) : null}
                              {/* {console.log(itemBlock,"here")} */}

                              <td
                                className="text-end"
                                style={{
                                  background:
                                    itemBlock?.id == this.state.checkColor
                                      ? "rgba(0, 123, 255, 0.2)"
                                      : "",
                                }}
                              >
                                {this.sumSpotbyitem(itemBlock?.arrDate)}
                              </td>
                              <td
                                className="text-end"
                                style={{
                                  background:
                                    itemBlock?.id == this.state.checkColor
                                      ? "rgba(0, 123, 255, 0.2)"
                                      : "",
                                }}
                              >
                                {this.formatNumber(
                                  parseInt(itemBlock?.realPrice)
                                )}
                              </td>

                              {itemBlock?.arrDate?.map((item) => {
                                return (
                                  <td
                                    // className="text-center"
                                    style={{
                                      background:
                                        itemBlock?.id == this.state.checkColor
                                          ? "rgba(0, 123, 255, 0.2)"
                                          : "",
                                    }}
                                    className={`${
                                      this.checkdayoff(item.numberdate) == 6 ||
                                      this.checkdayoff(item.numberdate) == 0
                                        ? "dayoffbg"
                                        : ""
                                    }`}
                                  >
                                    <CurrencyFormat
                                      value={item?.date}
                                      displayType={
                                        this.checkEdit(itemBlock?.id)
                                          ? "input"
                                          : "text"
                                      }
                                      allowNagetive={false}
                                      onValueChange={(values) => {
                                        item = {
                                          ...item,
                                          date: Number(values.value),
                                        };
                                        const updatedArrDate =
                                          itemBlock?.arrDate?.map((arrItem) => {
                                            if (
                                              arrItem.numberdate ==
                                              item.numberdate
                                            ) {
                                              return {
                                                ...arrItem,
                                                date: Number(values.value),
                                              };
                                            }
                                            return arrItem;
                                          });
                                        this.setState((prevState) => ({
                                          dataInput: {
                                            ...prevState.dataInput,
                                            blockList:
                                              prevState.dataInput?.blockList?.map(
                                                (blockItem) => {
                                                  if (
                                                    blockItem.id ===
                                                    itemBlock.id
                                                  ) {
                                                    return {
                                                      ...blockItem,
                                                      arrDate: updatedArrDate,
                                                    };
                                                  }
                                                  return blockItem;
                                                }
                                              ),
                                          },
                                        }));
                                      }}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}

                        <tr>
                          <th colSpan={4}>Tổng</th>
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th></th>
                          ) : null}
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th></th>
                          ) : null}{" "}
                          {window.location.pathname ==
                            "/admin/contract-notification" ||
                          window.location.pathname ==
                            "/admin/schedule-notification-daily" ? (
                            <th></th>
                          ) : null}
                          <th className="text-end">{this.sumSpot()}</th>
                          <th className="text-end">
                            {this.formatNumber(parseInt(this.sumValue()))}
                          </th>
                          <td colSpan={31}></td>
                        </tr>
                      </tbody>
                    </Table>

                    <Table
                      striped
                      bordered
                      style={{ border: "1px solid black" }}
                      id="exportBroadcastCertificationRes"
                      className="d-none"
                    >
                      <tr>
                        <th>UBND TỈNH CÀ MAU</th>
                      </tr>
                      <tr>
                        <th>ĐÀI PHÁT THANH - TRUYỀN HÌNH TỈNH CÀ MAU</th>
                      </tr>
                      <tr>
                        <th>----oOo----</th>
                      </tr>
                      <tr>
                        <th>CHỨNG NHẬN PHÁT SÓNG</th>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>
                          - Kèm theo hợp đồng số:{" "}
                          {this.state.broadcastCertification?.contractCode}{" "}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {" "}
                          Ngày:{" "}
                          {this.timestampToDateString(
                            this.state.broadcastCertification?.createdDate
                          )}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>
                          - Đài Phát Thanh - Truyền Hình Cà Mau đã phát sóng QC
                          cho: {this.state.broadcastCertification?.customerName}{" "}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          Từ tháng:{" "}
                          {this.timestampToMonthString(
                            this.state.startMonth / 1000
                          )}
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                          Đến tháng:{" "}
                          {this.timestampToMonthString(
                            this.state.startMonth / 1000
                          )}{" "}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          Năm:{" "}
                          {this.timestampToYearString(
                            this.state.startMonth / 1000
                          )}
                        </th>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th>Nội dung TB</th>
                        <th>Trên</th>
                        <th>SL</th>
                        <th>Đơn giá</th>
                        <th>Thành tiền</th>
                        {Array.from(
                          {
                            length: new Date(
                              this.state.startMonth.getFullYear(),
                              this.state.startMonth.getMonth() + 1,
                              0
                            ).getDate(),
                          },
                          (_, index) => (
                            <th key={index}>{index + 1}</th>
                          )
                        )}
                      </tr>
                      {this.state.blockListForCertification?.map((item) => {
                        return (
                          <tr>
                            <td>{item.content}</td>
                            <td>{item.adPrice?.name}</td>
                            <td>
                              {item.arrDate.reduce((sum, item) => {
                                if (item.date != "") return sum + item.date;
                                else return sum;
                              }, 0)}
                            </td>
                            <td className="text-end">
                              {parseInt(item?.realPrice)}
                            </td>
                            <td className="text-end">
                              {parseInt(
                                item.arrDate.reduce((sum, item) => {
                                  if (item.date != "") return sum + item.date;
                                  else return sum;
                                }, 0) * item?.realPrice
                              )}
                            </td>
                            {Array.from(
                              {
                                length: new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth() + 1,
                                  0
                                ).getDate(),
                              },
                              (_, index) => (
                                <td key={index}>
                                  {item.arrDate[index] &&
                                  item.arrDate[index].date != 0
                                    ? item.arrDate[index].date
                                    : "empty"}
                                </td>
                              )
                            )}
                          </tr>
                        );
                      })}
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th>TỔNG GIÁ TRỊ:</th>
                        <th></th>
                        <th></th>
                        <th>
                          {parseInt(
                            this.totalValueBroadcastCertification(
                              this.state.blockListForCertification
                            )
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>GIẢM GIÁ THEO HĐ:</th>
                        <th></th>
                        <th></th>
                        <th>
                          {this.state.broadcastCertification?.percentDiscount}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>SỐ TIỀN THANH TOÁN:</th>
                        <th></th>
                        <th></th>
                        <th>
                          {this.totalValueBroadcastCertification(
                            this.state.blockListForCertification
                          ) -
                            (this.state.broadcastCertification
                              ?.percentDiscount *
                              this.totalValueBroadcastCertification(
                                this.state.blockListForCertification
                              )) /
                              100}
                        </th>
                      </tr>
                      <tr>
                        <th>
                          Bằng chữ:{" "}
                          {this.capitalizeFirstLetter(
                            this.soThanhChu(
                              this.totalValueBroadcastCertification(
                                this.state.blockListForCertification
                              ) -
                                (this.state.broadcastCertification
                                  ?.percentDiscount *
                                  this.totalValueBroadcastCertification(
                                    this.state.blockListForCertification
                                  )) /
                                  100
                            )
                          )}
                        </th>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {" "}
                          Cà Mau, ngày{" "}
                          {new Date().getDate() > 9
                            ? new Date().getDate()
                            : "0" + new Date().getDate()}{" "}
                          tháng{" "}
                          {new Date().getMonth() + 1 > 9
                            ? new Date().getMonth() + 1
                            : "0" + (new Date().getMonth() + 1)}{" "}
                          năm {new Date().getFullYear()}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>ĐẠI DIỆN BÊN A</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>NGƯỜI LẬP BẢNG</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>ĐẠI DIỆN BÊN B</th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{localStorage.getItem("auth_name_ctv")}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </Table>

                    <Table id="exportBroadcastQC" className="d-none">
                      <tr>
                        <th>GIẤY CHỨNG NHẬN PHÁT SÓNG</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>CERTIFICATE OF BROADCASTING</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>
                      <tr>
                        <th>
                          Đài Phát Thanh - Truyền hình Cà Mau xác nhận đã phát
                          sóng quảng cáo:{" "}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>
                          Ca Mau Radio and Television Station had broadcasted
                          fully:{" "}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>
                      <tr>
                        <th>Sản phẩm / Product:</th> <th></th>
                        <th>
                          {" "}
                          {
                            this.state.broadcastCertification?.blockList[0]
                              ?.registrationSchedule?.tapeCode?.name
                          }
                        </th>{" "}
                        <th></th>
                      </tr>
                      <tr>
                        <th>Thời lượng / Duration:</th> <th></th>
                        <th>
                          {" "}
                          {
                            this.state.broadcastCertification?.blockList[0]
                              ?.realDuration
                          }{" "}
                          giây
                        </th>{" "}
                        <th></th>
                      </tr>
                      <tr>
                        <th>
                          Từ / From:{" "}
                          {this.getDate(
                            this.changeStartMonth(this.state.startMonth) / 1000
                          )}
                        </th>{" "}
                        <th></th>
                        <th>
                          Đến / To:{" "}
                          {this.getDate(
                            this.changeEndMonth(this.state.startMonth) / 1000
                          )}
                        </th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Các buổi phát sóng như sau: </th>
                        <th></th>
                        <th></th> <th></th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th> <th></th>
                      </tr>
                      <tr>
                        <th>Mã giờ</th>

                        <th>Giờ phát</th>
                        <th>Các ngày phát sóng (AirDate)</th>
                        <th>Số lần phát (No, of Spots)</th>
                      </tr>
                      {this.state.broadcastCertification?.blockList?.map(
                        (blockItem) => (
                          <tr>
                            <th> {blockItem?.adPrice?.name}</th>

                            <th>{blockItem?.adPrice?.timeStart}</th>
                            <th>
                              Ngày:{" "}
                              {this.removeDuplicatesByDate(
                                blockItem?.broadcastDateList
                              )?.map((item, index) => {
                                return this.removeDuplicatesByDate(
                                  blockItem?.broadcastDateList
                                )?.length -
                                  1 !=
                                  index
                                  ? (+new Date(item.date * 1000).getDate() >= 10
                                      ? new Date(item.date * 1000).getDate()
                                      : "0" +
                                        new Date(item.date * 1000).getDate()) +
                                      ", "
                                  : this.getDate(item.date);
                              })}
                            </th>
                            <th>{blockItem?.broadcastDateList?.length}</th>
                          </tr>
                        )
                      )}
                      <tr>
                        <th>Cộng / Total:</th>
                        <th>empty</th>
                        <th>empty</th>{" "}
                        <th>
                          {this.calculateTotalBroadcastLength(
                            this.state.broadcastCertification
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>
                      <tr>
                        <th>Tổng cộng / Total</th> <th></th>
                        <th>
                          {this.calculateTotalBroadcastLength(
                            this.state.broadcastCertification
                          )}{" "}
                          lần/spots
                        </th>
                        <th></th>
                      </tr>
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>
                      <tr>
                        <th></th> <th></th>{" "}
                        <th>Xác nhận của Đài PT_TH Cà Mau</th> <th></th>
                      </tr>
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>{" "}
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>{" "}
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>{" "}
                      <tr>
                        <th></th> <th></th> <th></th> <th></th>
                      </tr>
                      <tr>
                        <th></th> <th></th> <th>Phạm Thanh Phong</th> <th></th>
                      </tr>
                    </Table>
                  </div>
                </div>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
        {this.state.showModalRegister ? (
          <ModalRegister
            show={this.state.showModalRegister}
            onHide={this.modalClose}
            startMonth={this.changeStartMonth(this.state.startMonth)}
            endMonth={this.changeEndMonth(this.state.startMonth)}
            modal={{
              title: "Thêm Block",
              button: (
                <>
                  <i className="fa-solid fa-check" /> Lưu
                </>
              ),
            }}
            datarole={this.state.role}
            id_Regis={this.state.dataInput?.id}
            submit={this.modalSubmit}
            itemSchedule={this.state.dataInput}
            isSocial={this.props.isSocial}
          />
        ) : null}

        <div
          striped
          id="exportBroadcastCertificationPdf2"
          className="border border-0 d-none"
          style={{
            fontWeight: "normal",
            fontFamily: "Times New Roman, Times, serif",
            width: "1250px",
            color: "#000",
            // padding: "38px 38px 38px 57px",
          }}
        >
          <div
            style={{ width: "445px", fontSize: "14px" }}
            className="text-center fw-bold"
          >
            UBND TỈNH CÀ MAU
          </div>
          <div
            style={{ width: "445px", fontSize: "16px" }}
            className="text-center fw-bold"
          >
            ĐÀI PHÁT THANH - TRUYỀN HÌNH TỈNH CÀ MAU
          </div>
          <div
            style={{ width: "445px", fontSize: "14px" }}
            className="text-center fw-bold"
          >
            ----oOo----
          </div>
          <div
            style={{ width: "1094px", fontSize: "21px" }}
            className="text-center fw-bold"
          >
            CHỨNG NHẬN PHÁT SÓNG
          </div>
          <div className="d-flex">
            <div style={{ marginLeft: "245px", fontSize: "15px" }}>
              - Kèm theo hợp đồng số:{" "}
              {this.state.broadcastCertification?.contractCode}{" "}
            </div>
            <div style={{ marginLeft: "130px", fontSize: "15px" }}>
              Ngày:{" "}
              {this.timestampToDateString(
                this.state.broadcastCertification?.createdDate
              )}
            </div>
          </div>
          <div
            style={{
              marginLeft: "245px",
              fontSize: "15px",
              width: "780px",
              textAlign: "justify",
            }}
          >
            - Đài Phát Thanh - Truyền Hình Cà Mau đã phát sóng QC cho:{" "}
            {this.state.broadcastCertification?.customerName}{" "}
          </div>
          <div
            className="d-flex"
            style={{ marginLeft: "360px", fontSize: "15px" }}
          >
            <div style={{ fontSize: "15px" }}>
              Từ tháng:{" "}
              {this.timestampToMonthString(this.state.startMonth / 1000)}
            </div>
            <div style={{ marginLeft: "69px", fontSize: "15px" }}>
              Đến tháng:{" "}
              {this.timestampToMonthString(this.state.startMonth / 1000)}
            </div>
            <div style={{ marginLeft: "69px", fontSize: "15px" }}>
              Năm: {this.timestampToYearString(this.state.startMonth / 1000)}
            </div>
          </div>
          <div></div>
          <div
            className="d-flex fw-bold text-center"
            style={{ marginTop: "24px", fontSize: "11px" }}
          >
            <div
              className=""
              style={{
                border: "0.5px solid black",
                width: "160px",
                fontSize: "14px",
              }}
            >
              Nội dung TB
            </div>
            <div
              style={{
                borderBottom: "0.5px solid black",
                borderTop: "0.5px solid black",
                borderRight: "0.5px solid black",
                width: "99px",
                fontSize: "14px",
              }}
            >
              Trên
            </div>
            <div
              style={{
                borderBottom: "0.5px solid black",
                borderTop: "0.5px solid black",
                borderRight: "0.5px solid black",
                width: "25px",
                fontSize: "14px",
              }}
            >
              SL
            </div>
            <div
              style={{
                borderBottom: "0.5px solid black",
                borderTop: "0.5px solid black",
                borderRight: "0.5px solid black",
                width: "70px",
                fontSize: "14px",
              }}
            >
              Đơn giá
            </div>
            <div
              style={{
                borderBottom: "0.5px solid black",
                borderTop: "0.5px solid black",
                borderRight: "0.5px solid black",
                width: "85px",
                fontSize: "14px",
              }}
            >
              Thành tiền
            </div>
            {Array.from(
              {
                length: new Date(
                  this.state.startMonth.getFullYear(),
                  this.state.startMonth.getMonth() + 1,
                  0
                ).getDate(),
              },
              (_, index) => (
                <div
                  style={{
                    borderBottom: "0.5px solid black",
                    borderTop: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    width: `${
                      (19.4 * 31) /
                      new Date(
                        this.state.startMonth.getFullYear(),
                        this.state.startMonth.getMonth() + 1,
                        0
                      ).getDate()
                    }px`,
                    fontSize: "14px",
                  }}
                >
                  {index + 1}
                </div>
              )
            )}
          </div>
          {this.state.blockListForCertification?.map((item) => {
            return (
              <div class="d-flex">
                <div
                  class="px-2 d-flex align-items-center"
                  style={{
                    width: "160px",
                    borderBottom: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    borderLeft: "0.5px solid black",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.content}
                </div>
                <div
                  className="px-2 d-flex align-items-center"
                  style={{
                    width: "99px",
                    borderBottom: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.adPrice?.name}
                </div>
                <div
                  className="text-center d-flex align-items-center justify-content-center"
                  style={{
                    width: "25px",
                    borderBottom: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    fontSize: "14px",
                  }}
                >
                  {item.arrDate.reduce((sum, item) => {
                    if (item.date != "") return sum + item.date;
                    else return sum;
                  }, 0)}
                </div>
                <div
                  className="justify-content-end px-2 d-flex align-items-center "
                  style={{
                    width: "70px",
                    borderBottom: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    fontSize: "14px",
                  }}
                >
                  {this.formatNumber(parseInt(item?.realPrice))}
                </div>
                <div
                  className="justify-content-end px-2 d-flex align-items-center d-flex align-items-center"
                  style={{
                    width: "85px",
                    borderBottom: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    fontSize: "14px",
                  }}
                >
                  {this.formatNumber(
                    parseInt(
                      item.arrDate.reduce((sum, item) => {
                        if (item.date != "") return sum + item.date;
                        else return sum;
                      }, 0) * item?.realPrice
                    )
                  )}
                </div>
                {Array.from(
                  {
                    length: new Date(
                      this.state.startMonth.getFullYear(),
                      this.state.startMonth.getMonth() + 1,
                      0
                    ).getDate(),
                  },
                  (_, index) => (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: `${
                          (19.4 * 31) /
                          new Date(
                            this.state.startMonth.getFullYear(),
                            this.state.startMonth.getMonth() + 1,
                            0
                          ).getDate()
                        }px`,
                        borderBottom: "0.5px solid black",
                        borderRight: "0.5px solid black",
                        fontSize: "14px",
                      }}
                    >
                      {item.arrDate[index] && item.arrDate[index].date != 0
                        ? item.arrDate[index].date
                        : ""}
                    </div>
                  )
                )}
              </div>
            );
          })}
          <div></div>
          <div
            className="d-flex fw-bold"
            style={{ marginTop: "24px", fontSize: "15px" }}
          >
            <div
              className="text-end"
              style={{ width: "230px", fontSize: "15px" }}
            >
              TỔNG GIÁ TRỊ:
            </div>
            <div
              className="text-end px-2"
              style={{ width: "213px", fontSize: "15px" }}
            >
              {this.formatNumber(
                parseInt(
                  this.totalValueBroadcastCertification(
                    this.state.blockListForCertification
                  )
                )
              )}
            </div>
          </div>
          <div className="d-flex fw-bold">
            <div
              className="text-end"
              style={{ width: "230px", fontSize: "15px" }}
            >
              GIẢM GIÁ THEO HĐ:
            </div>
            <div
              className="text-end px-2"
              style={{ width: "213px", fontSize: "15px" }}
            >
              {this.state.broadcastCertification?.percentDiscount
                ? `${this.state.broadcastCertification?.percentDiscount}%`
                : ""}
            </div>
          </div>
          <div className="d-flex fw-bold">
            <div
              className="text-end"
              style={{ width: "230px", fontSize: "15px" }}
            >
              SỐ TIỀN THANH TOÁN:
            </div>
            <div
              className="text-end px-2"
              style={{ width: "213px", fontSize: "15px" }}
            >
              {this.formatNumber(
                this.totalValueBroadcastCertification(
                  this.state.blockListForCertification
                ) -
                  (this.state.broadcastCertification?.percentDiscount *
                    this.totalValueBroadcastCertification(
                      this.state.blockListForCertification
                    )) /
                    100
              )}
            </div>
          </div>
          <div className="fst-italic" style={{ fontSize: "14px" }}>
            Bằng chữ:{" "}
            {this.capitalizeFirstLetter(
              this.soThanhChu(
                this.totalValueBroadcastCertification(
                  this.state.blockListForCertification
                ) -
                  (this.state.broadcastCertification?.percentDiscount *
                    this.totalValueBroadcastCertification(
                      this.state.blockListForCertification
                    )) /
                    100
              )
            )}
          </div>
          <div
            style={{ marginLeft: "737px", fontSize: "16px" }}
            className="fst-italic"
          >
            Cà Mau, ngày{" "}
            {new Date().getDate() > 9
              ? new Date().getDate()
              : "0" + new Date().getDate()}{" "}
            tháng{" "}
            {new Date().getMonth() + 1 > 9
              ? new Date().getMonth() + 1
              : "0" + (new Date().getMonth() + 1)}{" "}
            năm {new Date().getFullYear()}
          </div>
          <div className="d-flex">
            <div
              className="text-center"
              style={{ fontSize: "16px", width: "377px" }}
            >
              ĐẠI DIỆN BÊN A
            </div>
            <div
              className="text-center"
              style={{ marginLeft: "40px", width: "230px", fontSize: "16px" }}
            >
              NGƯỜI LẬP BẢNG
            </div>
            <div
              className="text-center"
              style={{ marginLeft: "105px", width: "230px", fontSize: "16px" }}
            >
              ĐẠI DIỆN BÊN B
            </div>
          </div>

          <div className="d-flex" style={{ marginTop: "89px" }}>
            <div
              className="text-center"
              style={{ fontSize: "16px", width: "377px", fontSize: "16px" }}
            ></div>
            <div
              className="text-center"
              style={{ marginLeft: "40px", width: "230px", fontSize: "16px" }}
            >
              {localStorage.getItem("auth_name_ctv")}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RegisterBlockFormModal;
