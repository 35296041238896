import React from "react";
import { Link, NavLink } from "react-router-dom";
import Check from "../../components/admin/other/Check";

const Sidebar = () => {
  return (
    <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion" >
      <div className="sb-sidenav-menu">
        <div className="nav">
          {JSON.parse(localStorage.getItem('menu'))?.map(item => {
            return (
              <div key={`${item.id}/${item.icon}/${item.label}/zxcas/${item}`}>
                {
                        new Check().permission(item.permission, 'or') ?
                        <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" key={`${item.id}/${item.icon}/${item.label}/zxcas`}
                        data-bs-target={`#${item.id}`}
                        aria-expanded="false"
                        id="danh-muc"
                        aria-controls={`${item.id}`}  >
                        <div className="sb-nav-link-icon" key={`${item.id}/${item.icon}/${item.label}`} id='danh-muc'>
                          <i className={item.icon}></i> </div>
                        {item?.label}
                        <div className="sb-sidenav-collapse-arrow">
                          <i className="fas fa-angle-down"></i> </div>
                      </Link>
                        :null

                }
               
                <div className="collapse" id={`${item.id}`}  key={`${item.id}//${item.label}/zxcas/${item.icon}`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordionPages" >
                  <nav className="sb-sidenav-menu-nested nav">
                    {item.listmenu?.map(e => {
                      return (
                        new Check().permission(e.permission, 'or') ? 
                        <NavLink className="nav-link" to={`/admin/${e?.link}`} key={`${e.link}/${e.icon}/${e.label}`}>
                        <div className="sb-nav-link-icon">
                          <i className={e?.icon}></i>
                 
                        </div>
                        {e?.label}
                      </NavLink>
                        :null
                       
                      )
                    })}
                  </nav>
                </div>
              </div>

            )
          })}










        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small">Version: 1.0</div>
      </div>
    </nav>
  );
};

export default Sidebar;
