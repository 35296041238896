import React from "react";
import { NavDropdown, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Check from "../../components/admin/other/Check";

const Footer = () => {
    return (
        <footer className="py-2 mt-auto ">
            <div className="desktop-none bg-light">
                <Row style={{ justifyContent: "center", width: "100%" }}>
                    {JSON.parse(localStorage.getItem("menu"))?.map((item) => {
                        return (
                            <Col sm={2} xs={2} style={{ padding: "0 15px" }} key={`menu${item.label}/${item.icon}`}>
                                {new Check().permission(item.permission, "or") ? (
                                    <NavDropdown
                                        title={
                                            <>
                                                {" "}
                                                <span className="sb-nav-link-icon">
                                                    <i className={item.icon}></i>
                                                    <span className="text-menu">{item.label}</span>
                                                </span>
                                            </>
                                        }
                                        id="collasible-nav-dropdown"
                                        // className='pe-2 menu-hover'
                                    >
                                        {item.listmenu.map((e) => {
                                            return new Check().permission(e.permission, "or") ? (
                                                <NavDropdown.Item
                                                    as={NavLink}
                                                    // to={e.link}
                                                    to={`/admin/${e?.link}`}
                                                    key={`${e.link}/${e.icon}`}
                                                >
                                                    <span>
                                                        <span className="sb-nav-link-icon">
                                                            {" "}
                                                            <i className={e?.icon}></i>
                                                        </span>
                                                        <span> {e?.label}</span>{" "}
                                                    </span>
                                                </NavDropdown.Item>
                                            ) : null;
                                        })}
                                    </NavDropdown>
                                ) : null}
                            </Col>
                        );
                    })}
                </Row>
            </div>

            <div className="container-fluid px-4 footer-none">
                <div className="d-flex align-items-center justify-content-between small">
                    <Link to={`https://katec.vn/`} target="_blank" className="text-muted ">
                        Design by Katec
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
