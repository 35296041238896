import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Tabs,
  Tab,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import PriceModal from "./PriceModal";
import Check from "../other/Check";
import DatePicker from "react-datepicker";

class PriceIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        name: "",
        timeCode: "",
        price: "",
        unit: "",
        priceType: 0,
        timeStart: "12:00",
        timeEnd: "",
        dayApply: "",
        dayEnd: "",
        isFixed: false,
      },
      selectedRows: [],
      checkload: true,
      listproduct: [],
      priceType: 0,
      listAd: [],
      show: false,
      start_: new Date(),
      startDate: "",
      // new Date(),
      endDate: "",
      end_: new Date(),
      keyword: "",
    };
  }

  componentDidMount() {
    document.title = "Bảng giá";
    // this.getRoles();
    this.setState(
      {
        startDate: this.changeStartMonth(new Date()),
        endDate: this.changeEndMonth(new Date()),
      },
      () => this.Search()
    );
    //  this.getAllproduct()
  }

  getRoles = () => {
    axios.get(`/ad-price`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ listAd: res.data.data });
      }
    });
  };

  getRole = (item) => {
    if (item !== "null") {
      this.setState({
        role: item,
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa bảng giá?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/ad-price/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    if (data != null) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
    //  + " " +
    //   ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    else return 0;
  }
  dataTable(DataAd) {
    const columns = [
      {
        name: "Ký hiệu",
        selector: (row) => row.timeCode,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.timeCode}
          </div>
        ),
      },
      {
        name: "Tên block",
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.name}
          </div>
        ),
      },
      {
        name: "Giờ phát",
        selector: (row) => row.timeStart,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.time}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Giá",
        center: true,
        selector: (row) => row.price,
        cell: (row) => (
          <div
            style={{ width: "100%", paddingRight: "40%" }}
            className="text-wrap text-end"
          >
            {this.formatNumber(row.price)}
          </div>
        ),
      },
      {
        name: "Từ ngày",
        selector: (row) => row.dayApply,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDate(row.dayApply)}
          </div>
        ),
      },
      {
        name: "Đến ngày",
        selector: (row) => row.dayEnd,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDate(row.dayEnd)}
          </div>
        ),
      },

      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    // if (this.state.roles.length > 0) {
    DataAd?.map((item, i) => {
      // if (item.is_rank !== 1) {
      data.push({
        id: item.id,
        timeCode: item.timeCode,
        name: item.name,
        price: item.price,
        unit: item.unit,
        timeStart: item.timeStart,
        dayApply: item.dayApply,
        dayEnd: item.dayEnd,
        timeEnd: item.timeEnd,
        time: item.timeStart + " - " + (item.timeEnd ? item.timeEnd : "--:--"),

        setting: (
          <>
            {new Check().permission(["28"]) ? (
              <Button
                size="sm"
                variant="warning me-2"
                type="button"
                onClick={() => this.getRole(item)}
                title="Chi tiết bảng giá"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
            ) : null}
            {new Check().permission(["29"]) ? (
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.handleDelete(item)}
                title="Xóa bảng giá"
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : null}
          </>
        ),
      });
      // }

      return item;
    });
    // }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
        isFixed: false,
      },
    });
  };
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };

  Search() {
    axios
      .get(
        `/ad-price/search?keyword=${this.state.keyword?.trim()}&dayApply=${parseInt(
          this.state.startDate / 1000
        )}&dayEnd=${
          this.state.endDate
            ? parseInt(
                new Date(this.state.endDate?.setHours(23, 59, 59)) / 1000
              )
            : 0
        }`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ listAd: res.data.data });
        }
      });
  }
  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      // this.getRoles();
      this.Search();
    };
    const renderSearchFilter = () => {
      return (
        <Row>
          <Col className="mb-2 col-md-3 col-12 col-sm-12">
            <InputGroup>
              <Form.Control
                // style={{ height: '39px' }}
                onChange={(event) =>
                  this.setState({ keyword: event.target.value })
                }
                value={this.state.keyword}
                placeholder="Nhập ký hiệu"
              />
            </InputGroup>
          </Col>{" "}
          <Col
            className="mb-2 d-flex align-items-center mb-2 col-md-5 col-10 col-sm-10"
            style={{ zIndex: 2 }}
          >
            Từ ngày
            <DatePicker
              selected={this.state.startDate}
              onChange={(date) => this.setState({ startDate: date })}
              isClearable
              className="form-control"
              dateFormat={"dd/MM/yyyy"}
              placeholderText="dd/mm/yyyy"
            />
            -{" "}
            <DatePicker
              selected={this.state.endDate}
              onChange={(date) => this.setState({ endDate: date })}
              isClearable
              className="form-control"
              dateFormat={"dd/MM/yyyy"}
              placeholderText="dd/mm/yyyy"
            />
          </Col>
          <Col className="mb-2 col-md-3 col-1 col-sm-1">
            <Button
              variant="outline-secondary"
              className="mt-1"
              size="lg"
              onClick={() => this.Search()}
            >
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {new Check().permission(["27"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách bảng giá
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              // this.changeTab(k)
              let data = { ...this.state.role };
              data.priceType = k;
              this.setState({ priceType: k, role: data });
            }}
            className="mb-3"
          >
            <Tab key={1} title="Thông báo" eventKey={1}>
              {this.state.checkload
                ? this.loading()
                : this.dataTable(this.state.listAd?.notification)}
            </Tab>
            <Tab key={2} title="Quảng cáo" eventKey={2}>
              {this.state.checkload
                ? this.loading()
                : this.dataTable(this.state.listAd?.ad)}
            </Tab>
            <Tab key={4} title="Mạng xã hội" eventKey={4}>
              {this.state.checkload
                ? this.loading()
                : this.dataTable(this.state.listAd?.social)}
            </Tab>
          </Tabs>
          {/* {this.state.checkload ? this.loading() : this.dataTable()} */}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <PriceModal
              show={this.state.modalRoleShow}
              onHide={modalClose}
              modal={
                this.state.role.id === ""
                  ? {
                      title: "Thêm khung giờ phát sóng",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết khung giờ phát sóng",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Danh mục</Breadcrumb.Item>
            <Breadcrumb.Item active>Bảng giá</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default PriceIndex;
