import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb, InputGroup, Form } from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import TapCodeCreate from "./TapCodeCreate";
import Check from "../other/Check";
import Select from "react-select";

class TapCodeIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        code: "",
        name: "",
        duration: 0,
        receivedDate: "",
        tapeCodeTypeId: 0,
        tapeCodeType: "",
        product: "",
        productId: 0,
      },
      selectedRows: [],
      checkload: true,
      listproduct: [],
      keyword: "",
      productSearch: {
        value: 0,
        label: "Tất cả sản phẩm",
      },
    };
  }

  componentDidMount() {
    document.title = "Mã băng";
    this.getRoles();
    this.getProducts();
    //  this.getAllproduct()
  }

  getRoles = () => {
    axios.get(`/tape-code`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ roles: res.data.data });
      }
    });
  };
  getProducts = () => {
    axios.get(`/product`).then((res) => {
      if (res.data.status == 200) {
        // this.setState({ checkload: false })
        this.setState({ listproduct: res.data.data });
      }
    });
  };
  getRole = (item) => {
    if (item !== "null") {
      this.setState({
        role: item,
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa mã băng?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/tape-code/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.Search();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    if (data != 0) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()
      );
    } else return "";
    // const date = new Date(data * 1000);
    // // date.setHours(0, 0, 0, 0);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + " " +
    //   ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  dataTable() {
    const columns = [
      {
        name: "Mã băng",
        selector: (row) => row.role_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.role_name}
          </div>
        ),
      },
      {
        name: "Tên",
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.name}
          </div>
        ),
      },
      {
        name: "Thời lượng",
        selector: (row) => row.duration,
        sortable: true,
      },
      {
        name: "Sản phẩm",
        selector: (row) => row.product,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.product}
          </div>
        ),
      },
      {
        name: "Ngày nhận băng",
        selector: (row) => row.receivedDate,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDate(row.receivedDate)}
          </div>
        ),
      },
      {
        name: "Loại spot",
        selector: (row) => row.tapeCodeType,
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    // if (this.state.roles.length > 0) {
    this.state.roles.map((item, i) => {
      // if (item.is_rank !== 1) {
      data.push({
        id: item.id,
        role_name: item.code,
        name: item.name,
        receivedDate: item.receivedDate,
        duration: item.duration,
        product: item.product?.name,
        tapeCodeType: item.tapeCodeType?.name,
        productId: item.product?.id,
        tapeCodeTypeId: item.tapeCodeType?.id,

        setting: (
          <>
            {new Check().permission(["32"]) ? (
              <Button
                size="sm"
                variant="warning me-2"
                type="button"
                onClick={() => this.getRole(item)}
                title="Chi tiết mã băng"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
            ) : null}
            {new Check().permission(["33"]) ? (
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.handleDelete(item)}
                title="Xóa mã băng"
                disabled={item.isNotDelete}
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : null}
          </>
        ),
      });
      // }

      return item;
    });
    // }

    return <DataTables data={data} columns={columns} selectedRows={selectedRows} />;
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  Search() {
    axios
      .get(`/tape-code/search?productId=${this.state.productSearch.value}&keyword=${this.state.keyword?.trim()}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ roles: res.data.data });
        }
      });
  }

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      this.Search();
    };
    const renderSearchFilter = () => {
      let options = [];
      this.state.listproduct?.map((item) => {
        options.push({
          value: item.id,
          label: item.product?.name,
        });

        return item;
      }, options.unshift({ value: 0, label: "Tất cả sản phẩm" }));

      return (
        <Row>
          <Col md={3} xs={5} className="mb-2">
            <InputGroup>
              <Form.Control
                style={{ height: "39px" }}
                onChange={(event) => this.setState({ keyword: event.target.value })}
                value={this.state.keyword}
                placeholder="Nhập mã băng/ tên"
              />
            </InputGroup>
          </Col>

          <Col md={3} xs={5} className="mb-2" style={{ zIndex: "2" }}>
            <Select
              defaultValue={options[0]}
              options={options}
              placeholder="Chọn sản phẩm"
              value={this.state.productSearch}
              onChange={(choice) => {
                this.setState({ productSearch: choice });
              }}
            />
          </Col>
          <Col md={4} xs={1}>
            <Button variant="outline-secondary" className="mt-1" onClick={() => this.Search()}>
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {new Check().permission(["31"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách mã băng
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <TapCodeCreate
              show={this.state.modalRoleShow}
              onHide={modalClose}
              modal={
                this.state.role.id === ""
                  ? {
                      title: "Thêm mã băng",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết mã băng",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Danh mục</Breadcrumb.Item>
            <Breadcrumb.Item active>Mã băng</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TapCodeIndex;
