import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import RoleFormModal from "./RoleFormModal";
import Check from "../other/Check";

class RoleIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
      selectedRows: [],
      checkload: true,
    };
  }

  componentDidMount() {
    document.title = "Vai trò";
    this.getRoles();
  }

  getRoles = () => {
    axios.get(`/role`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ roles: res.data.data });
      }
    });
  };

  getRole = (item) => {
    if (item !== "null") {
      // axios.post(`/viewRole?id_role=${id}`).then((res) => {
      //   if (res.data.status === true) {
      //     const data = res.data.data[0];

      this.setState({
        role: {
          id: item.id,
          role_name: item.name,
          isNotDelete: item.isNotDelete,
          // is_rank: data.is_rank,
          permission:
            item.permissionList != null
              ? item?.permissionList?.map((itemRole) => {
                  return String(itemRole?.id);
                })
              : [],
        },
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa vai trò?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/role/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  dataTable() {
    const columns = [
      {
        name: "Tên vai trò",
        selector: (row) => row.role_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.role_name}
          </div>
        ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.roles.length > 0) {
      this.state.roles.map((item, i) => {
        if (item.is_rank !== 1) {
          data.push({
            id: item.id,
            // is_rank: item.is_rank,
            // permission: item.permission,
            role_name: item.name,
            setting: (
              <>
                {new Check().permission(["57"]) ? (
                  <Button
                    size="sm"
                    variant="warning me-2"
                    type="button"
                    onClick={() => this.getRole(item)}
                    title="Chi tiết vai trò"
                  >
                    <i className="fa-solid fa-pencil"></i>
                  </Button>
                ) : null}
                {new Check().permission(["59"]) ? (
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={() => this.handleDelete(item)}
                    disabled={item.isNotDelete}
                    title="Xóa vai trò"
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                ) : null}
              </>
            ),
          });
        }

        return item;
      });
    }

    return <DataTables data={data} columns={columns} selectedRows={selectedRows} />;
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      this.getRoles();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(["56"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách vai trò người dùng
          </Card.Title>
        </Card.Header>

        <Card.Body>{this.state.checkload ? this.loading() : this.dataTable()}</Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <RoleFormModal
              show={this.state.modalRoleShow}
              onHide={modalClose}
              modal={
                this.state.role.id === ""
                  ? {
                      title: "Thêm vai trò",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết vai trò",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Hệ thống</Breadcrumb.Item>
            <Breadcrumb.Item active>Vai trò</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RoleIndex;
