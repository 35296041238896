import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb, Table } from "react-bootstrap";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";

class MonthlySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      checkload: true,
      startMonth: new Date(),
      listCustomer: [],
      CustomerItem: { value: 0, label: "Chọn khách hàng" },
      listContract: [],
      ContractItem: { value: 0, label: "Chọn hợp đồng" },
      listDate: [],
      listDay: [],
      listExport: [],
    };
  }

  componentDidMount() {
    document.title = "Tổng kết tháng";
    this.getRoles();
    this.showDate(new Date());
    this.getAllContract(0);
    this.getAllCustomer();
  }

  getRoles = () => {
    axios
      .get(
        `/summary/monthly-summary-table?month=${this.state.startMonth / 1000}&customerId=${this.state.CustomerItem?.value
        }&contractId=${this.state.ContractItem?.value}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ roles: res.data.data }, () => {
            this.showDate(this.state.startMonth);
            this.changeDataExport();
          });
        }
      });
  };
  getAllCustomer = () => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn khách hàng" });
    axios
      .get(`/customer`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({
            listCustomer: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllContract = (id) => {
    var arr = [];
    arr.push({ value: 0, label: "Chọn hợp đồng" });
    axios
      .get(`/contract/?customerId=${id}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.contractCode });
          });
          this.setState({
            listContract: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  formatNumber(num) {
    if (num != null && num != "") return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
  showDate = (month) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });
    this.setState({ listDate: viewDate });
    let arr = this.state.roles;
    arr.map((item) => {
      let arrRes = [{ numberdate: 0, date: 0 }];

      viewDate?.map((date) => {
        arrRes?.push({
          numberdate: date.label,
          date:
            this.CheckRes(date.label, item?.broadcastDateList, month) > 0
              ? this.CheckRes(date.label, item?.broadcastDateList, month)
              : "",
        });
        item.arrDate = [...arrRes.filter((i) => i.numberdate > 0)];
      });
    });

    // let a = dataInput

    this.setState({ roles: arr });
  };
  changeDateToDay = (date) => {
    var data_ = new Date(this.state.startMonth.getFullYear(), this.state.startMonth.getMonth(), date);
    var daysOfWeek = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

    var rs = daysOfWeek[data_.getDay()];

    return rs;
  };
  getMaxDate(arr) {
    const nonEmptyDates = arr?.filter((item) => item.date != "");

    if (nonEmptyDates?.length === 0) {
      return null;
    }
    const maxDateObject = nonEmptyDates?.sort((a, b) => b.numberdate - a.numberdate)[0];
    return maxDateObject?.date;
  }
  changeDataExport() {
    // let arr = this.state.roles;
    // let check = false;
    // this.state.roles?.map((item) => {
    //   if (this.getMaxDate(item.arrDate) ==2 ) {
    //     arr.push(item);
    //     return;
    //   }
    // });
    // console.log(arr.slice(0,4), "33");
    // let a=arr.slice(0,4)
    // this.setState({listExport:a})
  }
  CheckRes(numberdate, arrRes, startMonth) {
    let count = 0;
    for (var i = 0; i < arrRes?.length; i++) {
      if (
        new Date(arrRes[i].date * 1000).getDate() == numberdate &&
        new Date(arrRes[i].date * 1000).getMonth() == startMonth?.getMonth() &&
        new Date(arrRes[i].date * 1000).getFullYear() == startMonth?.getFullYear()
      ) {
        count++;
      }
    }

    return count;
  }

  formatBlockOneRow(block) {
    const outputArray = [];
    let blockItem = block.arrDate;
    if (blockItem) {
      blockItem = block.arrDate.map((arrItem) => ({ ...arrItem, date: arrItem.date === "" ? 0 : arrItem.date }));

      const spotNumber = blockItem?.map((block) => {
        if (block.date != "") return block.date;
        else return 0;
      });
      const maxSpot = Math.max(...spotNumber);

      for (let i = 0; i < maxSpot; i++) {
        const itemArray = [];
        for (let j = 0; j < blockItem.length; j++) {
          if (blockItem[j].date != 0) {
            itemArray[j] = { ...blockItem[j], date: 1 };
            blockItem[j] = { ...blockItem[j], date: blockItem[j].date - 1 };
          } else itemArray[j] = { ...blockItem[j], date: 0 };
        }
        outputArray.push(itemArray);
      }

      const result = outputArray.map((output) => {
        return {
          ...block,
          arrDate: output,
        };
      });

      return result;
    }
  }

  summaryTableExport = () => {
    const table = document.getElementById("exportSummaryTable");
    var ws = XLSX.utils.table_to_sheet(table);

    const applyStyle = (row, col, value, size = 12) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: size, bold: true },
          alignment: { vertical: "center", horizontal: "center" },
        },
        t: "s",
        v: value,
      };
    };

    function setCellStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "right",
          horizontal: "right",
        },
      };
    }

    function setCellBottomStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
      };
    }

    function setCellItalicStyle(cellRef) {
      ws[cellRef].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          italic: true,
        },
      };
    }

    const applyStyleNormal = (row, col, value, size = 12, bold = 0) => {
      ws[`${col}${row}`] = {
        s: {
          font: { name: "Times New Roman", sz: size, bold: bold ? true : false },
        },
        v: value,
      };
    };

    // Merge B1 to E1 with styling
    ws["!merges"] = ws["!merges"] || [];
    ws["!merges"].push({ s: { r: 0, c: 1 }, e: { r: 0, c: 6 } });
    applyStyle(1, "B", ws["B1"]?.v, 11);

    // Merge B2 to E2 with styling
    ws["!merges"].push({ s: { r: 1, c: 1 }, e: { r: 1, c: 6 } });
    applyStyle(2, "B", ws["B2"]?.v, 11);

    // Merge B3 to E3 with styling
    ws["!merges"].push({ s: { r: 2, c: 1 }, e: { r: 2, c: 6 } });
    applyStyle(3, "B", ws["B3"]?.v, 11);

    ws["!merges"].push({
      s: { r: 3, c: 0 }, e: {
        r: 3, c: 6 + new Date(
          this.state.startMonth.getFullYear(),
          this.state.startMonth.getMonth() + 1,
          0
        ).getDate()
      }
    });

    applyStyleNormal("5", "B", ws["B5"].v, 12);
    applyStyleNormal("6", "B", ws["B6"].v, 12);
    applyStyleNormal("7", "B", ws["B7"].v, 12);

    ws["!cols"] = ws["!cols"] || [];

    ws["!cols"][6] = { wch: 15 };

    const colContractValueLabel = this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:");
    const afterTaxValueLabel = this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:");
    const discountValueLabel = this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:");
    const paymentValueLabel = this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:");

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:"))}`] = {
      f: `=SUM(G10:G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:")) - 2})`,
    };

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:"))}`] = {
      ...ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:"))}`],
      s: {
        font: { name: "Times New Roman", sz: 12, bold: true },
      },
    };

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:"))}`].z = "#,###,###,###";
    delete ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:"))}`].w;
    XLSX.utils.format_cell(ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:"))}`]);

    applyStyleNormal(
      this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:")),
      "G",
      ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`].v,
      12,
      1
    );

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`] = {
      f: `=SUM(G10:G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:")) - 2})`,
    };

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`] = {
      ...ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`],
      s: {
        font: { name: "Times New Roman", sz: 12, bold: true },
      },
    };

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`].z = "#,###,###,###";
    delete ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`].w;
    XLSX.utils.format_cell(ws[`G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`]);

    applyStyleNormal(
      this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:")),
      "G",
      ws[`G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`].v,
      12,
      1
    );

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`].v = ws[
      `G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`
    ].v
      ? ws[`G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`].v + "%"
      : "";

    ws[`G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`].s = {
      ...ws[`G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`].s,
      alignment: {
        vertical: "right",
        horizontal: "right",
      },
    };

    applyStyleNormal(
      this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")),
      "G",
      ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`].v,
      12,
      1
    );

    if (ws[`G${this.extractNumber(this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:"))}`].v)
      ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`] = {
        f: `=G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))} - G${this.extractNumber(
          this.findCellByValue(ws, "GIẢM GIÁ THEO HĐ:")
        )}*G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))}`,
      };
    else
      ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`] = {
        f: `=G${this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:"))} - 0*G${this.extractNumber(
          this.findCellByValue(ws, "GIÁ TRỊ SAU KHI GIẢM THUẾ:")
        )}`,
      };

    ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`].s = {
      ...ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`].s,
      alignment: {
        vertical: "right",
        horizontal: "right",
      },
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
    };

    ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`].z = "#,###,###,###";
    delete ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`].w;
    XLSX.utils.format_cell(ws[`G${this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:"))}`]);

    setCellItalicStyle(`A` + (this.extractNumber(this.findCellByValue(ws, "SỐ TIỀN THANH TOÁN:")) + 1));

    ws["!cols"][3] = { wch: 20 };

    setCellStyle(colContractValueLabel);
    setCellStyle(afterTaxValueLabel);
    setCellStyle(discountValueLabel);
    setCellStyle(paymentValueLabel);

    const reportName = this.findCellByValue(ws, "BẢNG KÊ CHI TIẾT PHÁT SÓNG");

    ws[reportName].s = {
      font: {
        name: "Times New Roman",
        sz: 13,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    setCellItalicStyle(`H` + (this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) - 1));
    setCellItalicStyle(`Y` + (this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN CÔNG TY")) - 1));

    setCellBottomStyle(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU"));
    setCellBottomStyle(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG"));
    setCellBottomStyle(this.findCellByValue(ws, "ĐẠI DIỆN CÔNG TY"));

    const userCreatePosition = this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG"));
    setCellItalicStyle("G" + (userCreatePosition - 1));

    ws["!merges"].push({
      s: { r: userCreatePosition, c: 6 },
      e: { r: userCreatePosition, c: 9 },
    });

    applyStyle(userCreatePosition, "G", ws["G" + userCreatePosition]?.v);

    ws["!merges"].push({
      s: { r: this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")) - 1, c: 1 },
      e: { r: this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")) - 1, c: 5 },
    });

    applyStyle(
      this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")),
      "B",
      ws["B" + this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU"))]?.v
    );

    ws["!merges"].push({
      s: { r: this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")) + 4, c: 1 },
      e: { r: this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")) + 4, c: 5 },
    });

    applyStyle(
      this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")) + 5,
      "B",
      ws["B" + (this.extractNumber(this.findCellByValue(ws, "ĐẠI DIỆN ĐÀI PT-TH CÀ MAU")) + 5)]?.v
    );

    ws["!merges"].push({
      s: { r: this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) - 1, c: 7 },
      e: { r: this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) - 1, c: 17 },
    });

    applyStyle(
      this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")),
      "H",
      ws["H" + this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG"))]?.v
    );

    ws["!merges"].push({
      s: { r: this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) + 4, c: 7 },
      e: { r: this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) + 4, c: 17 },
    });

    applyStyle(
      this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) + 5,
      "H",
      ws["H" + (this.extractNumber(this.findCellByValue(ws, "NGƯỜI LẬP BẢNG")) + 5)]?.v
    );

    // Set the starting position (A9) and ending position (AL15)
    const startPosition = { r: 8, c: 0 };
    const endPosition = { r: this.extractNumber(this.findCellByValue(ws, "GIÁ TRỊ HỢP ĐỒNG:")) - 2, c: 38 }; // Assuming AL corresponds to column 38

    // Add borders to all cells in the range from A9 to AL15
    for (let row = startPosition.r; row < endPosition.r; row++) {
      for (let col = startPosition.c; col <= endPosition.c; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

        if (ws[cellRef]?.v == "empty") {
          // Delete the cell
          ws[cellRef].v = "";
        }
        // Ensure cell exists
        if (!ws[cellRef]) {
          ws[cellRef] = {};
        }

        // Apply borders to the cell
        ws[cellRef].s = {
          font: {
            name: "Times New Roman",
          },
          ...ws[cellRef].s,
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
          alignment: {
            wrapText: cellRef == XLSX.utils.encode_cell({ r: 8, c: col }) ? false : true,
            vertical: "left",
            horizontal: "left",
          },
        };

        if (col >= 4 || col == 2) {
          ws[`${cellRef}`].z = "#,###,###,###";
          delete ws[`${cellRef}`].w;
          XLSX.utils.format_cell(ws[`${cellRef}`]);
          ws[`${cellRef}`].s = {
            ...ws[`${cellRef}`].s,
            alignment: {
              vertical: "right",
              horizontal: "right",
            },
          };
        }
      }
    }

    for (let col = 0; col <= 37; col++) {
      applyStyle(9, String.fromCharCode(65 + col), ws[String.fromCharCode(65 + col) + "9"]?.v);
      ws[`${String.fromCharCode(65 + col)}9`].s = {
        ...ws[`${String.fromCharCode(65 + col)}9`].s,
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };

      if (col >= 7) {
        ws["!cols"][col] = { wch: 2 };

        let colLabel = "";

        if (col <= 25) {
          colLabel = String.fromCharCode(65 + col); // A to Z
        } else {
          const firstLetter = String.fromCharCode(65 + Math.floor(col / 26) - 1);
          const secondLetter = String.fromCharCode(65 + (col % 26));
          colLabel = firstLetter + secondLetter; // AA to AL
        }

        applyStyle(9, colLabel, ws[colLabel + "9"]?.v);

        ws[`${colLabel}9`].s = {
          ...ws[`${colLabel}9`].s,
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };
      }
    }

    applyStyle(4, "A", ws["A4"]?.v, 15);
    let countDate = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth() + 1,
      0
    ).getDate()
    ws["!cols"] = [];
    ws["!cols"] = [
      { wch: 8.11 },
      { wch: 7.67 },
      { wch: 3.56 },
      {
        wch: countDate == 31 ? 11.56 :
          countDate == 30 ? 14.24 :
            countDate == 29 ? 12.56 :
              11.2
      },
      { wch: 2.89 },
      { wch: 9.22 },
      { wch: 11 },
    ];

    for (let i = 0; i < countDate; i++)
      countDate == 31 ? ws["!cols"].push({ wch: 1.7 }) :
        countDate == 30 ? ws["!cols"].push({ wch: 1.8 }) :
          countDate == 29 ? ws["!cols"].push({ wch: 1.9 }) :
            ws["!cols"].push({ wch: 2 })

    // ws["!cols"].push({ wch: 1.7*31/countDate });

    ws["!margins"] = {
      left: 1 / 2.54,
      right: 0.8 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };

    return ws;
  };

  extractNumber = (inputString) => {
    const numberPart = inputString.replace(/[^\d]/g, "");
    return parseInt(numberPart, 10);
  };

  extractLetters = (inputString) => {
    const lettersPart = inputString.replace(/[^\D]/g, "");
    return lettersPart;
  };

  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (data.hasOwnProperty(cellRef) && data[cellRef].t === "s" && data[cellRef].v == targetValue) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };

  getFirstDayOfMonth = (timestamp) => {
    const date = new Date(timestamp * 1000);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);

    // Convert the updated date to a timestamp
    const firstDayOfMonthTimestamp = Math.floor(date.getTime() / 1000);

    return firstDayOfMonthTimestamp;
  };

  getLastDayOfMonth = (timestamp) => {
    const date = new Date(timestamp * 1000);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    date.setHours(23, 59, 59, 999);

    const lastDayOfMonthTimestamp = Math.floor(date.getTime() / 1000);

    return lastDayOfMonthTimestamp;
  };

  renderTable = () => {
    return (
      <div style={{ overflow: "auto" }}>
        <Table striped bordered style={{ border: "1px solid #ccc" }}>
          <thead>
            <tr>
              <th colSpan={5} className="text-center">
                Thời điểm
              </th>
              {this.state.listDay?.map((item) => {
                return <th>{item.label}</th>;
              })}
              <th colSpan={3} className="text-center">
                Thành tiền
              </th>
            </tr>
            <tr style={{ whiteSpace: "nowrap" }}>
              <th>Mã giờ</th>
              <th>Block QC</th>
              <th>Sản phẩm</th>
              <th>Mã băng</th>
              <th>TL</th>
              {this.state.listDate?.map((item, index) => {
                return <th>{item.label}</th>;
              })}
              <th>Số kỳ</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {this.state.roles?.map((block) => {
              const item = this.formatBlockOneRow(block);
              return item?.map((item) => {
                return (
                  <tr>
                    <td>{item.adPrice?.timeCode}</td>
                    <td>{item.adPrice?.name}</td>
                    <td>{item.tapeCode?.product?.name}</td>
                    <td>{item.tapeCode?.code}</td>
                    <td>{item?.tapeCode.duration}</td>
                    {item.arrDate?.map((e) => {
                      return <td>{e.date == 0 ? "" : e.date}</td>;
                    })}
                    <td>{item.arrDate.reduce((sum, item) => sum + item.date, 0)}</td>
                    <td className="text-end">{this.formatNumber(parseInt(item?.realPrice))}</td>
                    <td className="text-end">
                      {this.formatNumber(
                        parseInt(item.arrDate.reduce((sum, item) => sum + item.date, 0) * item?.realPrice)
                      )}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </Table>
        <Table striped bordered style={{ border: "1px solid black" }} id="exportSummaryTable" className="d-none">
          <tr>
            <th></th>
            <th>UBND TỈNH CÀ MAU</th>
          </tr>
          <tr>
            <th></th>
            <th>ĐÀI PHÁT THANH - TRUYỀN HÌNH TỈNH CÀ MAU</th>
          </tr>
          <tr>
            <th></th>
            <th>----oOo----</th>
          </tr>
          <tr>
            <th>BẢNG KÊ CHI TIẾT PHÁT SÓNG</th>
          </tr>
          <tr>
            <th></th>
            <th>
              - Kèm theo hợp đồng số {this.state.roles[0]?.contract.contractCode} ký ngày{" "}
              {this.timestampToDateString(this.state.roles[0]?.contract.createdDate)}
            </th>
          </tr>
          <tr>
            <th></th>
            <th>- Đài PT-TH Cà Mau đã phát sóng QC cho: {this.state.roles[0]?.contract.customer.name}</th>
          </tr>
          <tr>
            <th></th>
            <th>
              Từ {this.timestampToMonthString(this.getFirstDayOfMonth(this.state.startMonth / 1000))} Đến{" "}
              {this.timestampToMonthString(this.getLastDayOfMonth(this.state.startMonth / 1000))} Năm{" "}
              {this.timestampToYearString(this.state.startMonth / 1000)}
            </th>
          </tr>
          <tr></tr>
          <tr>
            <th>Mã giờ</th>
            <th>Giờ phát</th>
            <th>TL</th>
            <th>Mặt hàng</th>
            <th>SL</th>
            <th>Đơn giá</th>
            <th>Thành Tiền</th>
            {Array.from({
              length: new Date(
                this.state.startMonth.getFullYear(),
                this.state.startMonth.getMonth() + 1,
                0
              ).getDate()
            }, (_, index) => (
              <th key={index}>{index + 1}</th>
            ))}
          </tr>
          {this.state.roles?.map((block) => {
            const item = this.formatBlockOneRow(block);
            return item?.map((item) => {
              return (
                <tr>
                  <td>{item.adPrice?.timeCode}</td>
                  <td>{item.adPrice?.timeStart}</td>
                  <td>{item.tapeCode?.duration}</td>
                  <td>{item.tapeCode?.name}</td>
                  <td>{item.arrDate.reduce((sum, item) => sum + item.date, 0)}</td>
                  <td className="text-end">{parseInt(item?.realPrice)}</td>
                  <td className="text-end">
                    {parseInt(item.arrDate.reduce((sum, item) => sum + item.date, 0) * item?.realPrice)}
                  </td>
                  {Array.from({
                    length: new Date(
                      this.state.startMonth.getFullYear(),
                      this.state.startMonth.getMonth() + 1,
                      0
                    ).getDate()
                  }, (_, index) => (
                    <td key={index}>
                      {item.arrDate[index] && item.arrDate[index].date != 0 ? item.arrDate[index].date : "empty"}
                    </td>
                  ))}
                </tr>
              );
            });
          })}
          <tr></tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>GIÁ TRỊ HỢP ĐỒNG:</th>
            <th></th>
            <th></th>
            <th>{parseInt(this.totalValueBroadcastCertification(this.state.roles))}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>GIÁ TRỊ SAU KHI GIẢM THUẾ:</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>GIẢM GIÁ THEO HĐ:</th>
            <th></th>
            <th></th>
            <th>{this.state.roles[0]?.contract.percentDiscount}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>SỐ TIỀN THANH TOÁN:</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>
              Bằng chữ:{" "}
              {this.capitalizeFirstLetter(
                this.soThanhChu(
                  parseInt(
                    this.totalValueBroadcastCertification(this.state.roles) -
                    (this.state.roles[0]?.contract.percentDiscount *
                      this.totalValueBroadcastCertification(this.state.roles)) /
                    100
                  )
                )
              )}
            </th>
          </tr>
          <tr></tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>
              Cà Mau, ngày {new Date().getDate()} tháng {new Date().getMonth() + 1} năm {new Date().getFullYear()}
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>..........ngày......tháng......năm......</th>
          </tr>
          <tr>
            <th></th>
            <th>ĐẠI DIỆN ĐÀI PT-TH CÀ MAU</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>NGƯỜI LẬP BẢNG</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>ĐẠI DIỆN CÔNG TY</th>
          </tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr>
            <th></th>
            <th>{this.state.roles[0]?.contract.companyRepresentativeName}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>{localStorage.getItem("auth_name_ctv")}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </Table>
      </div>
    );
  };

  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  timestampToDateString = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  timestampToMonthString = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${day}/${month}`;
  };

  timestampToYearString = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();

    return `${year}`;
  };

  soThanhChu(so) {
    const donVi = ["", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];
    const hang = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

    if (so === 0) return "Không";

    let chuoi = "";
    let i = 0;

    while (so > 0) {
      let donViHangNghin = so % 1000;
      if (donViHangNghin !== 0) {
        chuoi = this.soThanhChuHangNghin(donViHangNghin) + " " + hang[i] + " " + chuoi;
      }
      so = Math.floor(so / 1000);
      i++;
    }

    return chuoi.trim() + " đồng";
  }

  soThanhChuHangNghin(so) {
    const donVi = ["", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
    const chuc = [
      "",
      "mười",
      "hai mươi",
      "ba mươi",
      "bốn mươi",
      "năm mươi",
      "sáu mươi",
      "bảy mươi",
      "tám mươi",
      "chín mươi",
    ];

    let chuoi = "";

    if (so < 10) {
      chuoi = donVi[so];
    } else if (so < 100) {
      chuoi = chuc[Math.floor(so / 10)] + (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    } else if (so < 1000) {
      chuoi =
        donVi[Math.floor(so / 100)] +
        " trăm " +
        chuc[Math.floor((so % 100) / 10)] +
        (so % 10 !== 0 ? " " + donVi[so % 10] : "");
    }

    return chuoi.trim();
  }
  totalValueBroadcastCertification = (broadcastCertification) => {
    let sum = 0;
    broadcastCertification?.map((broadcastItem) => {
      sum +=
        broadcastItem.arrDate?.reduce((sum, item) => {
          if (item.date != "") return sum + item.date;
          else return sum;
        }, 0) * broadcastItem?.realPrice;
    });
    return sum;
  };

  getDate = (data) => {
    const date = new Date(data * 1000);
    // date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + (date.getMinutes() + 1)).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2)
    );
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  renderFilter = () => {
    return (
      <Row>
        <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 6 }}>
          <Select
            defaultValue={this.state.listCustomer[0]}
            options={this.state.listCustomer}
            placeholder="Chọn khách hàng"
            value={this.state.CustomerItem}
            onChange={(choice) => {
              this.setState({
                CustomerItem: choice,
                ContractItem: { value: 0, label: "Chọn hợp đồng" },
              });
              this.getAllContract(choice.value);
            }}
          />
        </Col>
        <Col className="mb-2 col-md-3 col-12 col-sm-12" style={{ zIndex: 5 }}>
          <Select
            defaultValue={this.state.listContract[0]}
            options={this.state.listContract}
            placeholder="Chọn hợp đồng"
            value={this.state.ContractItem}
            onChange={(choice) => {
              this.setState({ ContractItem: choice });
            }}
          />
        </Col>
        <Col className="mb-2 col-md-3 col-1 col-sm-1">
          <Button variant="outline-secondary" className="mt-1" size="md" onClick={() => this.getRoles()}>
            <i className="fa-solid fa-search"></i>
          </Button>
        </Col>
      </Row>
    );
  };

  xport = () => {
    // swal({
    //   title: "Xác nhận xuất bảng kê",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, this.summaryTableExport(), "Bảng kê");
    XLSX.writeFile(wb, `Bang ke ${this.state.roles[0]?.contract.contractCode.replaceAll("/", ".")}.xlsx`);
    //   }
    // });
  };

  renderRoleList() {
    return (
      <>
        <Card.Header>
          <Card.Title className="d-md-flex align-items-center justify-content-between ">
            <div>
              <i className="fas fa-list me-1"></i> Tổng kết tháng
              <span className="m-1">
                {this.state.startMonth
                  ? `Tháng ${this.state.startMonth.getMonth() + 1}-${this.state.startMonth.getFullYear()}`
                  : null}
              </span>
              <input
                type="month"
                locale="vi"
                name="month"
                // max={this.changeMonth(this.state.dataInput.broadcastEndDate)}
                // min={this.changeMonth(this.state.dataInput.broadcastStartDate)}
                style={{
                  border: "none",
                  width: 36,
                  height: 27,
                  backgroundColor: "rgb(16,108,252)",
                  borderRadius: 5,
                  padding: 0,
                }}
                className="ms-2 hidden-clear-button"
                onChange={(e) => {
                  if (e.target.value) {
                    const [year, month] = e.target.value.split("-");
                    this.setState({ startMonth: new Date(year, month - 1) });
                    this.showDate(new Date(year, month - 1));
                  } else {
                  }
                }}
              />
            </div>
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{}}
              onClick={() => this.xport()}
              className="me-2 mt-md-0 mt-1"
              disabled={this.state.roles[0]?.length == 0 || !this.state.roles[0]}
            >
              <i className="fa-solid fa-file-export"></i> Xuất bảng kê
            </Button>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {this.renderFilter()}
          {this.state.checkload ? this.loading() : this.renderTable()}
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Quản trị</Breadcrumb.Item>
            <Breadcrumb.Item active>Tổng kết tháng</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MonthlySummary;
