import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/admin/assets/img/CTV_logo.png"

const Navbar = () => {
  if (localStorage.getItem('access_token_ctv')) {
    window.location.href = "/admin";
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-light shadow sticky-top">
      <div className="container">
        <Link className="navbar-brand ps-3" to="/admin">
          <img src={Logo} style={{ height:48 }} alt="CTV Cà Mau" />
        </Link>
        {/* <Link className="navbar-brand ps-3 fw-bold" style={{ 'fontFamily': 'cursive' }} to="/login">
          <span className='text-warning'>Ka</span><span className='text-info'>food</span>
        </Link> */}

        <button size="sm" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              {/* <Link className="nav-link active" aria-current="page" to="/">Trang chủ</Link> */}
            </li>
            <li className="nav-item">
              {/* <Link className="nav-link" to="#">Về chúng tôi</Link> */}
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/login">Đăng nhập</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;