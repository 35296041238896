import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ProductFormModal from "../product/ProductFormModal";
import CategorySpotCreate from "../category_spot/CategorySpotCreate";
class TapCodeCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: [],
            dataInput: { ...this.props.datarole },
            isCheckGroup: [], listproduct: [], listTypeSpot: [],
            startDate: this.props.datarole?.receivedDate ? new Date(this.props.datarole?.receivedDate * 1000) : new Date(),
            choiceProduct: {
                value: this.props.datarole.product ? this.props.datarole.product?.id : 0,
                label: this.props.datarole.product ? this.props.datarole.product?.name : 'Chọn sản phẩm'
            },
            choiceSpot: {
                value: this.props.datarole.tapeCodeType ? this.props.datarole.tapeCodeType?.id : 0,
                label: this.props.datarole.tapeCodeType ? this.props.datarole.tapeCodeType?.name : 'Chọn loại spot'
            }, err: {}, showProduct: false, showSpot: false,
            role: {
                id: '',
                role_name: ''
            }
        };
    }

    componentDidMount() {

        this.getAllproduct()
        this.getAllTypeSpot()
    }
    getAllproduct = () => {
        var arr = []
        arr.push({
            value: 0,
            label: "Chọn sản phẩm"
        })
        axios.get(`/product`).then((res) => {
            if (res.data.status == 200) {
                this.setState({ checkload: false })
                res.data.data?.map(e => {
                    arr.push({
                        value: e.id,
                        label: e.product?.name
                    })
                })

                this.setState({ listproduct: arr });

            }
        });
    }
    getAllTypeSpot = () => {
        var arr = []
        arr.push({
            value: 0, label: 'Chọn loại spot'
        })
        axios.get(`/tape-code-type`).then((res) => {
            if (res.data.status == 200) {
                this.setState({ checkload: false })
                res.data.data?.map(e => {
                    arr.push({
                        value: e.id,
                        label: e.name
                    })
                })
                this.setState({ listTypeSpot: arr });

            }
        });
    }


    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        let arrErr = { ...this.state.err }
        delete (arrErr[field])
        this.setState({ dataInput: dataInput });
        this.setState({ err: arrErr });

    }

    hanldeCheck = (e) => {
        const { value, checked } = e.target;

        const dataInput = { ...this.state.dataInput };

        dataInput.permission = [...dataInput.permission, value];

        if (!checked) {
            dataInput.permission = dataInput.permission.filter(
                item => item !== value
            );
        }

        this.setState({ dataInput: dataInput });
    }


    createRole = () => {
        let temp = this.state.dataInput
        let data =
        {
            "code": temp.code,
            "name": temp.name,
            "productId": this.state.choiceProduct.value, // require
            "duration": !isNaN(temp.duration) ? Number(temp.duration) : temp.duration, // require
            "tapeCodeTypeId": this.state.choiceSpot.value,
            "receivedDate": this.state.startDate / 1000
        }

        axios.post(`/tape-code`, data)
            .then((res) => {
                if (res.data.status == 200) {
                    toast(`${res.data.message}`, { type: "success", autoClose: 1000, });
                    return this.props.submit();
                } else {
                    this.setState({ err: res.data.errorMessage })
                    // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
            });
    }

    updateRole = () => {
        let temp = this.state.dataInput
        let data =
        {
            "code": temp.code,
            "name": temp.name,
            "productId": this.state.choiceProduct.value, // require
            "duration": !isNaN(temp.duration) ? Number(temp.duration) : temp.duration, // require
            "tapeCodeTypeId": this.state.choiceSpot.value,
            "receivedDate": this.state.startDate / 1000
        }
        swal({
            title: "Bạn muốn cập nhật mã băng?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.put(`/tape-code/${this.state.dataInput.id}/update`, data)
                    .then((res) => {
                        if (res.data.status == 200) {
                            toast(`${res.data.message}`, { type: "success", autoClose: 1000, });

                            return this.props.submit();

                        } else {
                            this.setState({ err: res.data.errorMessage })
                            // toast(`${res.data.errorMessage}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.dataInput.id === '') {
            this.createRole();
        } else {
            this.updateRole();
        }
    }

    handleHide = () => {
        return this.props.onHide();
    }
    customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: '48px',
            color: state.isFocused ? 'yellow' : 'red',
            boxShadow: state.isFocused ? '0 0 0 4px rgba(199, 246, 255, 1)' : null,
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '20px',
        }),
       
    };

    render() {
        const { onHide, ...other } = { ...this.props };
        const modalClose = () => {
            this.setState({ showProduct: false, showSpot: false })
        }
        const modalSubmitProduct = () => {
            this.setState({ showProduct: false, showSpot: false })
            this.getAllproduct()
        }
        const modalSubmitSpot = () => {
            this.setState({ showProduct: false, showSpot: false })
            this.getAllTypeSpot()
        }
        return (<>
            <Modal show={other.show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title > {other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="code" defaultValue={this.state.dataInput?.code || ''} placeholder="Tên loại spot"
                                        onChange={this.handleInput}
                                    />
                                    <Form.Label>Mã băng<i className="text-danger">*</i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.code}</span>
                                </Form.Floating>
                            </Col>
                            <Col>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="name" defaultValue={this.state.dataInput?.name || ''} placeholder="Tên loại spot"
                                        onChange={this.handleInput}
                                    />
                                    <Form.Label>Tên<i className="text-danger">*</i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.name}</span>

                                </Form.Floating>

                            </Col>
                        </Row>
                        <Row>
                            <Col>    {new Check().permission(["23"]) ? 
                                <Form.Group className="text-end">
                                    <Link
                                        className="fst-italic"
                                        onClick={() => {
                                            // this.renderGroupAdd()
                                            this.setState({
                                                showProduct: true,
                                                type: "TM",
                                            });
                                        }}
                                    >
                                        +Thêm sản phẩm
                                    </Link>
                                </Form.Group>
:null}
                                <Form.Floating className="mb-3">   <span className="pe-2 " style={{
                                    position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                                }}>Sản phẩm</span>
                                    <Select
                                        defaultValue={this.state.listproduct[0]}
                                        styles={this.customStyles}
                                        options={this.state.listproduct}
                                        placeholder="Chọn sản phẩm"
                                        value={this.state.choiceProduct}
                                        onChange={(choice) => {
                                            this.setState({ choiceProduct: choice })

                                        }}
                                    />
                                </Form.Floating>
                            </Col>
                            <Col style={{ zIndex: 2 }}>
                            {new Check().permission(["19"]) ? 
                                <Form.Group className="text-end">
                                    <Link
                                        className="fst-italic"
                                        onClick={() => {
                                            // this.renderGroupAdd()
                                            this.setState({
                                                showSpot: true,
                                                type: "TM",
                                            });
                                        }}
                                    >
                                        +Thêm loại spot
                                    </Link>
                                </Form.Group>:null}
                                <Form.Floating className="mb-3">
                                    <span className="pe-2 " style={{
                                        position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                                    }}>Loại spot</span>
                                    <Select defaultValue={this.state.listTypeSpot[0]}
                                        options={this.state.listTypeSpot}
                                        styles={this.customStyles}
                                        placeholder="Chọn loại spot"
                                        value={this.state.choiceSpot}
                                        onChange={(choice) => {
                                            this.setState({ choiceSpot: choice })

                                        }}
                                    />
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Floating className="mb-1">
                                    <Form.Control type="text" name="duration" defaultValue={this.state.dataInput?.duration || ''} placeholder="Tên loại spot"
                                        onChange={this.handleInput}
                                    />
                                    <Form.Label>Thời lượng<i className="text-danger"></i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.duration}</span>

                                </Form.Floating>

                            </Col>
                            <Col>
                                <span className="pe-2 " style={{
                                    position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                                }}>Ngày nhận băng</span>  <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.setState({ startDate: date ,err:{ ...delete (this.state.err['receivedDate'])}})}
                                 
                                    isClearable
                                    placeholderText="Chọn ngày"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                />
                                  <span className='text-form-err'>{this.state.err.receivedDate}</span>
                            </Col>
                        </Row>


                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit"
                        // disabled = {!new Check().permission(['63','64'])}
                        >
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {this.state.showProduct ? <>
                <ProductFormModal
                    show={this.state.showProduct}
                    onHide={modalClose}
                    modal={{ title: 'Thêm sản phẩm', button: <><i className="fa-solid fa-check" /> Lưu</> }}
                    datarole={this.state.role}
                    submit={modalSubmitProduct}
                />
            </> : null
            }
            {this.state.showSpot ? <>
                <CategorySpotCreate
                    show={this.state.showSpot}
                    onHide={modalClose}
                    modal={{ title: 'Thêm loại spot', button: <><i className="fa-solid fa-check" /> Lưu</> }
                    }
                    datarole={this.state.role}
                    submit={modalSubmitSpot}
                />
            </> : null
            }
        </>
        );
    }
}

export default TapCodeCreate;