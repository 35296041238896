import axios from "axios";
import React, { Component } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Col,
  Form,
  Row,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustommerModal from "./CustommerModal";

import CustomerEditModal from "./CustomerEditModal";

import swal from "sweetalert";
import { toast } from "react-toastify";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listclass: [],
      listgroup: [],
      listuser: [],
      _class: [],
      show: false,
      type: "",
      alertShow: false,
      selectedRows: [],
      listclassID: [],
      showDetail: false,
      countclassid: [],
      listclasssearch: [],
      checkload: true,

      litscustomer: [],
      customerbyID: {},
      listtypeCustomer: [],
      typeCustomer: {
        value: 0,
        label: "Tất cả loại khách hàng",
        keyword: "",
      },
    };
  }

  async componentDidMount() {
    document.title = "Quản lý khách hàng";

    this.getAllCustomer();
    this.getAllCustomerType();
  }

  toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };
  handleStudentSearch = (root_data, text) => {
    let root_ = root_data;
    if (text !== "") {
      root_ = this.state.listclassID.filter((e) =>
        this.toSlug(e.student_name).includes(this.toSlug(text))
      );
    }

    this.setState({ listclasssearch: root_ });
  };
  Search() {
    axios
      .get(
        `/customer/search?customerTypeId=${
          this.state.typeCustomer.value
        }&keyword=${this.state.keyword?.trim() ?? ""}`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ litscustomer: res.data.data });
        }
      });
  }
  xport = () => {
    // swal({
    //   title: "Xác nhận xuất danh sách khách hàng",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, this.listCustomerExport(), "Khách hàng");

    XLSX.writeFile(wb, "Danh sách khách hàng.xlsx");
    //   }
    // })
  };
  listCustomerExport = () => {
    const table = document.getElementById("exportListCustomer");
    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D", "E", "F"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          // sz: 11
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    for (let i = 0; i < this.state.litscustomer.length; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${3 + i}`].v === "empty") {
          ws[`${alpha}${3 + i}`].v = " ";
        }

        ws[`${alpha}${3 + i}`].s = {
          font: {
            name: "Times New Roman",
            // sz: 11
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "left",
            // horizontal: alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H" ? "center" : "left"
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }

    ws["!cols"] = [
      { wch: 15 },
      { wch: 35 },
      { wch: 20 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws["!rows"] = [
      // { hpt: 30 },
      // { hpt: 30 },{ hpt: 30 },{ hpt: 30 },{ hpt: 30 },{ hpt: 30 }
    ];

    return ws;
  };
  // renderUser = () => {
  renderUser() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, show: false });
      // this.refreshRole();
      // this.getAllCustomer()
      this.Search();
      // this.getRoles();
    };
    const renderSearchFilter = () => {
      let options = [];
      this.state.listtypeCustomer?.map((item) => {
        options.push({
          key: item.id,
          value: item.id,
          label: item.name,
        });

        return item;
      }, options.unshift({ value: 0, label: "Tất cả loại khách hàng" }));

      return (
        <Row>
          <Col md={3} xs={5} className="mb-2">
            <InputGroup>
              <Form.Control
                style={{ height: "39px" }}
                onChange={(event) =>
                  this.setState({ keyword: event.target.value })
                }
                value={this.state.keyword}
                placeholder="Nhập tên khách hàng"
              />
            </InputGroup>
          </Col>

          <Col md={3} xs={5} className="mb-2" style={{ zIndex: "2" }}>
            <Select
              defaultValue={options[0]}
              options={options}
              placeholder="Chọn loại khách hàng"
              value={this.state.typeCustomer}
              onChange={(choice) => {
                this.setState({ typeCustomer: choice });
              }}
            />
          </Col>
          <Col md={4} xs={1}>
            <Button
              variant="outline-secondary"
              className="mt-1"
              onClick={() => this.Search()}
            >
              <i className="fa-solid fa-search"></i>
            </Button>
          </Col>
        </Row>
      );
    };
    return (
      <>
        <Card.Header>
          {new Check().permission(["39"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}

          <Button
            type="button"
            variant="success"
            size="sm"
            style={{ float: "right" }}
            onClick={() => this.xport()}
            className="me-2"
          >
            <i className="fa-solid fa-file-export"></i> Xuất DSKH
          </Button>
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách khách hàng
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <CustommerModal
              show={this.state.modalRoleShow}
              onHide={this.formclose}
              close={this.formclose}
              submit={modalSubmit}
              PT={"TM"}
            />
          </>
        ) : null}
        {this.state.show ? (
          <>
            <CustomerEditModal
              show={this.state.show}
              onHide={this.formclose}
              close={this.formclose}
              submit={modalSubmit}
              data={this.state.customerbyID}
              PT={"CN"}
            />
          </>
        ) : null}
      </>
    );
  }

  getAllCustomer = () => {
    axios
      .get(`/customer`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ checkload: false });

          this.setState({
            litscustomer: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllCustomerType = () => {
    axios
      .get(`/customer-type?page=1&limit=1000`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ checkload: false });

          this.setState({
            listtypeCustomer: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteclass = (item, event) => {
    swal({
      title: "Xác nhận xóa khách hàng",
      icon: "error",
      text: item.name,
      buttons: true,
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/customer/${item.id}/delete`)
          .then((res) => {
            if (res.data.status == 200) {
              // this.getAllCustomer();
              this.Search();

              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast(`${res.data.errorMessage}`, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast(`Hệ thống xảy ra lỗi`, {
              type: "error",
              autoClose: 1000,
            });
          });
      }
    });
  };

  formclose = () => {
    this.setState({ modalRoleShow: false, show: false });
    // this.getAllCustomer();
    this.Search();
  };
  getCustomerbyId = (id) => {
    axios.get(`/customer/${id}`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ customerbyID: res.data.data });
        this.setState({
          type: "CN",
          show: true,
        });
      } else {
        this.setState({ customerbyID: [] });
      }
    });
  };

  dataTable() {
    const columns = [
      {
        name: "Tên ngắn",
        selector: (row) => row.shortName,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.shortName}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Loại khách hàng",
        selector: (row) => row.CustomerType,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.CustomerType}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Địa chỉ",
        selector: (row) => row.address,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.address}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Số điện thoại",
        selector: (row) => row.phoneNumber,
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];
    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.litscustomer.length > 0) {
      this.state.litscustomer.map((item, i) => {
        data.push({
          id: item.id,
          shortName: item.shortName,
          address: item.address,
          CustomerType: item.customerType?.name,
          phoneNumber: item.phoneNumber,
          key: item.id + 12,
          setting: (
            <>
              {new Check().permission(["40"]) ? (
                <Button
                  variant="warning"
                  className="m-1"
                  title="Cập nhật"
                  size="sm"
                  onClick={() => {
                    this.getCustomerbyId(item.id);

                    this.setState({
                      _class: {
                        id: item.id,
                        id_group: item.id_group,
                        id_user: item.id_user,
                        class_name: item.class_name,
                      },
                    });
                  }}
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}
              {new Check().permission(["41"]) ? (
                <Button
                  variant="danger"
                  title="Xóa"
                  className="m-1"
                  size="sm"
                  onClick={() => {
                    this.deleteclass(item);
                  }}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  renderClassAdd() {
    return (
      <CustommerModal
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}

        // onClick={()=>this.setState({show:false})}
      />
    );
  }
  getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  getDate_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}`;
  };
  getMonth_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getMonth() + 1}`;
  };
  getYear_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getFullYear()}`;
  };
  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  render() {
    return (
      <>
        <Container fluid>
          {this.renderSelectedRows()}
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Danh mục</Breadcrumb.Item>
            <Breadcrumb.Item active>Khách hàng</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="mb-4">{this.renderUser()}</Card>
          <Table id="exportListCustomer" className="d-none ">
            <thead>
              <tr>
                <th colSpan={6}>DANH SÁCH KHÁCH HÀNG</th>
              </tr>
              <tr>
                <th> Tên ngắn </th>
                <th> Tên </th>
                <th> Loại </th>
                <th> Địa chỉ </th>
                <th> Điện thoại </th>
                <th> Fax </th>
              </tr>
            </thead>
            {this.state.litscustomer?.map((item) => {
              return (
                <tbody>
                  <tr key={`litscustomer${item}`}>
                    <td> {this.changeEmpty(item?.shortName)} </td>
                    <td> {this.changeEmpty(item?.name)} </td>
                    <td> {this.changeEmpty(item?.customerType?.name)} </td>
                    <td> {this.changeEmpty(item?.address)} </td>
                    <td> {this.changeEmpty(item?.phoneNumber)} </td>
                    <td> {this.changeEmpty(item?.faxNumber)} </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </Container>
      </>
    );
  }
}

export default Customer;
