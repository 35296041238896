import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
// import TimePicker from "react-bootstrap-time-picker";
import TimePicker from "react-time-picker";
// import 'react-bootstrap-time-picker/dist/react-bootstrap-time-picker.min.css';
import swal from "sweetalert";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
class PriceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      dataInput: { ...this.props.datarole },
      isCheckGroup: [],
      listproduct: [],
      listTypeSpot: [],
      startDate: this.props.datarole?.receivedDate
        ? new Date(this.props.datarole?.receivedDate * 1000)
        : new Date(),
      choiceProduct: {
        value: this.props.datarole.product
          ? this.props.datarole.product?.id
          : 0,
        label: this.props.datarole.product
          ? this.props.datarole.product?.name
          : "Chọn sản phẩm",
      },
      choiceSpot: {
        value: this.props.datarole.tapeCodeType
          ? this.props.datarole.tapeCodeType?.id
          : 0,
        label: this.props.datarole.tapeCodeType
          ? this.props.datarole.tapeCodeType?.name
          : "Chọn loại spot",
      },
      err: {},
      timeStart: this.props.datarole?.timeStart,
      timeEnd: this.props.datarole?.timeEnd,
      dayApply: this.props.datarole?.dayApply
        ? new Date(this.props.datarole?.dayApply * 1000)
        : new Date(),

      dayEnd: this.props.datarole?.dayEnd
        ? new Date(this.props.datarole?.dayEnd * 1000)
        : new Date(),
      checkType: this.props.datarole.isFixed == true ? 1 : 0,
      isNotify: this.props.datarole.isNotify,
      isBroadcast: this.props.datarole.isBroadcast,
    };
  }

  componentDidMount() {
    this.setState({
      dayApply: this.props.datarole?.dayApply
        ? new Date(this.props.datarole?.dayApply * 1000)
        : new Date(),

      dayEnd: this.props.datarole?.dayEnd
        ? new Date(this.props.datarole?.dayEnd * 1000)
        : new Date(this.changeEndMonth(new Date())),
    });
    this.getAllproduct();
    this.getAllTypeSpot();
  }
  HHMMToSeconds(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const totalSeconds = hours * 3600 + minutes * 60;
    return totalSeconds;
  }
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  getAllproduct = () => {
    var arr = [];
    arr.push({
      value: 0,
      label: "Chọn sản phẩm",
    });
    axios.get(`/product`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        res.data.data?.map((e) => {
          arr.push({
            value: e.id,
            label: e.product?.name,
          });
        });

        this.setState({ listproduct: arr });
      }
    });
  };
  getAllTypeSpot = () => {
    var arr = [];
    arr.push({
      value: 0,
      label: "Chọn loại spot",
    });
    axios.get(`/tape-code-type`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        res.data.data?.map((e) => {
          arr.push({
            value: e.id,
            label: e.name,
          });
        });
        this.setState({ listTypeSpot: arr });
      }
    });
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    if (event.target.name == "price") {
      let parsedValue = event.target.value.replace(/,/g, "");
      if (!isNaN(parsedValue)) {
        parsedValue = parseFloat(parsedValue).toLocaleString();
      }
      dataInput[field] = parsedValue;
    }

    dataInput[field] = value;
    let arrErr = { ...this.state.err };
    delete arrErr[field];
    this.setState({ dataInput: dataInput });
    this.setState({ err: arrErr });
  };

  hanldeCheck = (e) => {
    const { value, checked } = e.target;

    const dataInput = { ...this.state.dataInput };

    dataInput.permission = [...dataInput.permission, value];

    if (!checked) {
      dataInput.permission = dataInput.permission.filter(
        (item) => item !== value
      );
    }

    this.setState({ dataInput: dataInput });
  };

  createRole = () => {
    let temp = this.state.dataInput;
    let data = {
      name: temp.name,
      timeCode: temp.timeCode,
      price: !isNaN(temp.price) ? Number(temp.price) : temp.price,
      // price:temp.price,
      unit: !isNaN(temp.unit) ? Number(temp.unit) : temp.unit,
      priceType: Number(temp.priceType),
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      dayApply: this.state.dayApply / 1000,
      dayEnd: this.state.dayEnd / 1000,
      isFixed: this.state.checkType ? true : false,
      isNotify: this.state.isNotify,
      isBroadcast: this.state.isBroadcast,
    };

    axios
      .post(`/ad-price`, data)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          return this.props.submit();
        } else {
          this.setState({ err: res.data.errorMessage });
          // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };

  updateRole = () => {
    let temp = this.state.dataInput;
    let data = {
      name: temp.name,
      timeCode: temp.timeCode,
      price: !isNaN(temp.price) ? Number(temp.price) : temp.price,
      unit: !isNaN(temp.unit) ? Number(temp.unit) : temp.unit,
      priceType: Number(temp.priceType),
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      dayApply: this.state.dayApply / 1000,
      dayEnd: this.state.dayEnd / 1000,
      isFixed: this.state.checkType ? true : false,
      isNotify: this.state.isNotify,
      isBroadcast: this.state.isBroadcast,
    };
    swal({
      title: "Bạn muốn cập nhật khung giờ?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/ad-price/${this.state.dataInput.id}/update`, data)
          .then((res) => {
            if (res.data.status == 200) {
              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });

              return this.props.submit();
            } else {
              this.setState({ err: res.data.errorMessage });
              // toast(`${res.data.errorMessage}`, { type: "error", autoClose: 1000 });
            }
          })
          .catch((error) => {
            toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
          });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.dataInput.id === "") {
      this.createRole();
    } else {
      this.updateRole();
    }
  };

  handleHide = () => {
    return this.props.onHide();
  };
  customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "48px",
      color: state.isFocused ? "yellow" : "red",
      boxShadow: state.isFocused ? "0 0 0 4px rgba(199, 246, 255, 1)" : null,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "20px",
    }),
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> {other.modal.title}</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="timeCode"
                      defaultValue={this.state.dataInput?.timeCode || ""}
                      placeholder="Tên loại spot"
                      required
                      onChange={this.handleInput}
                    />
                    <Form.Label>
                      Ký hiệu<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err.timeCode}
                    </span>
                  </Form.Floating>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      defaultValue={this.state.dataInput?.name || ""}
                      placeholder="Tên loại spot"
                      required
                      onChange={this.handleInput}
                    />
                    <Form.Label>
                      Tên block<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">{this.state.err.name}</span>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    {/* <Form.Control type="text" name="price" 
                                 
                                    value={this.state.dataInput?.price }
                                     placeholder="Tên loại spot"
                                        onChange={this.handleInput}
                                    /> */}

                    <CurrencyFormat
                      className="form-control"
                      name="price"
                      thousandSeparator={true}
                      placeholder="Giá nhập"
                      required
                      value={this.state.dataInput?.price}
                      onValueChange={(values) => {
                        const { value } = values;
                        let dataInput = { ...this.state.dataInput };
                        dataInput.price = value;
                        this.setState({ dataInput: dataInput });
                      }}
                    />

                    <Form.Label>
                      Giá<i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">
                      {this.state.err.price}
                    </span>
                  </Form.Floating>
                </Col>

                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      defaultValue={this.state.dataInput?.priceType || ""}
                      onChange={this.handleInput}
                      name="priceType"
                      required
                    >
                      <option value="0">--Chọn--</option>
                      <option value="1">Thông báo</option>
                      <option value="2">Quảng cáo</option>
                      <option value="4">Mạng xã hội</option>
                    </Form.Select>
                    <Form.Label>
                      Loại bảng giá<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err.priceType}
                    </span>
                  </Form.Floating>
                </Col>
                <Col className="mb-3">
                  <Form.Check
                    inline
                    label="Tính tỉ lệ"
                    name="group1"
                    type="radio"
                    id={`inline-1`}
                    onChange={() => this.setState({ checkType: 0 })}
                    checked={this.state.checkType == "0"}
                  />
                  <Form.Check
                    inline
                    label="Không tính tỉ lệ (giá cứng)"
                    name="group1"
                    type="radio"
                    id={`inline-2`}
                    onChange={() => this.setState({ checkType: 1 })}
                    checked={this.state.checkType == "1"}
                  />
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="unit"
                      defaultValue={this.state.dataInput?.unit || ""}
                      placeholder="Tên loại spot"
                      onChange={this.handleInput}
                      required
                    />
                    <Form.Label>
                      Thời lượng<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">{this.state.err.unit}</span>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col className="col-md-3 col-6">
                  <span className="me-2">
                    <span
                      className="pe-2 "
                      style={{
                        position: "absolute",
                        marginLeft: "8px",
                        zIndex: 1,
                        color: "#757e86",
                      }}
                    >
                      Thời gian bắt đầu <i className="text-danger">*</i>
                    </span>
                    <TimePicker
                      onChange={(e) => this.setState({ timeStart: e })}
                      value={this.state.timeStart}
                      required
                      disableClock
                    />
                    <span className="text-form-err">
                      {this.state.err.timeStart}
                    </span>
                  </span>
                </Col>
                <Col className="col-md-3 col-6">
                  <span className="">
                    <span
                      className="pe-2 "
                      style={{
                        position: "absolute",
                        marginLeft: "8px",
                        zIndex: 1,
                        color: "#757e86",
                      }}
                    >
                      Thời gian kết thúc
                    </span>
                    <TimePicker
                      onChange={(e) => this.setState({ timeEnd: e })}
                      value={this.state.timeEnd}
                      disableClock
                    />
                    <span className="text-form-err">
                      {this.state.err.timeEnd}
                    </span>
                  </span>
                </Col>

                <Col>
                  <span
                    className="pe-2 "
                    style={{
                      position: "absolute",
                      marginLeft: "8px",
                      zIndex: 1,
                      color: "#757e86",
                    }}
                  >
                    Ngày bắt đầu<i className="text-danger">*</i>{" "}
                  </span>{" "}
                  <DatePicker
                    selected={this.state.dayApply}
                    onChange={(date) => this.setState({ dayApply: date })}
                    isClearable
                    placeholderText="Chọn ngày"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    required
                  />
                </Col>
                <Col>
                  <span
                    className="pe-2 "
                    style={{
                      position: "absolute",
                      marginLeft: "8px",
                      zIndex: 1,
                      color: "#757e86",
                    }}
                  >
                    Ngày kết thúc<i className="text-danger">*</i>
                  </span>{" "}
                  <DatePicker
                    selected={this.state.dayEnd}
                    onChange={(date) =>
                      this.setState({
                        dayEnd: date,
                        err: delete this.state.err?.dayEnd,
                      })
                    }
                    isClearable
                    minDate={this.state.dayApply}
                    placeholderText="Chọn ngày"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    required
                  />
                  <span className="text-form-err">{this.state.err.dayEnd}</span>
                </Col>
              </Row>
              {this.state.dataInput?.priceType == 4 ? null : (
                <Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Dùng cho thông báo"
                      name="isNotify"
                      id={`isNotify`}
                      onChange={() =>
                        this.setState({ isNotify: !this.state.isNotify })
                      }
                      checked={this.state.isNotify}
                    />

                    <Form.Check
                      inline
                      label="Phát thanh"
                      name="isBroadcast"
                      id={`isBroadcast`}
                      onChange={() =>
                        this.setState({ isBroadcast: !this.state.isBroadcast })
                      }
                      checked={this.state.isBroadcast}
                    />
                  </Col>
                </Row>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button
                size="sm"
                variant="success"
                type="submit"
                // disabled = {!new Check().permission(['63','64'])}
              >
                {other.modal.button}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default PriceModal;
