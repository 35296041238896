import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import CurrencyFormat from "react-currency-format";
// import TimePicker from "react-bootstrap-time-picker";
import TimePicker from 'react-time-picker';
// import 'react-bootstrap-time-picker/dist/react-bootstrap-time-picker.min.css';
import swal from "sweetalert";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import zIndex from "@mui/material/styles/zIndex";
class ScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissions: [],
            dataInput: { ...this.props.datarole },
            isCheckGroup: [], listproduct: [], listTypeSpot: [], listTapeCode: [],

            choiceProduct: {
                value: this.props.datarole.product ? this.props.datarole.product?.id : 0,
                label: this.props.datarole.product ? this.props.datarole.product?.name : 'Chọn sản phẩm'
            },
            choiceSpot: {
                value: this.props.datarole.tapeCodeType ? this.props.datarole.tapeCodeType?.id : 0,
                label: this.props.datarole.tapeCodeType ? this.props.datarole.tapeCodeType?.name : 'Chọn mã băng'
            }, err: {}
            ,
            dayApply: this.props.datarole?.broadcastStartDate ? new Date(this.props.datarole?.broadcastStartDate * 1000) : new Date(),

            dayEnd: this.props.datarole?.broadcastEndDate ? new Date(this.props.datarole?.broadcastEndDate * 1000) : new Date(),
            listContract: [], choiceContract: {
                value: 0,
                label: this.props.datarole.product ? this.props.datarole.product?.name : 'Chọn hợp đồng'

            },
            code: this.props.datarole.calendarCode ?? ''

        };
    }

    componentDidMount() {
        this.setState({
            dayEnd:
                this.props.datarole?.broadcastEndDate ? new Date(this.props.datarole?.broadcastEndDate * 1000) :
                    this.changeEndMonth(new Date())
            , dayApply: this.props.datarole?.broadcastStartDate ? new Date(this.props.datarole?.broadcastStartDate * 1000) :

                this.changeStartMonth(new Date())
        },()=>{
            this.getAllproduct()
            this.getAllTypeSpot()
            this.getAllTapeCode()
            this.getAllContract()
            this.getAllContract()
        })

    }
    HHMMToSeconds(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const totalSeconds = hours * 3600 + minutes * 60;
        return totalSeconds;
    }
    changeEndMonth = (date) => {
        return new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59))
    }
    changeStartMonth = (date) => {
        return new Date(new Date(date.setDate(1)).setHours(0, 0, 0))
    }
    getAllContract = () => {
        var arr = []
        arr.push({
            value: 0,
            label: "Chọn hợp đồng"
        })
        axios.get(`/contract/?keyword=&effectiveDate=&expirationDate=0&contractTypeId=&customerId=&contractView=2`).then((res) => {
            if (res.data.status == 200) {
                this.setState({ checkload: false })
                res.data.data?.map(e => {
                    arr.push({
                        value: e.id,
                        label: e.contractCode + ' (' + this.getDate(e.effectiveDate) + " - " + this.getDate(e.expirationDate) + ") ",
                        customerName: e.customer?.name

                    })
                })
                this.setState({ choiceContract: arr?.filter(item => item.value == this.props.datarole?.contractId)[0] })
                this.setState({ listContract: arr });
            }
        });
    }
    getDate = (data) => {
        const date = new Date(data * 1000);
        // date.setHours(0, 0, 0, 0);
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + " ";
        // ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes() + 1)).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
        // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    }
    getAllproduct = () => {
        var arr = []
        arr.push({
            value: 0,
            label: "Chọn sản phẩm"
        })
        axios.get(`/product`).then((res) => {
            if (res.data.status == 200) {
                this.setState({ checkload: false })
                res.data.data?.map(e => {
                    if (!e?.product?.isNotDelete) {
                        arr.push({
                            value: e.product?.id,
                            label: e.product?.name,

                        })
                    }

                })
                this.setState({
                    choiceProduct: arr?.filter(item => item.value == this.props.datarole?.tapeCode?.product?.id)[0]
                })
                this.setState({ listproduct: arr });

            }
        });
    }
    getAllTapeCode = () => {
        var arr = []
        arr.push({
            value: 0,
            label: "Chọn mã băng",
            id: 0
        })
        axios.get(`/tape-code`).then((res) => {
            if (res.data.status == 200) {
                this.setState({ checkload: false })
                res.data.data?.map(e => {
                    arr.push({
                        value: e.id,
                        label: e.code,
                        id: e.id, 
                        productid: e.product?.id,
                        tapcodeName: e.name,
                        duration: e.duration
                    })
                })
                this.setState({
                    choiceSpot: arr?.filter(item => item.value == this.props.datarole?.tapeCode?.id)[0]
                })
                this.setState({ listTapeCode: arr });

            }
        });
    }
    getAllTypeSpot = () => {
        var arr = []
        arr.push({
            value: 0, label: 'Chọn loại spot'
        })
        axios.get(`/tape-code-type`).then((res) => {
            if (res.data.status == 200) {
                this.setState({ checkload: false })
                res.data.data?.map(e => {
                    arr.push({
                        value: e.id,
                        label: e.name
                    })
                })
                this.setState({ listTypeSpot: arr });

            }
        });
    }


    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        let arrErr = { ...this.state.err }
        delete (arrErr[field])
        this.setState({ dataInput: dataInput });
        this.setState({ err: arrErr });
    }

    hanldeCheck = (e) => {
        const { value, checked } = e.target;

        const dataInput = { ...this.state.dataInput };

        dataInput.permission = [...dataInput.permission, value];

        if (!checked) {
            dataInput.permission = dataInput.permission.filter(
                item => item !== value
            );
        }

        this.setState({ dataInput: dataInput });
    }





    handleSubmit = (e) => {

        e.preventDefault();
        if (this.state.dataInput.id == '' || this.state.dataInput.id == undefined || this.state.dataInput.id == null) {
            this.createSchedule()
        } else {
            this.updateSchedule();
        }

    }
    updateSchedule = () => {
        let data =
        {
            "calendarCode": this.state.code,
            "contractId": this.state.choiceContract?.value, // require
            "tapeCodeId": this.state.choiceSpot?.value, // require
            "broadcastStartDate": this.state.dayApply / 1000,
            "broadcastEndDate": this.state.dayEnd / 1000
        }
        swal({
            title: "Bạn muốn cập nhật lịch quảng cáo?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.put(`/schedule/${this.state.dataInput.id}/update`, data).then((res) => {
                    if (res.data.status == 200) {
                        toast(`${res.data.message}`, { type: "success", autoClose: 1000, });
                        return this.props.submit();
                    } else {
                        this.setState({ err: res.data.errorMessage })

                        // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
                    }
                })
                    .catch((error) => {
                        console.log(error)
                        // toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        })

    }
    createSchedule = () => {
        let data =
        {
            "calendarCode": this.state.code,
            "contractId": this.state.choiceContract?.value, // require
            "tapeCodeId": this.state.choiceSpot?.value, // require
            "broadcastStartDate": this.state.dayApply / 1000,
            "broadcastEndDate": this.state.dayEnd / 1000
        }

        axios.post(`/schedule`, data).then((res) => {
            if (res.data.status == 200) {
                toast(`${res.data.message}`, { type: "success", autoClose: 1000, });
                return this.props.submit();
            } else {
                this.setState({ err: res.data.errorMessage })

                // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
            }
        })
            .catch((error) => {
                console.log(error)
                // toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
            });
    }
    handleHide = () => {
        return this.props.onHide();
    }
    customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: '48px',
            boxShadow: state.isFocused ? '0 0 0 4px rgba(199, 246, 255, 1)' : null,
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '20px',
        }), menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 2

        })
    };

    render() {
        const { onHide, ...other } = { ...this.props };

        return (<>
            <Modal show={other.show} onHide={onHide} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title > {other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col>

                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="name"
                                        defaultValue={this.state.choiceContract?.customerName || ''}
                                        placeholder="Tên loại spot" required
                                        readOnly
                                    />
                                    <Form.Label>Khách hàng<i className="text-danger"></i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.timeCode}</span>
                                </Form.Floating>
                            </Col>
                            <Col style={{ zIndex: 4 }}>

                                <Select
                                    options={this.state.listContract}
                                    value={this.state.choiceContract}
                                    styles={
                                        this.customStyles
                                    }
                                    onChange={(choice) => this.setState({ choiceContract: choice, err: { ...delete (this.state.err['contractId']) } })}
                                    required
                                    isDisabled={!(this.state.dataInput.id == '' || this.state.dataInput.id == undefined || this.state.dataInput.id == null)}
                                />
                                <div className="placeholder-select">Chọn hợp đồng<i className="text-danger">*</i> </div>
                                <span className="text-form-err">{this.state.err.contractId}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="code" defaultValue={this.state.code || ''} placeholder="Tên loại spot"
                                        onChange={(e) => this.setState({ code: e.target.value, err: { ...delete (this.state.err['calendarCode']) } })}
                                    />
                                    <Form.Label>Mã lịch<i className="text-danger"></i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.calendarCode}</span>

                                </Form.Floating>
                            </Col>

                            <Col style={{ zIndex: 3 }}>

                                <Select
                                    options={this.state.listproduct}
                                    defaultValue={this.state.listproduct[0]}
                                    value={this.state.choiceProduct}
                                    styles={
                                        this.customStyles
                                    }
                                    onChange={(choice) => this.setState({ choiceProduct: choice ,choiceSpot:{
                                        value:0,
                                        label:"Chọn mã băng"
                                    }})}
                                />
                                <div className="placeholder-select">Chọn sản phẩm</div>
                            </Col>

                        </Row>
                        <Row>
                            <Col className="mb-3" md={4} xs={5}>

                                <Select
                                    options=
                                    
                                    {
                                        !this.state.choiceProduct ||this.state.choiceProduct?.value==0?
                                        this.state.listTapeCode:
                                        this.state.listTapeCode?.filter(e => e?.productid == this.state.choiceProduct?.value)
                                    
                                    }
                                    value={this.state.choiceSpot}
                                    styles={
                                        this.customStyles
                                    }
                                    // isDisabled={this.state.choiceProduct ? this.state.choiceProduct.value == 0 : 1}
                                    onChange={(choice) => this.setState({ choiceSpot: choice, err: { ...delete (this.state.err['tapcodeId']) } })}
                                />
                                <div className="placeholder-select">Chọn mã băng<i className="text-danger">*</i> </div>
                                <span className="text-form-err">{this.state.err.tapeCodeId}</span>
                            </Col>
                            <Col md={6} xs={4}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="timeCode"
                                        defaultValue={this.state.choiceSpot?.tapcodeName || ''}
                                        placeholder="Tên loại spot" required
                                        readOnly

                                    />
                                    <Form.Label>Tên mã băng<i className="text-danger"></i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.timeCode}</span>
                                </Form.Floating>
                            </Col>
                            <Col md={2} xs={3}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="timeCode"
                                        defaultValue={this.state.choiceSpot?.duration || ''}
                                        placeholder="Tên loại spot" required
                                        readOnly

                                    />
                                    <Form.Label>Thời lượng<i className="text-danger"></i></Form.Label>
                                    <span className='text-form-err'>{this.state.err.timeCode}</span>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <span className="pe-2 " style={{
                                    position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                                }}>Ngày bắt đầu<i className="text-danger">*</i> </span> <DatePicker
                                    selected={this.state.dayApply}
                                    onChange={(date) => this.setState({ dayApply: date, err: { ...delete (this.state.err['broadcastEndDate']) } })}
                                    isClearable
                                    placeholderText="Chọn ngày"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    required
                                />
                                <span className='text-form-err'>{this.state.err.broadcastStartDate}</span>

                            </Col>
                            <Col style={{ zIndex: 2 }}>
                                <span className="pe-2 " style={{
                                    position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                                }}>Ngày kết thúc<i className="text-danger">*</i></span>   <DatePicker
                                    selected={this.state.dayEnd}
                                    onChange={(date) => this.setState({ dayEnd: date, err: { ...delete (this.state.err['broadcastEndDate']) } })}
                                    isClearable
                                    placeholderText="Chọn ngày"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    required

                                />  <span className='text-form-err'>{this.state.err.broadcastEndDate}</span>

                            </Col>
                        </Row>
                        <Row>
     
                            <Col className={this.state.dataInput.id == '' || this.state.dataInput.id == undefined || this.state.dataInput.id == null?"d-none":null}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" name="timeCode"
                                        value={this.state.dataInput?.creator?.fullName || ''}
                                        placeholder="Tên loại spot"
                                        readOnly
                                      

                                    />
                                    <Form.Label>Người nhập<i className="text-danger"></i></Form.Label>

                                </Form.Floating>
                            </Col>
                            <Col className={this.state.dataInput.id == '' || this.state.dataInput.id == undefined || this.state.dataInput.id == null?"d-none":null}>
                                <span className="pe-2 " style={{
                                    position: 'absolute', marginLeft: '8px', zIndex: 1, color: '#757e86'
                                }}>Ngày tạo<i className="text-danger"></i></span>   <DatePicker
                                    selected={
                                        this.state.dataInput?.createdAt ?
                                            new Date(this.state.dataInput?.createdAt * 1000
                                            ) : new Date()
                                    }

                                    placeholderText="Chọn ngày"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                  
                                    disabled

                                />
                            </Col>
                        </Row>


                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit"
                        // disabled = {!new Check().permission(['63','64'])}
                        >
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
        );
    }
}

export default ScheduleModal;
