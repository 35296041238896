import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";
import Select from 'react-select'
import { Link } from "react-router-dom";
import SupplierFormModal from "../Supplier/SupplierFormModal";
class ProductFormModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permissions: [],
            dataInput: { ...this.props.datarole },
            isCheckGroup: [], listsupplier: [], err: {},
            selectSupplier: {
                value: this.props.datarole.supplierid,
                label: this.props.datarole.supplier
            }, modalSupplier: false,
            role: {
                id: '',
                role_name: ''
            }
        };
    }

    componentDidMount() {
        this.getPermissions();
        this.getlistcustomer()
    }
    getlistcustomer = () => {
        var arr = []
        arr.push({
            value: 0,
            label: "Chọn nhà cung cấp"
        })
        axios
            .get(`/supplier`).then((res) => {
                if (res.data.status === 200) {
                    this.setState({ checkload: false })
                    res.data.data?.map(e => {
                        arr.push({
                            value: e.id,
                            label: e.name
                        })
                    })
                    this.setState({
                        listsupplier: arr,

                    });

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getPermissions = () => {
        // axios.get("/viewPermission")
        //     .then((res) => {
        //         this.setState({ permissions: res.data.data });
        //     });
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        this.setState({ dataInput: dataInput });
    }

    hanldeCheck = (e) => {
        const { value, checked } = e.target;

        const dataInput = { ...this.state.dataInput };

        dataInput.permission = [...dataInput.permission, value];

        if (!checked) {
            dataInput.permission = dataInput.permission.filter(
                item => item !== value
            );
        }

        this.setState({ dataInput: dataInput });
    }

    handleSelectGroup = (e) => {
        const { value, checked } = e.target;
        const idChildArr = (e.target.dataset.id_child).split(',');

        if (idChildArr.length > 0) {
            const dataInput = { ...this.state.dataInput };

            dataInput.permission = [...dataInput.permission, ...idChildArr];
            this.setState({ dataInput: dataInput });


            if (!checked) {
                idChildArr.map((id, i) => {
                    dataInput.permission = dataInput.permission.filter(item => item !== id);

                    this.setState({ dataInput: dataInput });

                    return id;
                });


            }


        }

        this.setState({ isCheckGroup: [...this.state.isCheckGroup, String(value)] });

        if (!checked) {
            this.setState({ isCheckGroup: this.state.isCheckGroup.filter(item => item !== String(value)) });
        }
    }

    createRole = () => {
        let data =
        {
            supplierId: this.state.selectSupplier.value,
            name: this.state.dataInput.role_name
        }
        axios.post(`/product`, data)

            // axios.post(`/createRole?role_name=${data.role_name}&permission=${data.permission.toString()}`)
            .then((res) => {
                if (res.data.status == 200) {
                    toast(`${res.data.message}`, { type: "success", autoClose: 1000, });
                    return this.props.submit();
                } else {

                    toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
            });
    }

    updateRole = () => {
        let data =
        {
            supplierId: this.state.selectSupplier.value,
            name: this.state.dataInput.role_name
        }
        swal({
            title: "Bạn muốn cập nhật sản phẩm?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.put(`/product/${this.state.dataInput.id}/update`, data)
                    .then((res) => {
                        if (res.data.status == 200) {
                            toast(`${res.data.message}`, { type: "success", autoClose: 1000, });

                            return this.props.submit();

                        } else {
                            toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.dataInput.id === '') {
            this.createRole();
        } else {
            this.updateRole();
        }
    }

    handleHide = () => {
        return this.props.onHide();
    }

    customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: '48px',
            color: state.isFocused ? 'yellow' : 'red',
            boxShadow: state.isFocused ? '0 0 0 4px rgba(199, 246, 255, 1)' : null,
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,



        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            // color: state.isFocused ? 'yellow' : 'blue',
            // fontWeight: 'bold', 
            marginTop: '20px',
            // background: 'aqua'
        }),
    };
    render() {
        const { onHide, ...other } = { ...this.props };
        const modalClose = () => {
            this.setState({ modalSupplier: false })
        }
        const modalSubmit = () => {
            this.setState({ modalSupplier: false })
            this.getlistcustomer()
        }
        return (<>
            <Modal show={other.show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title ><i className="fa-solid fa-store" /> {other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                    {new Check().permission(["15"]) ? (
                        <Form.Group className="text-end">
                            <Link
                                className="fst-italic"
                                onClick={() => {
                                    // this.renderGroupAdd()
                                    this.setState({
                                        modalSupplier: true,
                                        type: "TM",
                                    });
                                }}
                            >
                                +Thêm nhà cung cấp
                            </Link>
                        </Form.Group>):null}
                        <Form.Floating className="mb-3">
                            <Select
                                styles={this.customStyles}

                                // defaultValue={options[0]}
                                options={this.state.listsupplier}
                                placeholder=""
                                value={this.state.selectSupplier}
                                onChange={(choice) => {
                                    this.setState({ selectSupplier: choice })

                                }}
                            />
                            <div className="placeholder-select">Chọn nhà cung cấp</div>
                        </Form.Floating>
                        <Form.Floating className="mb-1">
                            <Form.Control type="text" name="role_name" defaultValue={this.state.dataInput?.role_name || ''} placeholder="Tên sản phẩm"
                                onChange={this.handleInput}
                            />
                            <Form.Label>Tên sản phẩm<i className="text-danger">*</i></Form.Label>

                        </Form.Floating>


                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit"
                        // disabled = {!new Check().permission(['63','64'])}
                        >
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {this.state.modalSupplier ? <>
                <SupplierFormModal
                    show={this.state.modalSupplier}
                    onHide={modalClose}
                    modal={{ title: 'Thêm nhà cung cấp', button: <><i className="fa-solid fa-check" /> Lưu</> }}
                    datarole={this.state.role}
                    submit={modalSubmit}
                />
            </> : null
            }

        </>
        );
    }
}

export default ProductFormModal;