import React from "react";
import "./assets/fontawesome-free-6.2.1-web/css/fontawesome.css";
// import "./assets/fontawesome-free-6.2.1-web/css/brands.css";
import "./assets/fontawesome-free-6.2.1-web/css/solid.css";
// import "./assets/fontawesome-free-6.2.1-web/css/regular.css";
import Router from "./routes/router";
import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000/api/kfood/v1'}/`;

axios.defaults.headers.post["Content-Type"] = 'application/json';
axios.defaults.headers.post["Accept"] = "*/*";

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers["Authorization"] = localStorage.getItem("access_token_ctv")
  ? "Bearer " +
  localStorage.getItem("access_token_ctv")
  : "";

export default function App() {
  return <Router />;
}
