import React from "react";
import DataTable from "react-data-table-component";
// import {Card} from "react-bootstrap";

function DataTables(props) {
  const stripedStyle = {
    background: 'linear-gradient(to right, #f2f2f2 50%, white 50%)'
  };
  const customStyles = {
    head: {
      style: {
        top: "-1px",
      },
    },
    headRow: {
      style:( {
        backgroundColor: '#c7f2fb'
        // border: "none",
      }),
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "13px !important",
        zIndex: "unset",
        whiteSpace:'unset'
      },

    },


    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgba(0, 0, 0, 0.175)",
        // borderColor: 'rgba(0, 0, 0, 0.175)',
        // borderRadius: "25px",
        outline: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom:' 1px solid rgba(0, 0, 0, 0.12)'
        // style:stripedStyle

      },

    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
 

const handleRowSelected = (state) => {
  if (props.selectedRows) {
    return props.selectedRows(state.selectedRows);
  }
};

return (
  <DataTable
    // columns={props.columns || []}
    // data={props.data || []}
    className="striped-rows"
    customStyles={customStyles }
    highlightOnHover
    // pointerOnHover
    // selectableRows={props.select}
    onSelectedRowsChange={handleRowSelected}

    fixedHeader={true}
    fixedHeaderScrollHeight={'568px'}

    pagination
    paginationPerPage={30}
    paginationRowsPerPageOptions={[30, 50, 100, 500]}
    paginationComponentOptions={{
      rowsPerPageText: "Hiển thị:",
      rangeSeparatorText: "của",
    }}

    noDataComponent=
    "Không có dữ liệu..."

    {...props}

  />
);
}

export default DataTables;
