import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";

class SupplierFormModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permissions: [],
            dataInput: { ...this.props.datarole },
            isCheckGroup: [],
        };
    }

    componentDidMount() {
        this.getPermissions();
    }

    getPermissions = () => {
        // axios.get("/viewPermission")
        //     .then((res) => {
        //         this.setState({ permissions: res.data.data });
        //     });
    }

    handleInput = (event) => {
        let field = event.target.name;
        let value = event.target.value;
        let dataInput = { ...this.state.dataInput };
        dataInput[field] = value;
        this.setState({ dataInput: dataInput });
    }

    hanldeCheck = (e) => {
        const { value, checked } = e.target;

        const dataInput = { ...this.state.dataInput };

        dataInput.permission = [...dataInput.permission, value];

        if (!checked) {
            dataInput.permission = dataInput.permission.filter(
                item => item !== value
            );
        }

        this.setState({ dataInput: dataInput });
    }

    handleSelectGroup = (e) => {
        const { value, checked } = e.target;
        const idChildArr = (e.target.dataset.id_child).split(',');

        if (idChildArr.length > 0) {
            const dataInput = { ...this.state.dataInput };

            dataInput.permission = [...dataInput.permission, ...idChildArr];
            this.setState({ dataInput: dataInput });


            if (!checked) {
                idChildArr.map((id, i) => {
                    dataInput.permission = dataInput.permission.filter(item => item !== id);

                    this.setState({ dataInput: dataInput });

                    return id;
                });


            }


        }

        this.setState({ isCheckGroup: [...this.state.isCheckGroup, String(value)] });

        if (!checked) {
            this.setState({ isCheckGroup: this.state.isCheckGroup.filter(item => item !== String(value)) });
        }
    }

    createRole = () => {
        let data =
        {
            name: this.state.dataInput.role_name
        }
        axios.post(`/supplier`, data)

            // axios.post(`/createRole?role_name=${data.role_name}&permission=${data.permission.toString()}`)
            .then((res) => {
                if (res.data.status==200) {
                    toast(`${res.data.message}`, { type: "success", autoClose: 1000, });
                    return this.props.submit();
                } else {
                    toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
            });
    }

    updateRole = () => {
        let data = 
        {name:this.state.dataInput.role_name};
        swal({
            title: "Bạn muốn cập nhật nhà cung cấp?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then((ok) => {
            if (ok) {
                axios.put(`/supplier/${this.state.dataInput.id}/update`,data)
                    .then((res) => {
                        if (res.data.status==200) {
                            toast(`${res.data.message}`, { type: "success", autoClose: 1000, });

                            return this.props.submit();

                        } else {
                            toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
                        }
                    })
                    .catch((error) => {
                        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
                    });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.dataInput.id === '') {
            this.createRole();
        } else {
            this.updateRole();
        }
    }

    handleHide = () => {
        return this.props.onHide();
    }

 
    render() {
        const { onHide, ...other } = { ...this.props };
        return (<>
            <Modal show={other.show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title ><i className="fa-solid fa-store" /> {other.modal.title}</Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-1">
                            <Form.Control type="text" name="role_name" defaultValue={this.state.dataInput?.role_name || ''} placeholder="Tên nhà cung cấp"
                                onChange={this.handleInput}
                            />
                            <Form.Label>Tên nhà cung cấp<i className="text-danger">*</i></Form.Label>
                        </Form.Floating>

                      
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" id="btnCloseAddIngredent" onClick={this.handleHide}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>

                        <Button size="sm" variant="success" type="submit"
                        // disabled = {!new Check().permission(['63','64'])}
                        >
                            {other.modal.button}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
        );
    }
}

export default SupplierFormModal;