import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MasterLayout from "../layouts/admin/MasterLayout";
import Home from "../components/frontend/Home";
import Login from "../components/frontend/auth/Login";
// import CreateSchool from "../components/admin/school/CreateSchool";

import Error404 from "../components/admin/errors/Error404";
import ErrorHome404 from "../components/admin/errors/ErrorHome404";
import UserIndex from "../components/admin/user/UserIndex";
import Customer from "../components/admin/customer/Customer";
import Category_Customer from "../components/admin/category_customer/Category_Customer";
import Category_Contract from "../components/admin/category_contract/Category_Customer";
import Contract from "../components/admin/contract/Contract";
import RoleIndex from '../components/admin/role/RoleIndex'
import PositionIndex from '../components/admin/position/PositionIndex'
import SupplierIndex from "../components/admin/Supplier/SupplierIndex";
import CategoryProductIndex from "../components/admin/category_product/CategoryProductIndex";
import CompanyIndex from "../components/admin/company/CompanyIndex";
import ProductIndex from "../components/admin/product/ProductIndex";
import CategorySpotIndex from "../components/admin/category_spot/Category_Spot";
import TapCodeIndex from "../components/admin/tap_code/TapCode";
import PriceIndex from "../components/admin/price/Price";
import Schedule from "../components/admin/Schedule/Schedule";
import RegisterBlockIndex from "../components/admin/registerBlock/RegisterBlockIndex";
import ScheduleDaily from "../components/admin/scheduleDaily/ScheduleDaily";
import SpotDetail from "../components/admin/Report/SpotDetail";
import MonthlySummary from "../components/admin/Report/MonthlySummary";
import MonthlySummaryList from "../components/admin/Report/MonthlySummaryList";
import SummaryList from "../components/admin/Report/SummaryList";
import YearSummaryList from "../components/admin/Report/YearSummaryList";
export default function router() {

  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/katec/create-school" element={<CreateSchool />} /> */}
          {/* <Route path="admin" element={<MasterLayout />}> */}
          <Route path="/" element={<MasterLayout />}>
          <Route path="/admin" element={<Home />} />
         
          <Route path="/admin/user" element={<UserIndex />} />
          <Route path="/admin/category-customer" element={<Category_Customer />} />
          <Route path="/admin/customer" element={<Customer />} />
          <Route path="/admin/category-contract" element={<Category_Contract />} />
          <Route path="/admin/contract" element={<Contract />} />
          <Route path="/admin/contract-notification" element={<Contract page='notification' />} />
          <Route path="/admin/contract-advertisement" element={<Contract page='advertisement'/>} />
          <Route path="/admin/schedule-notification-daily" element={<ScheduleDaily element='notification' id='test1' key='notification'/>} />
          <Route path="/admin/schedule-advertisement-daily" element={<ScheduleDaily element='advertisement' id='test2' key='advertisement'/>} />

          <Route path="/admin/role" element={<RoleIndex />} />
          <Route path="/admin/position" element={<PositionIndex />} />
          <Route path="/admin/supplier" element={<SupplierIndex />} />
          <Route path="/admin/category-product" element={<CategoryProductIndex />} />
          <Route path="/admin/company" element={<CompanyIndex />} />
          <Route path="/admin/product" element={<ProductIndex />} />
          <Route path="/admin/category-spot" element={<CategorySpotIndex />} />
          <Route path="/admin/tap-code" element={<TapCodeIndex />} />
          <Route path="/admin/price" element={<PriceIndex />} />
          <Route path="/admin/schedule" element={<Schedule />} />
          <Route path="/admin/register-block" element={<RegisterBlockIndex />} />
          
          <Route path="/admin/report-spot-detail" element={<SpotDetail />} />
          <Route path="/admin/monthly-summary" element={<MonthlySummary />} />
          <Route path="/admin/monthly-summary-list" element={<MonthlySummaryList />} />
          <Route path="/admin/summary-list" element={<SummaryList />} />
          <Route path="/admin/year-summary-list" element={<YearSummaryList />} />

          
            <Route path="*" element={<Error404 />} />
          </Route>

          <Route path="*" element={<ErrorHome404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
