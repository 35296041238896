import React from 'react';
import {Link } from 'react-router-dom';

const Error404 = () => {
    return (
        <div id="layoutError_content">
            <main>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mt-4">
                                <img className="mb-4 img-error" src="/assets/img/banner/404.webp" alt="error404" />
                                <h1 >Error 404 </h1>

                                <p className="lead">Không tìm thấy URL được yêu cầu.</p>
                                <Link to="/admin" className='btn btn-primary'>
                                    <i className="fas fa-arrow-left me-1"></i> Quay lại Tổng quan
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Error404;