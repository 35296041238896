import axios from "axios";
import React, { Component } from "react";
import { Button, Form, Modal, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import GroupCreate from "../category_customer/GroupCreate";
import Check from "../other/Check";
import Select from "react-select";
import CategoryCustomerCreate from "../category_customer/CategoryCustomerCreate";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
class CustomerEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foodDetail:
        this.props.foodDetail != null
          ? this.props.foodDetail
          : {
              food_name: "",
              id_unit: 0,
              id_category: 0,
            },
      ingredientFoodDetail: [],

      units: [],
      categories: [],
      categorySelected:
        this.props.foodDetail != null
          ? this.props.foodDetail.id_category
          : //  this.props.id_category,
            0,
      ingredentOptions: [],

      classGroups: [],
      groups: [],
      bntAdd: false,
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
      customer: this.props.data || {},

      bankList: this.props.data.bankList,

      representativeList: this.props.data.representativeList,
      listCategory: [],
      showAdd: false,
      err: {},
      checkVal: false,
    };
  }

  async componentDidMount() {

    this.getCategoryCustomer();

    this.getClassGroupsData();
    this.getIngredentsData();
    let arr = this.state.bankList;
    arr.push({
      bankName: "",
      bankNumber: "",
      cardOwner: "",
      address: "",
      note: "",
    });
    this.setState({ bankList: arr });
    let arr1 = this.state.representativeList;
    arr1.push({
      name: "",
      role: "",
      phoneNumber: "",
      email: "",
    });
    this.setState({ representativeList: arr1 });
  }
  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };
  modalSubmit = () => {
    this.setState({ showAdd: false });
    this.refreshRole();
    this.getCategoryCustomer();
  };

  // Fetch Data
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.customer };
    dataInput[field] = value;
    this.setState({ customer: dataInput });
    // let Err=
    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };
  handleInputBank = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.bankList };
    dataInput[field] = value;
    this.setState({ bankList: dataInput });
  };

  createCustomer = () => {
    var customer = this.state.customer;
    let bank = this.state.bankList.filter(
      (e) =>
        e.bankName != "" ||
        e.bankNumber != "" ||
        e.cardOwner != "" ||
        e.address != "" ||
        e.note != ""
    );

    let data = {
      shortName: customer.shortName, // require
      name: customer.name, // require
      address: customer.address,
      phoneNumber: customer.phoneNumber,
      customerTypeId: Number(customer.customerTypeId),
      faxNumber: customer.faxNumber,
      taxCode: customer.taxCode,
      bankList: this.state.bankList.filter(
        (e) =>
          e.bankName != "" ||
          e.bankNumber != "" ||
          e.cardOwner != "" ||
          e.address != "" ||
          e.note != ""
      ),
      // this.state.bankList
      representativeList: this.state.representativeList.filter(
        (e) =>
          e.name != "" || e.role != "" || e.phoneNumber != "" || e.email != ""
      ),
    };
    axios
      .post(`/customer`, data)

      // axios.post(`/createRole?role_name=${data.role_name}&permission=${data.permission.toString()}`)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          return this.props.submit();
        } else {
          this.setState({ err: res.data.errorMessage });
          // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };
  updateCustomer = () => {
    this.setState({ checkVal: true });
    var customer = this.state.customer;
    let bank = this.state.bankList.filter(
      (e) =>
        e.bankName != "" ||
        e.bankNumber != "" ||
        e.cardOwner != "" ||
        e.address != "" ||
        e.note != ""
    );

    let data = {
      shortName: customer.shortName, // require
      name: customer.name, // require
      address: customer.address,
      phoneNumber: customer.phoneNumber,
      customerTypeId: Number(customer.customerTypeId),
      faxNumber: customer.faxNumber,
      taxCode: customer.taxCode,
      bankList: this.state.bankList.filter(
        (e) =>
          e.bankName != "" ||
          e.bankNumber != "" ||
          e.cardOwner != "" ||
          e.address != "" ||
          e.note != ""
      ),
      representativeList: this.state.representativeList.filter(
        (e) =>
          e.name != "" || e.role != "" || e.phoneNumber != "" || e.email != ""
      ),
    };
    if (data.representativeList?.filter(e=>e.phoneNumber?.length>20)?.length<1)
    axios
      .put(`/customer/${this.props.data.id}/update`, data)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          return this.props.submit();
        } else {
          this.setState({ err: res.data.errorMessage });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };
  getCategoryCustomer = () => {
    axios.get(`/customer-type`).then((res) => {
      if (res.data.status == 200) {
        this.setState({
          listCategory: res.data.data,
        });
      } else {
        this.setState({
          listCategory: [],
        });
      }
    });
  };
  getClassGroupsData = () => {
    // axios.get(`/viewClassGroup`).then((res) => {
    //   const ingredients = [];
    //   ingredients.push({
    //     id_ingredent: 0,
    //     ingredent_name: "",
    //     parent_unit: 0,
    //     unit_id: 0,
    //     unit_name: "",
    //     // group: groups,
    //   });
    //   this.setState({
    //     // classGroups: res.data.data,
    //     ingredientFoodDetail:
    //       //  this.props.ingredientFoodDetail ? this.props.ingredientFoodDetail :
    //       ingredients,
    //     // groups: groups,
    //   });
    //   if (res.data.status === true) {
    //     const ingredients = [];
    //     let groups = this.props.ingredientFoodDetail
    //       ? this.props.ingredientFoodDetail[0].group
    //         .map((item) => {
    //           return {
    //             id: Number(item.id),
    //             amount: 0,
    //           }
    //         })
    //       : res.data.data
    //         .filter((item) => Number(item.is_main) === 1)
    //         .map((item) => {
    //           return {
    //             id: Number(item.id),
    //             amount: 0,
    //           };
    //         });
    //     ingredients.push({
    //       id_ingredent: 0,
    //       ingredent_name: "",
    //       parent_unit: 0,
    //       unit_id: 0,
    //       unit_name: "",
    //       group: groups,
    //     });
    //     this.setState({
    //       classGroups: res.data.data,
    //       ingredientFoodDetail:
    //         ingredients,
    //       groups: groups,
    //     });
    //   }
    // });
  };

  getIngredentsData = () => {};

  // Logic
  removeSelect = (ingredentId, cardOwner) => {
    swal({
      title: "Bạn muốn xóa ngân hàng?",
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        toast("Đã xóa ngân hàng.", {
          type: "success",
          autoClose: 1000,
        });
        var arr = this.state.bankList.filter((item) =>
          ingredentId
            ? item.bankNumber !== ingredentId && item.cardOwner != cardOwner
            : item.cardOwner != cardOwner
        );
        arr.push({
          bankName: "",
          bankNumber: "",
          cardOwner: "",
          address: "",
          note: "",
        });

        this.setState({
          bankList: Array.from(new Set(arr?.map(JSON.stringify)), JSON.parse),
        });

        //
      }
    });
  };

  removeSelectRepre = (name, role) => {
    swal({
      title: "Bạn muốn xóa người đại diện",
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        var arr = this.state.representativeList.filter((item) =>
          name ? item.name !== name && item.role != role : item.role != role
        );
        arr.push({
          name: "",
          phoneNumber: "",
          email: "",
          role: "",
        });

        this.setState({
          representativeList: Array.from(
            new Set(arr?.map(JSON.stringify)),
            JSON.parse
          ),
        });
        toast("Đã xóa người địa diện.", {
          type: "success",
          autoClose: 1000,
        });
      }
    });
  };
  // Render
  RenderRepresentativeList() {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr>
              <th rowSpan={2} className="align-middle text-center">
                STT
              </th>
              <th
                rowSpan={2}
                className="align-middle text-center"
                style={{ width: "220px" }}
              >
                Tên người đại diện{" "}
                <i className="text-danger">
                  {this.state.representativeList?.filter(
                    (e) => e.role != "" || e.phoneNumber != ""
                  ).length > 0
                    ? "*"
                    : ""}
                </i>
              </th>
              <th className="text-center">
                Chức vụ<i className="text-danger"></i>
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Điện thoại
              </th>{" "}
              <th rowSpan={2} className="align-middle text-center">
                Email
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Phương thức
              </th>
            </tr>
          </thead>
          <tbody>{this.representativeList()}</tbody>
        </Table>
      </div>
    );
  }
  representativeList() {
    return (
      <>
        {/* ingredientFoodDetail */}
        {}
        {this.state.representativeList?.map((bank, index) => {
          return (
            <tr key={index}>
              <td className="align-middle text-center">{index + 1}</td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="cardOwner"
                    value={bank.name}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.name = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;
                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map(
                        (item, index) => {
                            if (
                                item.name == bank.name &&
                                item.role == bank.role
                                &&item.phoneNumber==bank.phoneNumber
                                &&item.email==bank.email
                              ){
                            return {
                              // id:index+1,
                              ...item,
                              name: e.target.value,
                            };
                          }
                          return item;
                        }
                      );
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Tên người đại diện"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.role == "" && bank.name == ""
                      ? null
                      : bank.name.trim().length > 0 &&
                        bank.name.trim().length < 51
                      ? null
                      : "Tên người đại diện không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="role"
                    value={bank.role}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.role = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map(
                        (item, index) => {
                            if (
                                item.name == bank.name &&
                                item.role == bank.role
                                &&item.phoneNumber==bank.phoneNumber
                                &&item.email==bank.email
                              ) {
                            return {
                              ...item,
                              role: e.target.value,
                            };
                          }
                          return item;
                        }
                      );
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Chức vụ"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.role == "" && bank.name == ""
                      ? null
                      : bank?.role?.trim()?.length >= 0 &&
                        bank?.role?.trim()?.length < 101
                      ? null
                      : "Chức vụ không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>

              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    value={bank.phoneNumber}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.phoneNumber = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map(
                        (item, index) => {
                            if (
                                item.name == bank.name &&
                                item.role == bank.role
                                &&item.phoneNumber==bank.phoneNumber
                                &&item.email==bank.email
                              ) {
                            return {
                              ...item,
                              phoneNumber: e.target.value,
                            };
                          }
                          return item;
                        }
                      );
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Điện thoại"
                    required
                  />{" "}
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.phoneNumber == ""
                      ? null
                      :
                      //  !isNaN(bank.phoneNumber?.replace(/\s/g, "")) 
                      //  &&
                        bank.phoneNumber?.length > 9 &&
                        bank.phoneNumber?.length < 20
                      ? null
                      : "Số điện thoại không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="email"
                    value={bank.email}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.email = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map(
                        (item, index) => {
                            if (
                                item.name == bank.name &&
                                item.role == bank.role
                                &&item.phoneNumber==bank.phoneNumber
                                &&item.email==bank.email
                              ) {
                            return {
                              ...item,
                              email: e.target.value,
                            };
                          }
                          return item;
                        }
                      );
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Email"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.email == ""
                      ? null
                      : /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
                          bank.email
                        ) && bank.email?.length < 101
                      ? null
                      : "Email không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>

              <td className="align-middle text-center">
                {Number(
                  bank.name == 0 &&
                    bank.role == 0 &&
                    bank.phoneNumber == 0 &&
                    bank.email == 0
                ) ? (
                  ""
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    type="button"
                    title="Xóa người đại diện"
                    onClick={() => {
                      this.removeSelectRepre(
                        bank.name,
                        bank.role
                        // ingredient.id_ingredent
                      );
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  }
  RenderIngredientSelect() {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr>
              <th rowSpan={2} className="align-middle text-center">
                STT
              </th>
              <th
                rowSpan={2}
                className="align-middle text-center"
                style={{ width: "220px" }}
              >
                Tên tài khoản <i className="text-danger"></i>
              </th>
              <th className="text-center">
                Số tài khoản
                <i className="text-danger">
                  {this.state.bankList?.filter(
                    (e) => e.bankNumber != "" || e.bankName != ""
                  ).length > 0
                    ? "*"
                    : ""}
                </i>
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Tại ngân hàng
                <span className="text-danger">
                  {this.state.bankList?.filter(
                    (e) => e.bankNumber != "" || e.bankName != ""
                  ).length > 0
                    ? "*"
                    : ""}
                </span>
              </th>{" "}
              <th rowSpan={2} className="align-middle text-center">
                Địa chỉ
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Ghi chú
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Phương thức
              </th>
            </tr>
          </thead>
          <tbody>{this.RenderIngredientFoodDetail()}</tbody>
        </Table>
      </div>
    );
  }

  RenderIngredientFoodDetail() {
    return (
      <>
        {/* ingredientFoodDetail */}
        {}
        {this.state.bankList?.map((bank, index) => {
          return (
            <tr key={index}>
              <td className="align-middle text-center">{index + 1}</td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="cardOwner"
                    value={bank.cardOwner}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.cardOwner = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;
                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            cardOwner: e.target.value,
                            bankNumber: item.bankNumber,
                            bankName: item.bankName,
                            address: item.address,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Tên tài khoản"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.bankNumber == "" &&
                    bank.bankName == "" &&
                    bank.cardOwner == ""
                      ? null
                      : bank.cardOwner.trim().length < 100
                      ? null
                      : "Tên tài khoản không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="cardOwner"
                    value={bank.bankNumber}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.bankNumber = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;
                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            cardOwner: item.cardOwner,
                            bankNumber: e.target.value,
                            bankName: item.bankName,
                            address: item.address,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Tên tài khoản"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.bankNumber == "" &&
                    bank.bankName == "" &&
                    bank.cardOwner == ""
                      ? null
                      : bank.bankNumber?.replace(/\s/g, "")?.trim().length >
                          0 &&
                        // !isNaN(bank.bankNumber?.replace(/\s/g, "")) &&
                        bank.bankNumber?.replace(/\s/g, "")?.trim().length < 51
                      ? null
                      : "Số tài khoản không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>

              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="bankName"
                    value={bank.bankName}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.bankName = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            bankName: e.target.value,
                            bankNumber: item.bankNumber,
                            cardOwner: item.cardOwner,
                            address: item.address,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Tên ngân hàng"
                    required
                  />{" "}
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.bankNumber == "" &&
                    bank.bankName == "" &&
                    bank.cardOwner == ""
                      ? null
                      : bank?.bankName?.trim()?.length > 0 &&
                        bank?.bankName?.trim()?.length < 100
                      ? null
                      : "Tên ngân hàng không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="bankName"
                    value={bank.address}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.address = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            bankName: item.bankName,
                            bankNumber: item.bankNumber,
                            cardOwner: item.cardOwner,
                            address: e.target.value,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Địa chỉ"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {bank.bankNumber == "" &&
                    bank.bankName == "" &&
                    bank.cardOwner == ""
                      ? null
                      : bank?.address == null ||
                        bank?.address?.trim()?.length < 255
                      ? null
                      : "Địa chỉ không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="note"
                    value={bank.note}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.note = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            bankName: item.bankName,
                            bankNumber: item.bankNumber,
                            cardOwner: item.cardOwner,
                            address: item.address,
                            note: e.target.value,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(
                          new Set(newBank?.map(JSON.stringify)),
                          JSON.parse
                        ),
                      });
                    }}
                    placeholder="Noteỉ"
                    required
                  />
                  <span
                    className={this.state.checkVal ? "text-form-err" : "d-none"}
                  >
                    {
                      // (bank.note=='') ? null :
                      bank.note == null ||
                      (bank?.note?.length >= 0 &&
                        bank?.note?.trim()?.length < 255)
                        ? null
                        : "Ghi chú không hợp lệ"
                    }
                  </span>
                </Form.Floating>
              </td>

              <td className="align-middle text-center">
                {Number(
                  bank.bankNumber == 0 &&
                    bank.cardOwner == 0 &&
                    bank.bankName == 0 &&
                    bank.address == 0 &&
                    bank.note == 0
                ) ? (
                  ""
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    type="button"
                    title="Xóa ngân hàng"
                    onClick={() => {
                      this.removeSelect(
                        bank.bankNumber,
                        bank.cardOwner
                        // ingredient.id_ingredent
                      );
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  }

  formclose = () => {
    this.setState({ show: false });
    // this.getlistgroup();
    this.refreshRole();
  };
  formcloseAdd = () => {
    this.refreshRole();
    this.setState({ showAdd: false });
    this.getCategoryCustomer();

    // this.getlistgroup();
  };
  renderGroupAdd = () => {
    return (
      // <CategoryCustomerCreate
      //   PT={this.state.type}
      //   show={this.state.show}
      //   close={this.formclose}
      // />
      this.state.showAdd ? (
        <CategoryCustomerCreate
          show={this.state.showAdd}
          onHide={this.formcloseAdd}
          modal={
            this.state.role?.id === ""
              ? {
                  title: "Thêm loại khách hàng",
                  button: (
                    <>
                      <i className="fa-solid fa-check" /> Lưu
                    </>
                  ),
                }
              : {
                  title: "Thêm loại khách hàng",
                  button: (
                    <>
                      <i className="fa-solid fa-check" /> Lưu
                    </>
                  ),
                }
          }
          datarole={this.state.role}
          submit={this.modalSubmit}
        />
      ) : null
    );
  };

  render() {
    const { onHide, ...other } = { ...this.props };

    const closeModal = () => {
      const food = {
        food_name: "",
        id_unit: 0,
        id_category: 0,
      };

      this.setState({
        foodDetail: food,
        ingredientFoodDetail: [],
      });

      onHide();
    };

    return (
      <>
        {/* <Modal
                  show={other.show}
                  onHide={onHide} */}
        <Modal
          show={this.props.show}
          onHide={this.props.close}
          size="xl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Header> */}
            <Modal.Title>
              <i className="fa-solid fa-user"></i> Chi tiết khách hàng
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="shortName"
                    defaultValue={this.state.customer.shortName}
                    onChange={(e) => {
                      this.handleInput(e);
                    }}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Tên ngắn<i className="text-danger">*</i>
                  </Form.Label>

                  <span className="text-form-err">
                    {this.state.err.shortName}
                  </span>
                  <div className="invalid-feedback">=</div>
                </Form.Floating>
              </Col>

              <Col md={8}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="name"
                    defaultValue={this.state.customer.name}
                    onChange={(e) => {
                      this.handleInput(e);
                    }}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Tên đầy đủ<i className="text-danger">*</i>
                  </Form.Label>{" "}
                  <span className="text-form-err">{this.state.err.name}</span>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="address"
                    defaultValue={this.state.customer.address}
                    onChange={(e) => {
                      this.handleInput(e);
                    }}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Địa chỉ<i className="text-danger"></i>
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="taxCode"
                    defaultValue={this.state.customer.taxCode}
                    onChange={(e) => {
                      this.handleInput(e);
                    }}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Mã số thuế<i className="text-danger"></i>
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="phoneNumber"
                    defaultValue={this.state.customer.phoneNumber}
                    onChange={(e) => {
                      this.handleInput(e);
                    }}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Điện thoại<i className="text-danger"></i>
                  </Form.Label>{" "}
                  <span className="text-form-err">
                    {this.state.err.phoneNumber}
                  </span>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="floatingInputClassName"
                    placeholder="Tên khách hàng"
                    name="faxNumber"
                    defaultValue={this.state.customer.faxNumber}
                    onChange={(e) => {
                      this.handleInput(e);
                    }}
                  />
                  <Form.Label htmlFor="floatingInputClassName">
                    Fax<i className="text-danger"></i>
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                </Form.Floating>
                <span className="text-form-err">
                  {this.state.err.faxNumber}
                </span>
              </Col>
            </Row>
            {new Check().permission(["35"]) ? 
            <Form.Group className="text-end">
              <Link
                className="fst-italic"
                onClick={() => {
                  // this.renderGroupAdd()
                  this.setState({
                    showAdd: true,
                    type: "TM",
                  });
                }}
              >
                +Thêm loại khách hàng
              </Link>
            </Form.Group>
           :null}

            <Form.Floating className="mb-3">
              <Form.Select
                name="group_id"
                id="selectGroupId"
                placeholder="Loại khách hàng"
                value={this.state.customer.customerTypeId}
                onChange={(e) => {
                  this.getCategoryCustomer();
                  let data = this.state.customer;
                  data.customerTypeId = e.target.value;
                  this.setState({ customer: data });
                  // this.props._class.id_group = e.target.value;
                }}
              >
                <option value={0}>--Chọn--</option>
                {this.state.listCategory.map((group, i) => {
                  return (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Label htmlFor="selectGroupId">
                Loại khách hàng<i className="text-danger"></i>
              </Form.Label>
            </Form.Floating>
            {this.RenderIngredientSelect()}
            {this.RenderRepresentativeList()}
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                this.props.close();
                this.setState({
                  bankList: [
                    {
                      bankName: "",
                      bankNumber: "",
                      cardOwner: "",
                      address: "",
                      note: "",
                    },
                  ],
                });
              }}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button
              size="sm"
              type="submit"
              variant="success"
              onClick={() => {
                if (this.props.PT === "TM" || this.props.PT === "") {
                  this.createCustomer();
                } else {
                  this.updateCustomer();
                }
              }}
            >
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Modal>
        {this.renderGroupAdd()}
      </>
    );
  }
}

export default CustomerEditModal;
