import React from "react";
import { useReactToPrint } from "react-to-print";
import { Row, Col, Button } from "react-bootstrap";
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataExport: this.props.data,
      date: this.props.date,
      countDuration: this.props.countDuration,
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        dataExport: this.props.data,
        date: this.props.date,
        countDuration: this.props.countDuration,
      });
    }
  }
  getSumDuration = (data) => {
    let tong = 0;
    data?.map((item) => (tong += item?.adBlock?.tapeCode?.duration || 0));
    return tong;
  };
  getDayfromDateForExcel(data) {
    if (data > 0) {
      var daysOfWeek = ["Chủ nhật, ", "Thứ hai, ", "Thứ ba, ", "Thứ tư, ", "Thứ năm, ", "Thứ sáu, ", "Thứ bảy, "];

      var rs = daysOfWeek[new Date(data * 1000)?.getDay()];
      return rs;
    } else return data;
  }
  getDate = (data) => {
    if (data > 0) {
      const date = new Date(data * 1000);
      return (
        ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()
      );
    } else return data;
  };
  render() {
    return (
      <div
        id="exportPDFSchedulee"
        style={{
          fontFamily: "Times New Roman, Times, serif",
          color: "#000",
          // ,margin:' 20px 55px '
        }}
        className="hidden-print"
      >
        <div>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            LỊCH {this.props.element == "notification" ? "THÔNG BÁO" : "QUẢNG CÁO"} HÀNG NGÀY KÊNH CTV
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            {this.getDayfromDateForExcel(this.state.date) + " " + this.getDate(this.state.date)}
          </div>
          <div style={{ height: "20px" }}></div>
          <div
            style={{
              //  border: '2px solid',
              // borderTop:'1px solid',

              display: "contents",
            }}
            className="tableExport"
          >
            {/* <thead> */}
            <tr
              style={{
                borderBottom: "1px solid #000",
                borderBottomWidth: "1px !important",
                height: "40px",
              }}
            >
              <th className="text-center align-middle bordertd" style={{ width: "67px" }}>
                Mã giờ
              </th>
              <th className="text-center  align-middle" style={{ width: "77px" }}>
                Giờ phát
              </th>
              <th className="text-center  align-middle" style={{ width: "43px" }}>
                Vị trí
              </th>
              <th className="text-center  align-middle" style={{ width: "295px" }}>
                Nội dung
              </th>
              {this.props.element == "notification" ? null : (
                <>
                  <th className="text-center  align-middle">Thời lượng</th>
                  <th className="text-center  align-middle" style={{ width: "105px" }}>
                    Mã băng
                  </th>
                </>
              )}

              <th
                className="text-center  align-middle"
                style={{
                  width: this.props.element == "notification" ? "197px" : "69px",
                }}
              >
                Ghi chú
              </th>
            </tr>
            {/* </thead> */}
            {/* <tbody> */}
            {this.state.dataExport?.map((item) => {
              return (
                <>
                  <tr className="fw-bold align-middle">
                    <td className="bordertd"> {item?.adPrice?.timeCode}</td>
                    <td>{item?.adPrice?.timeStart}</td>
                    <td>{}</td>
                    <td className="text-center"> {item?.adPrice?.name}</td>
                    <td>{}</td>
                    {this.props.element == "notification" ? null : (
                      <>
                        <td></td> <td></td>
                      </>
                    )}
                  </tr>
                  {item.broadcastList?.map((broadcastItem) => {
                    return (
                      <tr className="align-middle">
                        <td className="bordertd align-middle">{}</td> <td>{}</td>
                        <td className="text-center align-middle">{broadcastItem.position}</td>
                        <td className="align-middle">
                          {this.props.element == "notification" && item?.adPrice?.priceType != 1
                            ? broadcastItem?.adBlock?.tapeCode?.name
                            : this.props.element == "notification" && item?.adPrice?.priceType == 1
                            ? broadcastItem?.adBlock?.content
                            : broadcastItem?.adBlock?.tapeCode?.name}
                        </td>
                        {this.props.element == "notification" ? null : (
                          <>
                            <td className="text-center align-middle">{broadcastItem?.adBlock?.tapeCode?.duration}</td>
                            <td className="text-wrap align-middle">{broadcastItem?.adBlock?.tapeCode?.name}</td>
                          </>
                        )}
                        <td>{broadcastItem?.note ?? broadcastItem.adBlock?.note}</td>
                      </tr>
                    );
                  })}
                  {this.props.element == "notification" ? null : (
                    <>
                      <tr className="fw-bold">
                        <td colSpan={4} className="text-end bordertd">
                          Tổng thời lượng đoạn:
                        </td>
                        <td className="text-center">{this.getSumDuration(item?.broadcastList)}</td>
                        <td></td> <td></td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
            {this.props.element == "notification" ? null : (
              <tr>
                <td colSpan={4} className="text-end  fw-bold bordertl ">
                  Tổng thời lượng:
                </td>
                <td className="text-center borderth  fw-bold">{this.state.countDuration}</td>
                <td className="text-center borderth"></td>
                <td className="text-center borderth"></td>
              </tr>
            )}
            {/* </tbody> */}
          </div>
          <div>
            {this.props.element == "notification" ? (
              <>
                <div></div>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    marginTop: "15px",
                    border: "none !important",
                  }}
                >
                  <Col className="text-center">BIÊN TẬP CT TB-QC</Col>
                  <Col className="text-center">TP.KH-TC & DV</Col>
                  <Col className="text-center">KT.THỦ TRƯỞNG ĐƠN VỊ</Col>
                </Row>
                <div style={{ height: "80px" }}>
                  <div></div>
                </div>{" "}
                <div></div> <div></div> <div></div>{" "}
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Col></Col>
                  <Col className="text-center">Nguyễn Hoàng Sấy</Col>
                  <Col className="text-center">Hồ Tấn Lộc</Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    marginTop: "15px",
                  }}
                >
                  <Col className="text-center">BIÊN TẬP CT TB-QC</Col>
                  <Col className="text-center">TP.KH-TC & DV</Col>
                  <Col className="text-center">KT.THỦ TRƯỞNG ĐƠN VỊ</Col>
                </Row>
                <div style={{ height: "80px" }}></div>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Col></Col>
                  <Col className="text-center">Nguyễn Hoàng Sấy</Col>
                  <Col className="text-center">Hồ Tấn Lộc</Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const PrintButton = ({ data, page, date, countDuration }) => {
  const componentRef = React.useRef();
  const getDate = (data) => {
    if (data > 0) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()
      );
    } else return data;
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Lich  ${(page == "notification" ? "TB " : "QC ") + getDate(date)}`,
    // pageStyle: `{ size: 2.5in 4in;

    // }`
  });

  return (
    <div>
      <ComponentToPrint ref={componentRef} data={data} element={page} date={date} countDuration={countDuration} />
      {/* <button onClick={
        ()=> {
         
          const element = document.getElementById("exportPDFSchedulee");
          var clonedElement = element.cloneNode(true);

          clonedElement.classList.remove("d-none");
          handlePrint()
        }
        }>Print</button> */}

      <Button
        variant="danger"
        size="sm"
        style={{ float: "right", whiteSpace: "nowrap" }}
        className="m-1"
        // className="btn-setting"
        disabled={data?.length < 1}
        // title={this.props.element == "notification" ? "Xuất PDF" : "Xuất PDF"}
        onClick={() => {
          const element = document.getElementById("exportPDFSchedulee");
          var clonedElement = element.cloneNode(true);

          clonedElement.classList.remove("d-none");
          handlePrint();
        }}
      >
        <i className="fa-solid fa-file-export"></i> Xuất PDF
      </Button>
    </div>
  );
};

export default PrintButton;
