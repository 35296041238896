import React, { Component } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";
import Select from "react-select";
import { Link } from "react-router-dom";
import SupplierFormModal from "../Supplier/SupplierFormModal";
import PriceModal from "../price/PriceModal";
class ModalRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
      dataInput: { ...this.props.datarole },
      isCheckGroup: [],
      listsupplier: [],
      err: {},
      selectSupplier: {
        value: 0,
        label: "Chọn bảng giá",
      },
      modalSupplier: false,
      role: {
        id: "",
        role_name: "",
        dayEnd: "",
      },
      err: {},
      modalprice: false,
    };
  }

  componentDidMount() {
    this.getlistcustomer();
  }
  getlistcustomer = () => {
    var arr = [];
    arr.push({
      value: 0,
      label: "Chọn bảng giá",
    });
    axios
      .get(
        `/ad-price/search?dayApply=${parseInt(
          this.props.startMonth / 1000
        )}&dayEnd=${this.props.endMonth / 1000}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ checkload: false });
          if (this.props.isSocial) {
            res.data.data.social?.map((e) => {
              arr.push({
                value: e.id,
                label: e.timeCode + " - " + e.name,
              });
            });
          } else if (
            window.location.pathname == "/admin/contract-notification" ||
            window.location.pathname == "/admin/schedule-notification-daily"
          ) {
            res.data.data.notification?.map((e) => {
              arr.push({
                value: e.id,
                label: e.timeCode + " - " + e.name,
              });
            });
          } else {
            res.data.data.ad?.map((e) => {
              arr.push({
                value: e.id,
                label: e.timeCode + " - " + e.name,
              });
            });
          }

          this.setState({
            listsupplier: arr,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
  };

  createRole = () => {
    let data = {
      registrationScheduleId: this.props.id_Regis,
      adPriceId: this.state.selectSupplier.value,
      note: this.state.dataInput.role_name,
      content: "", //khoon
      amount: 0, //
      realDuration: 0, //
      dates: [],
      month: parseInt(this.props.startMonth / 1000),
    };
    axios
      .post(`/block`, data)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
          return this.props.submit();
        } else {
          toast(`${res.data.errorMessage.adPriceId}`, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };

  updateRole = () => {
    let data = {
      supplierId: this.state.selectSupplier.value,
      name: this.state.dataInput.role_name,
    };
    swal({
      title: "Bạn muốn cập nhật sản phẩm?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/product/${this.state.dataInput.id}/update`, data)
          .then((res) => {
            if (res.data.status == 200) {
              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });

              return this.props.submit();
            } else {
              toast(`${res.data.errorMessage.name}`, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
          });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.dataInput.id === "") {
      this.createRole();
    } else {
      this.updateRole();
    }
  };

  handleHide = () => {
    return this.props.onHide();
  };

  customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "48px",
      color: state.isFocused ? "yellow" : "red",
      boxShadow: state.isFocused ? "0 0 0 4px rgba(199, 246, 255, 1)" : null,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "20px",
    }),
  };
  render() {
    const { onHide, ...other } = { ...this.props };
    const modalClose = () => {
      this.setState({ modalSupplier: false, modalprice: false });
    };
    const modalSubmit = () => {
      this.setState({ modalSupplier: false, modalprice: false });
      this.getlistcustomer();
    };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> {other.modal.title}</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Form.Group className="text-end">
                <Link
                  className="fst-italic"
                  onClick={() => {
                    // this.renderGroupAdd()
                    this.setState({
                      modalprice: true,
                      type: "TM",
                    });
                  }}
                >
                  +Thêm bảng giá
                </Link>
              </Form.Group>
              <Form.Floating className="mb-3">
                <Select
                  styles={this.customStyles}
                  // defaultValue={options[0]}
                  options={this.state.listsupplier}
                  placeholder=""
                  value={this.state.selectSupplier}
                  onChange={(choice) => {
                    this.setState({ selectSupplier: choice });
                  }}
                />
                <div className="placeholder-select">
                  Chọn bảng giá<i className="text-danger">*</i>
                </div>
              </Form.Floating>
              <Form.Floating className="mb-1">
                <Form.Control
                  type="text"
                  name="role_name"
                  defaultValue={this.state.dataInput?.role_name || ""}
                  placeholder="Tên sản phẩm"
                  onChange={this.handleInput}
                />
                <Form.Label>
                  Ghi chú<i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button
                size="sm"
                variant="success"
                type="submit"
                // disabled = {!new Check().permission(['63','64'])}
              >
                {other.modal.button}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {this.state.modalSupplier ? (
          <>
            <SupplierFormModal
              show={this.state.modalSupplier}
              onHide={modalClose}
              modal={{
                title: "Thêm bảng giá",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
        {this.state.modalprice ? (
          <>
            <PriceModal
              show={this.state.modalprice}
              onHide={modalClose}
              modal={
                this.state.role.id === ""
                  ? {
                      title: "Thêm khung giờ phát sóng",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết khung giờ phát sóng",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }
}

export default ModalRegister;
