import React, { Component } from "react";
import axios from "axios";
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button, Form, Modal, Table, Row, Col, NavDropdown } from "react-bootstrap";
import Check from "../../components/admin/other/Check";
// import FormatData from "../../components/admin/FormatData";
import Logo from "../../assets/admin/assets/img/CTV_logo.png";
import moment from "moment";

// import Logo from '../../../public/logo.png'
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      user: {},
      listEthnic: [],
      listposition: [],
      show: false,
      listrole: [
        {
          id: localStorage.getItem("role_id"),
          role_name: localStorage.getItem("role_name"),
        },
      ],
      showAlarm: false,
      ingredent_alarmlist: [],
      count: 0,
      menu: [],
      err: {},
    };
  }

  async componentDidMount() {
    this.getlistrole();
    this.getlistPosition();
    this.getUser();
    this.setState({ menu: JSON.parse(localStorage.getItem("menu")) });
  }

  handleClose = () => {
    this.setState({ show: false, showAlarm: false, err: {} });
    this.getUser();
  };
  getlistPosition = () => {
    var arr = [];
    axios
      .get("/position")
      .then((res) => {
        if (res.data.status == 200) {
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({ listposition: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getUser = () => {
    // this.setState({ user: localStorage.getItem('detail_user_ctv') });

    axios
      .get(`/user/${localStorage.getItem("user_id_ctv")}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ user: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sidenavToggle = (event) => {
    document.getElementById("sb-nav-fixed").classList.toggle("sb-sidenav-toggled");
  };

  dateNotTime(data) {
    let date = new Date(data * 1000);
    return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
  }

  logout = () => {
    swal({
      title: "Bạn muốn đăng xuất?",
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        // axios.get("/logout").then((res) => {
        //   if (res.data.status) {
        localStorage.removeItem("auth_id_shool");
        localStorage.removeItem("auth_id_user");
        localStorage.removeItem("auth_name_ctv");
        localStorage.removeItem("role_rank");
        localStorage.removeItem("role_id_ctv");
        localStorage.removeItem("role_name");
        localStorage.removeItem("user_id_ctv");

        localStorage.removeItem("permission_ctv");
        localStorage.removeItem("token_type");
        localStorage.removeItem("access_token_ctv");
        localStorage.removeItem("auth_phone");
        localStorage.removeItem("auth_account");
        localStorage.removeItem("vip");
        this.props.navHook("/login");

        toast("Đăng xuất thành công", { type: "success" });
      }
      // }).catch((error) => {
      //   toast('Đăng xuất thất bại!', { type: "success" });
      // });
      // }
    });
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  updateuser = () => {
    let data_ = this.state.user;
    let data = {
      fullName: data_?.fullName || "",
      phoneNumber: data_?.phoneNumber || "", //not require
      email: data_?.email || "", //not require
      gender: Number(data_?.gender) || 0, //not require
      dateOfBirth: data_?.dateOfBirth > 0 ? data_?.dateOfBirth : new Date(data_.dateOfBirth) / 1000, //not require
      newPassword: data_?.password?.password || "", // not require
      oldPassword: data_?.password_old?.password_old || "", // not require
      image: "12121212112121base64", //not require
    };
    swal({
      title: "Bạn muốn cập nhật tài khoản?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/user/update-by-user`, data)
          .then((res) => {
            if (res.data.status == 200) {
              this.handleClose();
              localStorage.setItem("auth_name_ctv", data.fullName);
              // {localStorage.getItem("auth_name_ctv")}
              this.setState({ id_user: "" });
              toast(`${res.data?.message}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              this.setState({ err: res.data.errorMessage });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  getlistrole = () => {
    var arr = [];
    axios
      .get("/role")
      .then((res) => {
        if (res.data.status == 200) {
          res.data.data?.map((item) => {
            arr.push({ value: item?.id, label: item?.name });
          });
          this.setState({ listrole: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleShowAlarm = () => {
    if (this.state.count > 0) this.setState({ showAlarm: true });
  };

  handleUpdate = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    if (event.target.name === "acc") {
      value = {
        userName: event.target.value,
      };
    }
    if (event.target.name === "password") {
      value = {
        password: event.target.value,
      };
    }
    if (event.target.name === "password_old") {
      value = {
        password_old: event.target.value,
      };
    }

    let user = { ...this.state.user };
    user[field] = value;
    this.setState({ user: user });
  };

  updateToken = () => {
    axios.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("access_token_ctv");
  };

  render() {
    // if (!localStorage.getItem('access_token_ctv')) {
    //   window.location.href = "/login";
    // } else {
    //   this.updateToken();
    // }

    return (
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-light">
        <Modal
          show={this.state.show}
          size="md"
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-chalkboard-user"></i>&nbsp; Thông tin
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="fullName"
                    onChange={(e) => {
                      this.handleUpdate(e);
                      delete this.state.err?.fullName;
                    }}
                    defaultValue={this.state.user?.fullName}
                    placeholder="Họ và tên"
                    required
                  />
                  <Form.Label>
                    Họ và tên<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-form-err">{this.state.err?.fullName}</span>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    className="form-control"
                    defaultValue={this.state.user?.phoneNumber}
                    name="phoneNumber"
                    onChange={(e) => {
                      this.handleUpdate(e);
                      delete this.state.err?.phoneNumber;
                    }}
                    placeholder="Số điện thoại"
                  />
                  <Form.Label>Số điện thoại</Form.Label>
                  <span className="text-form-err">{this.state.err?.phoneNumber}</span>
                </Form.Floating>
              </Col>
              <Col md={3}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    defaultValue={this.state.user?.gender}
                    name="gender"
                    onChange={(e) => {
                      this.handleUpdate(e);
                      // delete (err.fullName)
                    }}
                  >
                    <option value="0">--Chọn--</option>
                    <option value="2">Nam</option>
                    <option value="1">Nữ</option>
                    <option value="3">Khác</option>
                  </Form.Select>
                  <Form.Label>
                    Giới tính<i className="text-danger"></i>
                  </Form.Label>
                  {/* <span className="text-form-err">
                    {err.gender}
                  </span> */}
                </Form.Floating>
              </Col>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <input
                    type="date"
                    className="form-control"
                    name="dateOfBirth"
                    onChange={(e) => {
                      this.handleUpdate(e);

                      delete this.state.err?.dateOfBirth;
                    }}
                    defaultValue={moment(new Date(this.state.user?.dateOfBirth * 1000)).format("YYYY-MM-DD")}
                    // onChange={(e) => {
                    //   setbirthday
                    //     (new Date(e.target.value)
                    //       // .getTime() / 1000
                    //     )
                    // }}
                  />
                  <Form.Label>
                    Ngày sinh<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{this.state.err?.dateOfBirth}</span>
                </Form.Floating>
              </Col>
              <Col md={5}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={(e) => {
                      this.handleUpdate(e);
                      delete this.state.err?.email;
                    }}
                    autoComplete="off"
                    defaultValue={this.state.user?.email == "" ? "" : this.state.user?.email}
                    placeholder=""
                  />
                  <Form.Label>Email</Form.Label>
                  <span className="text-form-err">{this.state.err?.email}</span>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={(e) => {
                      this.handleUpdate(e);
                      delete this.state.err?.newPassword;
                    }}
                    placeholder="Mật khẩu"
                    autoComplete="off"
                  />
                  <Form.Label>
                    Mật khẩu mới<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{this.state.err?.newPassword}</span>
                </Form.Floating>
              </Col>

              <Col md={6} className={this.state.user?.password?.password?.length > 0 ? "d-block" : "d-none"}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="password"
                    name="password_old"
                    onChange={(e) => {
                      this.handleUpdate(e);
                      delete this.state.err?.oldPassword;
                    }}
                    placeholder="Mật khẩu"
                    autoComplete="off"
                  />
                  <Form.Label>
                    Mật khẩu hiện tại<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{this.state.err?.oldPassword}</span>
                </Form.Floating>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={this.handleClose}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>

            <Button size="sm" type="submit" variant="success" onClick={() => this.updateuser()}>
              <i className="fa-solid fa-pencil"></i> Cập nhật
            </Button>
          </Modal.Footer>
        </Modal>

        <Link className="navbar-brand ps-3" to="/admin">
          <img src={Logo} style={{ height: 68 }} alt="CTV Cà Mau" />
          {/* <span className='text-black fw-bold pt-3'>
            Cà Mau
          </span> */}
        </Link>

        <Button
          variant="secondary"
          size="sm"
          className="order-1 order-lg-0 me-2"
          id="sidebarToggle"
          onClick={this.sidenavToggle}
        >
          <i className="fas fa-bars"></i>
        </Button>
    
        <ul
          className="menu-nav d-md-flex "
          // style={{minWidth:'75%'}}
        >
          <Row  >
            <Col md={12} className="d-flex ">
              {this.state.menu?.map((item) => {
                return new Check().permission(item.permission, "or") ? (
                  <NavDropdown
                    title={
                      <>
                        {" "}
                        <span className="sb-nav-link-icon">
                          {" "}
                          <i className={item?.icon}></i>
                        </span>{" "}
                        <span className="fw-bold">
                        {item.label}
                        </span>
        
                      </>
                    }
                    id="collasible-nav-dropdown"
                    className="pe-2"
                    key={`${item?.link}/${item?.icon}/${item?.label}`}
                  >
                    {item.listmenu.map((e) => {
                      return new Check().permission(e.permission, "or") ? (
                        <NavDropdown.Item as={NavLink} to={`/admin/${e?.link}`} key={`${e.link}/${e.icon}`}>
                          <span onClick={() => localStorage.setItem("id_product_detail", 1)}>
                            <span className="sb-nav-link-icon">
                              {" "}
                              <i className={e?.icon}></i>
                            </span>{" "}
                            {e?.label}{" "}
                          </span>
                        </NavDropdown.Item>
                      ) : null;
                    })}
                  </NavDropdown>
                ) : null;
              })}
            </Col>
          </Row>
          <div>
            <Link className="text-primary" to="https://katec.gitbook.io/ctv"   target="_blank" title='Hướng dẫn sử dụng'>
            <i className="fa-solid fa-circle-question"></i>     Hướng dẫn sử dụng
            </Link>
           
          </div>
        </ul>
        
 
       <div>
       <div>
            <Link className="text-primary d-md-none me-3"  to="https://katec.gitbook.io/ctv"   target="_blank" title='Hướng dẫn sử dụng'>
            <i className="fa-solid fa-circle-question"></i>     
            </Link>
           
          </div>
       </div>
        <Row>
          <Col md={12}>
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4 float-end ">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle text-black"
                  id="navbarDropdown"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-user fa-fw"></i>
                  <span className="limited-text">{localStorage.getItem("auth_name_ctv")}</span>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" onClick={this.handleShow}>
                      <i className="fa-solid fa-gear"> </i> Thông tin
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item text-danger" onClick={this.logout}>
                      <i className="fa-solid fa-right-from-bracket"></i> Đăng xuất
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </nav>
    );
  }
}

function myParams(Component) {
  //Back link AND use "params"
  return (props) => <Component navHook={useNavigate()} {...props} params={useParams()} />;
}

export default myParams(Navbar);
