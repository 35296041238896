import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Card, Container, Form, Modal, Col, Row, InputGroup, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import Select from "react-select";
import Check from "../other/Check";
import moment from "moment/moment";
import * as XLSX from "xlsx-js-style";

export default function UserIndex() {
  useEffect(() => {
    document.title = "Nhân viên";
    getlistrole();
    getEthnic();
    getlistPosition();
    getlistusers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [show, setShow] = useState(false);
  const [type, settype] = useState("");
  const [listuser, setlistuser] = useState([]);
  const [userSelectedFile, setuserSelectedFile] = useState(null);
  const [isAddStaffListLoading, setisAddStaffListLoading] = useState(null);
  const [listrole, setlistrole] = useState([]);
  const [id_user, setid_user] = useState("");
  const [user, setuser] = useState({});
  const [selectedRows, setselectedRows] = useState([]);
  const [alertShow, setalertShow] = useState(false);
  const [RoleFilter, setRoleFilter] = useState({
    value: 0,
    label: "Tất cả vai trò",
  });
  const [positionFilter, setpositionFilter] = useState({
    value: 0,
    label: "Tất cả chức vụ",
  });
  const [listposition, setlistposition] = useState([]);

  const [classsearch, setclasssearch] = useState([]);
  const [RoleSearch, setRoleSearch] = useState([]);
  const [users, setusers] = useState({});
  // Fetch API
  const getlistuser = () => {};
  const getlistrole = () => {
    axios
      .get("/role")
      .then((res) => {
        if (res.data.status == 200) {
          setlistrole(res.data.data);
          // .filter(e => Number(e.is_rank) > Number(localStorage.getItem('role_rank'))));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getlistusers = () => {
    axios
      .get("/user")
      .then((res) => {
        if (res.data.status == 200) {
          setusers(res.data.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getlistPosition = () => {
    axios
      .get("/position")
      .then((res) => {
        if (res.data.status == 200) {
          setlistposition(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEthnic = () => {};

  const handleClose = () => {
    setShow(false);
    settype("");
    setuser({});
  };

  function activeuser(item) {
    swal({
      title: `
        Bạn muốn xóa nhân viên?
        `,
      text: `"${item.fullName}"`,
      icon: `error`,
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
      successMode: false,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/user/${item.id}/delete`)
          .then((res) => {
            getlistusers();
            toast(`${res.data.message}`, {
              type: "success",
              autoClose: 1000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  function getdetailuser(id) {
    axios
      .get(`/user/${id}`)
      .then((res) => {
        if (res.data.status == 200) {
          setuser(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Handle Logic
  const selectUserByRole = (value) => {
    setRoleFilter(value);
    setclasssearch("");
    handleClassbySelectOrSearch(listuser, value, "");
  };

  const handleSearchRole = (event) => {
    setclasssearch(event.target.value);

    handleClassbySelectOrSearch(listuser, RoleFilter, event.target.value.trim());
  };

  const handleClassbySelectOrSearch = (listuser, value, text) => {
    let root_ = listuser;

    if (value.value !== 0) {
      if (text) {
        root_ = listuser.filter((e) => e.id_role === value.value && toSlug(e.user_name).includes(toSlug(text)));
      } else {
        root_ = listuser.filter((e) => e.id_role === value.value);
      }
    } else {
      if (text) {
        root_ = listuser.filter((e) => toSlug(e.user_name).includes(toSlug(text)));
      }
    }
    setRoleSearch(root_.filter((e) => e.is_rank > Number(localStorage.getItem("role_rank"))));
  };

  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };

  // Render
  const loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const RenderSelectedRows = () => {
    let num = selectedRows.length;
    if (num > 0 && alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => setalertShow(false)}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  };

  const DataTable = () => {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.fullName,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.fullName}
          </div>
        ),
      },
      {
        name: "Vai trò",
        selector: (row) => row.role_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.role_name}
          </div>
        ),
      },
      {
        name: "Chức vụ",
        selector: (row) => row.position,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.position}
          </div>
        ),
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.email}
          </div>
        ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      setselectedRows(items);
      setalertShow(true);
    };

    if (users.length > 0) {
      users.map((item, i) => {
        data.push({
          id: item.id,
          fullName: item.fullName,
          email: item.email,
          phonenumber: item.phoneNumber,
          position: item.position?.name,
          role_name: item.account?.role?.name,
          setting: (
            <>
              {new Check().permission(["76"]) ? (
                <Button
                  variant="warning "
                  size="sm"
                  className="m-1"

                  // style={{color:'white'}}
                  title="Cập nhật nhân viên"
                  onClick={() => {
                    setid_user(item.id);
                    getdetailuser(item.id);
                    settype("CN");
                    setShow(true);
                  }}
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission(["77"]) ? (
            
                <Button variant="danger"       className="m-1" size="sm" onClick={() => activeuser(item)} title="Xóa nhân viên">
                  <i className="fa-solid fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return <DataTables data={data} columns={columns} selectedRows={selectedRows} />;
  };

  function Formuser() {
    const [username, setusername] = useState(user?.fullName ?? null);
    const [gender, setgender] = useState(user?.gender || null);
    const [birthday, setbirthday] = useState(user.dateOfBirth);
    const [phone, setphone] = useState(user.phoneNumber || "");
    const [email, setemail] = useState(user.email || "");
    const [positionid, setpositionid] = useState(user.position?.id || "");
    const [account, setaccount] = useState(user.account?.userName ?? null);
    const [password, setpassword] = useState("");
    const [id_role, setid_role] = useState(user.account?.role ? user.account?.role?.id : "");
    const [err, setErr] = useState({});

    function createuser() {
      const data = {
        positionId: Number(positionid),
        fullName: username,
        phoneNumber: phone,
        email: email,
        gender: Number(gender),
        dateOfBirth: birthday / 1000 || 0,
        userName: account || "",
        password: password,
        roleId: Number(id_role),
        image: "",
      };

      axios
        .post(`/user`, data)
        .then((res) => {
          if (res.data.status == 200) {
            getlistusers();
            handleClose();
            // setid_user("");
            toast(`${res.data.message}`, {
              type: "success",
              autoClose: 1000,
            });
          } else {
            setErr(res.data.errorMessage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function updateuser() {
      const data = {
        positionId: Number(positionid),
        fullName: username,
        phoneNumber: phone,
        email: email == null ? "" : email,
        gender: Number(gender),
        dateOfBirth: birthday > 0 ? Number(user.dateOfBirth) : Number(new Date(birthday) / 1000),
        userName: account || "",
        oldPassword: user.password,
        newPassword: password,
        roleId: Number(id_role),
        image: "",
      };
      swal({
        title: "Bạn muốn cập nhật nhân viên?",
        icon: "warning",
        buttons: ["Đóng", "Cập nhật"],
        successMode: true,
      }).then((ok) => {
        if (ok) {
          axios
            .put(`/user/${user.id}/update`, data)
            .then((res) => {
              if (res.data.status == 200) {
                getlistusers();
                handleClose();
                setid_user("");
                toast(`${res.data.message}`, {
                  type: "success",
                  autoClose: 1000,
                });
              } else {
                setErr(res.data.errorMessage);
                // toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }

    const handleSubmit = (e) => {
      e.preventDefault();

      if (type === "TM") {
        createuser();
      } else {
        updateuser();
      }
    };

    return (
      <Modal show={show} size="md" onHide={handleClose} backdrop="static" keyboard={false} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-solid fa-chalkboard-user"></i>&nbsp;
            {type === "TM" ? "Thêm người dùng" : "Chi tiết người dùng"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setusername(e.target.value);
                      delete err.fullName;
                      // setaccount
                    }}
                    defaultValue={username}
                    placeholder="Họ và tên"
                    required
                  />
                  <Form.Label>
                    Họ và tên<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-form-err">{err.fullName}</span>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    className="form-control"
                    defaultValue={phone}
                    onChange={(e) => {
                      setphone(e.target.value);
                      delete err.phoneNumber;
                    }}
                    placeholder="Số điện thoại"
                  />
                  <Form.Label>Số điện thoại</Form.Label>
                  <span className="text-form-err">{err.phoneNumber}</span>
                </Form.Floating>
              </Col>
              <Col md={3}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    defaultValue={user.gender}
                    onChange={(e) => {
                      setgender(e.target.value);
                      delete err.gender;
                    }}
                  >
                    <option value="0">--Chọn--</option>
                    <option value="2">Nam</option>
                    <option value="1">Nữ</option>
                    <option value="3">Khác</option>
                  </Form.Select>
                  <Form.Label>
                    Giới tính<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{err.gender}</span>
                </Form.Floating>
              </Col>
              <Col md={4}>
                <Form.Floating className="mb-3">
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={moment(new Date(user.dateOfBirth * 1000)).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setbirthday(
                        e.target.value
                        // .getTime() / 1000
                      );
                      delete err.dateOfBirth;
                    }}
                  />
                  <Form.Label>
                    Ngày sinh<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{err?.dateOfBirth}</span>
                </Form.Floating>
              </Col>
              <Col md={5}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      delete err.email;
                      setemail(e.target.value);
                    }}
                    defaultValue={user.email}
                    placeholder="Hình thức hợp đồng"
                  />
                  <Form.Label>Email</Form.Label>
                  <span className="text-form-err">{err.email}</span>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    defaultValue={user.position?.id}
                    onChange={(e) => {
                      setpositionid(e.target.value);
                      delete err.positionId;
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    {listposition.length > 0
                      ? listposition.map((ethnic_) => {
                          return (
                            <option
                              //  key={ethnic_.id}
                              value={ethnic_.id}
                            >
                              {ethnic_.name}
                            </option>
                          );
                        })
                      : null}
                  </Form.Select>
                  <Form.Label>
                    Chức vụ<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-form-err">{err.positionId}</span>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    onChange={(e) => {
                      setid_role(e.target.value);
                      delete err.roleId;
                    }}
                    defaultValue={user?.account?.role?.id}
                    required={account}
                  >
                    <option value="">--Chọn--</option>
                    {listrole.map((role) => {
                      return (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Label>
                    Vai trò<i className="text-danger">{account ? "*" : ""}</i>
                  </Form.Label>
                  <span className="text-form-err">{err.roleId}</span>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setaccount(e.target.value);
                      delete err.userName;
                    }}
                    autoComplete="off"
                    defaultValue={account == "" ? "" : account}
                    placeholder="Tài khoản"
                  />
                  <Form.Label>
                    Tài khoản<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{err.userName}</span>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="password"
                    onChange={(e) => {
                      setpassword(e.target.value);
                      delete err.newPassword;
                    }}
                    defaultValue={""}
                    placeholder="Mật khẩu"
                    // autoComplete="off"
                    autoComplete="new-password"
                  />
                  <Form.Label>
                    Mật khẩu<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-form-err">{type === "TM" ? err.password : err.newPassword}</span>
                </Form.Floating>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            {type === "TM" ? (
              <Button size="sm" type="submit" variant="success">
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
            ) : (
              <Button size="sm" type="submit" variant="success">
                <i className="fa-solid fa-pencil"></i> Cập nhật
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  const renderSearchFilter = () => {
    let options = [];
    listrole.map((item) => {
      // if (item.is_rank > Number(localStorage.getItem('role_rank')))
      options.push({
        value: item.id,
        label: item.name,
      });

      return item;
    }, options.unshift({ value: 0, label: "Tất cả vai trò" }));
    let optionsPosition = [];
    listposition.map((item) => {
      // if (item.is_rank > Number(localStorage.getItem('role_rank')))
      optionsPosition.push({
        value: item.id,
        label: item.name,
      });

      return item;
    }, optionsPosition.unshift({ value: 0, label: "Tất cả vai trò" }));
    return (
      <Row>
        <Col md={3} sm={6} className="mb-2">
          <InputGroup>
            <Form.Control
              style={{ height: "39px" }}
              onChange={(event) => handleSearchRole(event)}
              value={classsearch}
              placeholder="Nhập tên người dùng"
            />
            {/* <InputGroup.Text><i className="fa-solid fa-search me-1" /></InputGroup.Text> */}
          </InputGroup>
        </Col>

        <Col md={3} sm={6} className="mb-2" style={{ zIndex: "2" }}>
          <Select
            defaultValue={options[0]}
            options={options}
            placeholder="Chọn vai trò"
            value={RoleFilter}
            onChange={(choice) => {
              selectUserByRole(choice);
            }}
          />
        </Col>
        <Col md={3} sm={6} className="mb-2" style={{ zIndex: "2" }}>
          <Select
            defaultValue={optionsPosition[0]}
            options={optionsPosition}
            placeholder="Chọn chức vụ"
            value={positionFilter}
            onChange={(choice) => {
              selectUserByRole(choice);
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid>
      <RenderSelectedRows />

      <Breadcrumb className="mb-2 mt-2">
        <Breadcrumb.Item active>Hệ thống</Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý người dùng</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Card.Header>
          <div className="d-flex d-md-flex justify-content-between">
            <Card.Title>
              <i className="fas fa-list me-1" />
              Danh sách người dùng
            </Card.Title>
            {new Check().permission(["75"]) ? (
              <Button
                type="button"
                style={{ float: "right" }}
                className="mt-3 mt-md-0"
                variant="success"
                size="sm"
                onClick={() => {
                  settype("TM");
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i>
                Thêm
              </Button>
            ) : null}
          </div>
        </Card.Header>
        <Card.Body>
          {/* {renderSearchFilter()} */}
          {
            // checkload ? loading() :
            <DataTable />
          }
        </Card.Body>
      </Card>
      {show ? <Formuser /> : ""}
    </Container>
  );
}
