import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../layouts/frontend/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Container, Breadcrumb, Card, Badge } from "react-bootstrap";
import axios from "axios";
import imageIconDT from "../../assets/admin/assets/img/tong doanh thu.png";
// import imageIconTB from "../../assets/admin/assets/img/tong doanh thu.png";
import imageIconQC from "../../assets/admin/assets/img/DoanhThuQC.png";
import imageDurationTB from "../../assets/admin/assets/img/DurationTB.png";
import imageDurationQC from "../../assets/admin/assets/img/DurationQC.png";
import imageIconTB from "../../assets/admin/assets/img/money.png";

function Home() {
    const history = useNavigate();
    const [tottalTb, setTotalTB] = useState(0);
    const [tottalQC, setTotalQC] = useState(0);
    const [tottalDuraQC, setTotalDuraQC] = useState(0);
    const [tottalDuraTB, setTotalDuraTB] = useState(0);

    useEffect(() => {
        document.title = "Tổng quan";
        getRevene1();
        getRevene2();
    }, []);

    const getRevene1 = () => {
        axios
            .get(
                `summary/monthly-summary-list?customerId=0&startDay=${parseInt(
                    new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)) / 1000
                )}&endDay=${
                    new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59)) /
                    1000
                }
        &adPriceId=0&contractId=0&contractTypeId=0&tapeCodeId=0&productId=0&supplierId=0&viewType=1&createdBy=0&groupIds=[]`
            )
            .then((res) => {
                if (res.data.status == 200) {
                    setTotalDuraTB(res.data.data[0]?.totalDuration || 0);
                    setTotalTB(res.data.data[0]?.totalRevenue || 0);
                }
            });
    };
    const getRevene2 = () => {
        axios
            .get(
                `summary/monthly-summary-list?customerId=0&startDay=${parseInt(
                    new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)) / 1000
                )}&endDay=${
                    new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59)) /
                    1000
                }&adPriceId=0&contractId=0&contractTypeId=0&tapeCodeId=0&productId=0&supplierId=0&viewType=2&createdBy=0&groupIds=[]`
            )
            .then((res) => {
                if (res.data.status == 200) {
                    setTotalDuraQC(res.data.data[0]?.totalDuration || 0);
                    setTotalQC(res.data.data[0]?.totalRevenue || 0);
                }
            });
    };
    const formatNumber = (num) => {
        if (num != null && num != "") return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        else return 0;
    };

    return (
        <Container fluid>
            <Breadcrumb className="mb-2 mt-2">
                <Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="mb-4 py-2 ">
                <center>
                    <h2>
                        <Badge className="text-center" bg="secondary">
                            THỐNG KÊ THÁNG {new Date().getMonth() + 1}/{new Date().getFullYear()}
                        </Badge>
                    </h2>{" "}
                </center>

                <Row className=" justify-content-center me-2 ms-2 ">
                    <Col className="  text-center   d-flex align-items-center my-2 " md={4}>
                        <div className=" row-home">
                            <img style={{ height: "100px" }} src={imageIconDT} alt="icon" />
                            <center className="ms-2">
                                Tổng doanh thu
                                <center>{formatNumber(tottalTb + tottalQC)} VNĐ</center>
                            </center>
                        </div>
                    </Col>
                    {/* </Row>
        <Row className="me-3"> */}
                    <Col className=" text-center d-flex align-items-center my-2" md={4}>
                        <div className=" row-home ">
                            <img style={{ height: "100px" }} src={imageIconTB} alt="icon" />
                            <center className="ms-2">
                                Doanh thu thông báo<center>{formatNumber(tottalTb)} VNĐ</center>
                            </center>
                        </div>

                        {/* <span>Tổng doanh thu thông báo {tottalTb}</span> */}
                    </Col>
                    <Col className="  text-center d-flex align-items-center my-2" md={4}>
                        <div className=" row-home">
                            <img style={{ height: "100px" }} src={imageIconQC} alt="icon" />
                            <center className="ms-2">
                                Doanh thu quảng cáo<center>{formatNumber(tottalQC)} VNĐ</center>
                            </center>
                        </div>
                    </Col>
                </Row>
                {/* <Row className="m-2 justify-content-center  ">
                    <Col className=" text-center d-flex align-items-center my-2" md={4}>
                        <div className="row-home ">
                            <img style={{ height: "100px" }} src={imageDurationTB} alt="icon" />
                            <center className="ms-2">
                                Thời lượng thông báo<center>{formatNumber(tottalDuraTB)} giây</center>
                            </center>
                        </div>
                    </Col>
                    <Col className=" text-center d-flex align-items-center my-2" md={4}>
                        <div className=" row-home ">
                            <img style={{ height: "100px" }} src={imageDurationQC} alt="icon" />
                            <center className="ms-2">
                                Thời lượng quảng cáo<center>{formatNumber(tottalDuraQC)} giây</center>
                            </center>
                        </div>
                    </Col>
                </Row> */}
            </Card>
        </Container>
    );
}

export default Home;
