import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import '../../assets/admin/css/styles.css';
import { toast } from 'react-toastify';
import axios from 'axios';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

function MasterLayout() {
    const navigate=useNavigate()
    useEffect(() => {
        checkLogin();
        localStorage.setItem('menu', JSON.stringify(menu))
        if (!localStorage.getItem('access_token_ctv'))
          navigate('/login')
        
    }, [localStorage.getItem('access_token_ctv')]);
    // const checkLogin=()=>{

    // }
    useEffect(() => { 
        if(window.location.pathname=='/') {
            // toast("Đăng nhập thành công.", {type: "success", autoClose: 1000});
            return navigate("/admin");
        }else{
         ;
        }
    }, []);
    const checkLogin =  () => {
         axios.post(`/account/check-token`).then(res => {
            if (res.data.status == 200) {
                // toast(res.data.data.mess, { type: "error", autoClose: 10 });
                // getLocalStore();
            } else {
               
                toast('Phiên đăng nhập hết hạn', { type: "error", autoClose: 10 });
                localStorage.removeItem("access_token_ctv");
                navigate('/login')
                // navigate('/login')
                // localStorage.setItem("auth_name_ctv", res.data.data.user_name);
                // localStorage.setItem("role_rank", res.data.data.role_rank);
                // localStorage.setItem("role_id", res.data.data.role_id);
                // localStorage.setItem("role_name", res.data.data.role_name);
                // localStorage.setItem("permission", res.data.data.permission);
                // localStorage.setItem("vip", res.data.data.vip);
            }
        });
    }
    const menu = [
        {
            id: 'pagesCollapseSystem',
            permission: ['95'],
            icon: 'fa-solid fa-gears',
            label: 'Hệ thống',
            listmenu: [
                
                {
                    permission: ['94'],
                    icon: 'fa-solid fa-users',
                    label: 'Quản lý người dùng',
                    link: 'user',
                }, {
                    permission: ['90'],
                    icon: 'fa-solid fa-user-secret',
                    label: 'Vai trò người dùng',
                    link: 'role',
                }, {
                    permission: [`89`],
                    icon: 'fa-solid fa-user-tie',
                    label: 'Quản lý chức vụ',
                    link: 'position',
                },
                {
                    permission: [`79`],
                    icon: 'fa-solid fa-building',
                    label: 'Thông tin đơn vị',
                    link: 'company',
                }
            ]
        },
        {
            id: 'pagesCollapseCategory',
            permission: ['96'],
            icon: 'fa-solid fa-memory',
            label: 'Danh mục',
            listmenu: [
              {
                    permission: [`87`],
                    icon: 'fa-solid fa-file-contract',
                    label: 'Loại hợp đồng',
                    link: 'category-contract',
                },
                {
                    permission: [`85`],
                    icon: 'fa-solid fa-people-line',
                    label: 'Loại khách hàng',
                    link: 'category-customer',
                },
                {
                    permission: [`86`],
                    icon: 'fa-solid fa-person-dress',
                    label: 'Khách hàng',
                    link: 'customer',
                },
               
                {
                    permission: [`82`],
                    icon: 'fa-solid fa-tv',
                    label: 'Sản phẩm QC',
                    link: 'product',
                },
                {
                    permission: ['84'],
                    icon: 'fa-solid fa-computer',
                    label: 'TVC QC (Mã băng)',
                    link: 'tap-code',
                },
                // {
                //     permission: null,
                //     icon: 'fa-solid fa-circle-info',
                //     label: 'Thông tin chung',
                //     link: '',
                // },
                // {
                //     permission: null,
                //     icon: 'fa-solid fa-computer',
                //     label: 'Mẫu QC tự định nghĩa',
                //     link: ' updating',
                // },
                // {
                //     permission: null,
                //     icon: 'fa-solid fa-percent',
                //     label: 'Phân khúc tỉ lệ',
                //     link: '',
                // },
                // {
                //     permission: null,
                //     icon: 'fa-solid fa-sack-dollar',
                //     label: 'Bảng giá GRP',
                //     link: '',
                // },
                {
                    permission: ['81'],
                    icon: 'fa-solid fa-location-crosshairs',
                    label: '  Loại Spot',
                    link: 'category-spot',
                },
                // {
                //     permission: null,
                //     icon: 'fa-solid fa-film',
                //     label: '  Phim',
                //     link: '',
                // },
                {
                    permission: ['80'],
                    icon: 'fa-solid fa-arrow-up-from-ground-water',
                    label: 'Nhà cung cấp',
                    link: 'supplier',
                },



            ]
        },


        {
            id: 'pagesCollapseNoti',
            permission: ['97'],
            icon: 'fa-solid fa-bell',
            label: 'Thông báo',
            listmenu: [
                {
                    permission: ['88'],
                    icon: 'fa-regular fa-bell',
                    label: ' Hợp đồng thông báo',
                    link: 'contract-notification',
                }, {
                    permission: ['65'],
                    icon: 'fa-regular fa-envelope',
                    label: 'Thông báo hàng ngày',
                    link: 'schedule-notification-daily',

                },

            ]
        },
        {
            id: 'pagesCollapseAdver',
            permission: ['98'],
            icon: 'fa-solid fa-video',
            label: 'Quảng cáo',
            listmenu: [
                {
                    permission: ['83'],
                    icon: 'fa-solid fa-dollar-sign',
                    label: 'Giá bán lẻ',
                    link: 'price',
                }, {
                    permission: ['88'],
                    icon: 'fa-solid fa-handshake',
                    label: 'Hợp đồng quảng cáo',
                    link: 'contract-advertisement',

                },
                {
                    permission: ['91'],
                    icon: 'fa-solid fa-pen',
                    label: 'Đăng ký quảng cáo',
                    link: 'schedule',
                }
                , {
                    permission: ['65'],
                    icon: 'fa-solid fa-calendar-days',
                    label: '  Lịch QC hàng ngày',
                    link: 'schedule-advertisement-daily',

                }
                , {
                    permission: null,
                    icon: 'fa-solid fa-bag-shopping',
                    label: 'Chứng nhận phát sóng',
                    link: ' updating',

                }




            ]
        },
        {
            id: 'pagesCollapseMana',
            permission: ['99'],
            icon: 'fa-solid fa-chart-pie',
            label: 'Quản trị',
            listmenu: [
                {
                    permission: ['93'],
                    icon: 'fa-solid fa-chart-simple',
                    label: ' Tổng kết QC',
                    link: 'summary-list',

                }, {
                    permission: ['72'],
                    icon: 'fa-solid fa-chart-line',
                    label: 'Tổng kết năm',
                    link: 'year-summary-list',

                },
                {
                    permission: ['73'],
                    icon: 'fa-solid fa-boxes-stacked',
                    label: 'Chi tiết Spot',
                    link: 'report-spot-detail',

                }
                , {
                    permission: ['72'],
                    icon: 'fa-solid fa-calendar-check',
                    label: 'Tổng kết tháng',
                    link: 'monthly-summary',

                } , {
                    permission: ['72'],
                    icon: 'fa-solid fa-list-ul',
                    label: 'Danh sách tổng kết tháng',
                    link: 'monthly-summary-list',

                }
                // , {
                //     permission: null,
                //     icon: 'fa-solid fa-calendar-days',
                //     label: 'TK TL - theo ngày',
                //     link: ' updating',

                // }


            ]
        }
    ]
    const getLocalStore = () => {
        localStorage.removeItem("auth_id_shool");
        localStorage.removeItem("auth_id_user");
        localStorage.removeItem("auth_name_ctv");
        localStorage.removeItem("role_rank");
        localStorage.removeItem("role_id");
        localStorage.removeItem("role_name");
        localStorage.removeItem("permission");
        localStorage.removeItem("token_type");
        localStorage.removeItem("access_token_ctv");
        localStorage.removeItem("auth_phone");
        localStorage.removeItem("auth_account");
        localStorage.removeItem("vip");
        window.location.href = "/";
    }

  

    const sidenavToggle = () => {
        const sbNavFixed = document.getElementById('sb-nav-fixed');
        //sbNavFixed.classList.remove('sb-sidenav-toggled');

        let testClass = sbNavFixed.className;

        if (testClass === 'sb-nav-fixed sb-sidenav-toggled' && window.innerWidth < 992) {
            sbNavFixed.classList.remove('sb-sidenav-toggled');
        }
    }


    return (
        <div className='sb-nav-fixed' id="sb-nav-fixed">

            <Navbar />

            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <Sidebar />
                </div>

                <div id="layoutSidenav_content" onClick={sidenavToggle}>
                    <main style={{ position: 'inherit', zIndex: '0' }}>
                        <Outlet />
                    </main>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default MasterLayout;
