import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb, Form, Table } from "react-bootstrap";
import DataTables from "../datatable/DataTables";
// import CategoryContractCreate from "./CategoryContractCreate";
import Check from "../other/Check";

class CompanyIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {},
      selectedRows: [],
      checkload: true,
      bankList: {},
      representativeList: {},
      err: {},
      checkVal: false,
    };
  }

  componentDidMount() {
    document.title = "Thông tin đơn vị";
    this.getRoles();
  }
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.company };
    dataInput[field] = value;
    this.setState({ company: dataInput });

    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };
  removeSelectRepre = (name, role) => {
    swal({
      title: "Bạn muốn xóa người đại diện",
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        var arr = this.state.representativeList.filter((item) =>
          name ? item.name !== name && item.role != role : item.role != role
        );
        arr.push({
          name: "",
          phoneNumber: "",
          email: "",
          role: "",
        });

        this.setState({
          representativeList: Array.from(new Set(arr?.map(JSON.stringify)), JSON.parse),
        });
        toast("Đã xóa người địa diện.", {
          type: "success",
          autoClose: 1000,
        });
      }
    });
  };
  removeSelect = (bank) => {
    swal({
      title: "Bạn muốn xóa ngân hàng?",
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        toast("Đã xóa ngân hàng.", {
          type: "success",
          autoClose: 1000,
        });
        var arr = this.state.bankList?.filter((item) =>
          bank.bankNumber
            ? item.bankNumber !== bank.bankNumber && item.cardOwner != bank.cardOwner
            : item.cardOwner != bank.cardOwner
        );
        arr.push({
          bankName: "",
          bankNumber: "",
          cardOwner: "",
          address: "",
          note: "",
        });

        this.setState({
          bankList: Array.from(new Set(arr?.map(JSON.stringify)), JSON.parse),
        });

        //
      }
    });
  };
  getRoles = () => {
    axios.get(`/company/1`).then((res) => {
      if (res.data.status == 200) {
        this.setState({ checkload: false });
        this.setState({ company: res.data.data });
        let arr = res.data.data.bankList;
        arr.push({
          bankName: "",
          bankNumber: "",
          cardOwner: "",
          address: "",
          note: "",
        });
        this.setState({ bankList: arr });

        let arr1 = res.data.data.representativeList;
        arr1.push({
          name: "",
          role: "",
          phoneNumber: "",
          email: "",
        });
        this.setState({ representativeList: arr1 });
      }
    });
  };

  getRole = (item) => {
    if (item !== "null") {
      // axios.post(`/viewRole?id_role=${id}`).then((res) => {
      //   if (res.data.status === true) {
      //     const data = res.data.data[0];

      this.setState({
        role: {
          id: item.id,
          role_name: item.name,
          // is_rank: data.is_rank,
          // permission: data.permission !== null ? data.permission.split(',') : [],
        },
      });

      this.setState({ modalRoleShow: true });
    }
    // });
  };

  RenderIngredientSelect() {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr>
              <th rowSpan={2} className="align-middle text-center">
                STT
              </th>
              <th rowSpan={2} className="align-middle text-center" style={{ width: "220px" }}>
                Tên tài khoản
              </th>
              <th className="align-middle text-center">
                Số tài khoản{" "}
                <i className="text-danger">
                  {this.state.bankList?.filter((e) => e.bankNumber != "" || e.bankName != "").length > 0 ? "*" : ""}
                </i>
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Tại ngân hàng{" "}
                <i className="text-danger">
                  {this.state.bankList?.filter((e) => e.bankNumber != "" || e.bankName != "").length > 0 ? "*" : ""}
                </i>
              </th>{" "}
              <th rowSpan={2} className="align-middle text-center">
                Địa chỉ
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Ghi chú
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Phương thức
              </th>
            </tr>
          </thead>
          <tbody>{this.RenderIngredientFoodDetail()}</tbody>
        </Table>
      </div>
    );
  }

  RenderIngredientFoodDetail() {
    return (
      <>
        {/* ingredientFoodDetail */}
        {}
        {this.state.bankList?.map((bank, index) => {
          return (
            <tr key={index}>
              <td className="align-middle text-center">{index + 1}</td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="cardOwner"
                    value={bank.cardOwner}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.cardOwner = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;
                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            cardOwner: e.target.value,
                            bankNumber: item.bankNumber,
                            bankName: item.bankName,
                            address: item.address,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Tên tài khoản"
                  />
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.bankNumber == "" && bank.bankName == "" && bank.cardOwner == ""
                      ? null
                      : bank.cardOwner?.trim()?.length < 100
                      ? null
                      : "Tên tài khoản không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="cardOwner"
                    value={bank.bankNumber}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.bankNumber = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;
                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            cardOwner: item.cardOwner,
                            bankNumber: e.target.value,
                            bankName: item.bankName,
                            address: item.address,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Tên tài khoản"
                  />

                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.bankNumber == "" && bank.bankName == "" && bank.cardOwner == ""
                      ? null
                      : bank.bankNumber?.replace(/\s/g, "")?.trim().length > 0 &&
                        // !isNaN(bank.bankNumber?.replace(/\s/g, "")) &&
                        bank.bankNumber?.replace(/\s/g, "")?.trim().length < 51
                      ? null
                      : "Số tài khoản không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>

              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="bankName"
                    value={bank.bankName}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.bankName = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            bankName: e.target.value,
                            bankNumber: item.bankNumber,
                            cardOwner: item.cardOwner,
                            address: item.address,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Tên ngân hàng"
                  />{" "}
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.bankNumber == "" && bank.bankName == "" && bank.cardOwner == ""
                      ? null
                      : bank?.bankName?.trim()?.length > 0 && bank?.bankName?.trim()?.length < 100
                      ? null
                      : "Tên ngân hàng không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="bankName"
                    value={bank.address}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.address = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            bankName: item.bankName,
                            bankNumber: item.bankNumber,
                            cardOwner: item.cardOwner,
                            address: e.target.value,
                            note: item.note,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Địa chỉ"
                  />
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.bankNumber == "" && bank.bankName == "" && bank.cardOwner == ""
                      ? null
                      : bank?.address == null || bank?.address?.trim()?.length < 255
                      ? null
                      : "Địa chỉ không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="note"
                    value={bank.note}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.bankList };
                      dataInput.note = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        bankList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.bankList.map((item, index) => {
                        if (
                          item.bankName == bank.bankName &&
                          item.bankNumber == bank.bankNumber &&
                          item.cardOwner == bank.cardOwner &&
                          item.address == bank.address &&
                          item.note == bank.note
                        ) {
                          return {
                            // id:index+1,
                            bankName: item.bankName,
                            bankNumber: item.bankNumber,
                            cardOwner: item.cardOwner,
                            address: item.address,
                            note: e.target.value,
                          };
                        }
                        return item;
                      });
                      if (
                        // newBank.id != 0&&
                        newBank.bankName != "" &&
                        newBank.bankNumber != "" &&
                        newBank.cardOwner != "" &&
                        newBank.address != "" &&
                        newBank.note != ""
                      ) {
                        newBank.push({
                          bankName: "",
                          bankNumber: "",
                          cardOwner: "",
                          address: "",
                          note: "",
                        });
                      }
                      this.setState({
                        bankList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Noteỉ"
                  />
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {
                      // (bank.note=='') ? null :
                      bank.note == null || (bank?.note?.length >= 0 && bank?.note?.trim()?.length < 255)
                        ? null
                        : "Ghi chú không hợp lệ"
                    }
                  </span>
                </Form.Floating>
              </td>

              <td className="align-middle text-center">
                {Number(
                  bank.bankNumber == 0 &&
                    bank.cardOwner == 0 &&
                    bank.bankName == 0 &&
                    bank.address == 0 &&
                    bank.note == 0
                ) ? (
                  ""
                ) : (
                  <>
                    {new Check().permission(["14"]) ? (
                      <Button
                        variant="danger"
                        size="sm"
                        type="button"
                        title="Xóa ngân hàng"
                        onClick={() => {
                          this.removeSelect(bank);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </Button>
                    ) : null}
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  }
  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa loại hợp đồng?",
      text: `"${item.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .put(`/contract-type/${item.id}/delete`)

          .then((res) => {
            if (res.data.status === 200) {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getRoles();
            } else {
              toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
            }
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  getDate = (data) => {
    const date = new Date(data * 1000);
    // date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + (date.getMinutes() + 1)).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2)
    );
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  updateCompany = () => {
    swal({
      title: "Bạn muốn cập nhật thông tin đơn vị",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        this.setState({ checkVal: true });
        var company = this.state.company;
        let bank = this.state.bankList.filter(
          (e) => e.bankName != "" || e.bankNumber != "" || e.cardOwner != "" || e.address != "" || e.note != ""
        );

        let data = {
          name: company.name, // require

          faxNumber: company.faxNumber,
          taxCode: company.taxCode,
          address: company.address,
          phoneNumber: company.phoneNumber,

          bankList: this.state.bankList.filter(
            (e) => e.bankName != "" || e.bankNumber != "" || e.cardOwner != "" || e.address != "" || e.note != ""
          ),
          representativeList: this.state.representativeList.filter(
            (e) => e.name != "" || e.role != "" || e.phoneNumber != "" || e.email != ""
          ),
        };

        axios
          .put(`/company/1/update`, data)
          .then((res) => {
            if (res.data.status == 200) {
              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });
              // return this.props.submit();
            } else {
              this.setState({ err: res.data.errorMessage });
            }
          })
          .catch((error) => {
            console.log(error);
            toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
          });
      }
    });
  };
  dataTable() {
    return (
      <>
        <Row>
          <Col md={6}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputClassName"
                placeholder="Tên khách hàng"
                name="name"
                defaultValue={this.state.company.name}
                onChange={(e) => {
                  this.handleInput(e);
                }}
              />
              <Form.Label htmlFor="floatingInputClassName">
                Tên đơn vị<i className="text-danger">*</i>
              </Form.Label>
              <span className="text-form-err">{this.state.err.name}</span>
              <div className="invalid-feedback"></div>
            </Form.Floating>
          </Col>
          <Col md={6}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputClassName"
                placeholder="Tên khách hàng"
                name="address"
                defaultValue={this.state.company.address}
                onChange={(e) => {
                  this.handleInput(e);
                }}
              />
              <Form.Label htmlFor="floatingInputClassName">
                Địa chỉ<i className="text-danger"></i>
              </Form.Label>
              <div className="invalid-feedback"></div>
            </Form.Floating>
          </Col>
          <Col md={4}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputClassName"
                placeholder="Tên khách hàng"
                name="phoneNumber"
                defaultValue={this.state.company.phoneNumber}
                onChange={(e) => {
                  this.handleInput(e);
                }}
              />
              <Form.Label htmlFor="floatingInputClassName">
                Số điện thoại<i className="text-danger"></i>
              </Form.Label>
              <span className="text-form-err">{this.state.err.phoneNumber}</span>
              <div className="invalid-feedback"></div>
            </Form.Floating>
          </Col>
          <Col md={4}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputClassName"
                placeholder="Tên khách hàng"
                name="taxCode"
                defaultValue={this.state.company.taxCode}
                onChange={(e) => {
                  this.handleInput(e);
                }}
              />
              <Form.Label htmlFor="floatingInputClassName">
                Mã số thuế<i className="text-danger"></i>
              </Form.Label>{" "}
              <span className="text-form-err">{this.state.err.taxCode}</span>
              <div className="invalid-feedback"></div>
            </Form.Floating>
          </Col>
          <Col md={4}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputClassName"
                placeholder="Tên khách hàng"
                name="faxNumber"
                defaultValue={this.state.company.faxNumber}
                onChange={(e) => {
                  this.handleInput(e);
                }}
              />
              <Form.Label htmlFor="floatingInputClassName">
                Fax<i className="text-danger"></i>
              </Form.Label>
              <span className="text-form-err">{this.state.err.faxNumber}</span>
              <div className="invalid-feedback"></div>
            </Form.Floating>
          </Col>
        </Row>

        {this.RenderIngredientSelect()}
        {this.RenderRepresentativeList()}
        {new Check().permission(["14"]) ? (
          <Button variant="warning" onClick={() => this.updateCompany()}>
            Cập nhật
          </Button>
        ) : null}
      </>
    );
  }
  RenderRepresentativeList() {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr>
              <th rowSpan={2} className="align-middle text-center">
                STT
              </th>
              <th rowSpan={2} className="align-middle text-center" style={{ width: "220px" }}>
                Tên người đại diện{" "}
                <i className="text-danger">
                  {this.state.representativeList?.filter((e) => e.role != "" || e.phoneNumber != "").length > 0
                    ? "*"
                    : ""}
                </i>
              </th>
              <th className="text-center">
                Chức vụ<i className="text-danger"></i>
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Điện thoại
              </th>{" "}
              <th rowSpan={2} className="align-middle text-center">
                Email
              </th>
              <th rowSpan={2} className="align-middle text-center">
                Phương thức
              </th>
            </tr>
          </thead>
          <tbody>{this.representativeList()}</tbody>
        </Table>
      </div>
    );
  }
  representativeList() {
    return (
      <>
        {/* ingredientFoodDetail */}
        {}
        {this.state.representativeList?.map((bank, index) => {
          return (
            <tr key={index}>
              <td className="align-middle text-center">{index + 1}</td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="cardOwner"
                    value={bank.name}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.name = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;
                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map((item, index) => {
                        if (
                          item.name == bank.name &&
                          item.role == bank.role &&
                          item.phoneNumber == bank.phoneNumber &&
                          item.email == bank.email
                        ) {
                          return {
                            // id:index+1,
                            ...item,
                            name: e.target.value,
                          };
                        }
                        return item;
                      });
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Tên người đại diện"
                  />
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.role == "" && bank.name == ""
                      ? null
                      : bank.name.trim().length > 0 && bank.name.trim().length < 51
                      ? null
                      : "Tên người đại diện không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="role"
                    value={bank.role}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.role = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map((item, index) => {
                        if (
                          item.name == bank.name &&
                          item.role == bank.role &&
                          item.phoneNumber == bank.phoneNumber &&
                          item.email == bank.email
                        ) {
                          return {
                            ...item,
                            role: e.target.value,
                          };
                        }
                        return item;
                      });
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Chức vụ"
                  />
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.role == "" && bank.name == ""
                      ? null
                      : (bank?.role?.trim()?.length >= 0 && bank?.role?.trim()?.length < 101) || bank?.role == null
                      ? null
                      : "Chức vụ không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>

              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    value={bank.phoneNumber}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false, checkVal: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.phoneNumber = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map((item, index) => {
                        if (
                          item.name == bank.name &&
                          item.role == bank.role &&
                          item.phoneNumber == bank.phoneNumber &&
                          item.email == bank.email
                        ) {
                          return {
                            ...item,
                            phoneNumber: e.target.value,
                          };
                        }
                        return item;
                      });
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Điện thoại"
                  />{" "}
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.phoneNumber == ""
                      ? null
                      : // (!isNaN(bank.phoneNumber?.replace(/\s/g, "")) &&
                      (bank.phoneNumber?.length > 7 && bank.phoneNumber?.length < 13) || bank.phoneNumber == null
                      ? null
                      : "Số điện thoại không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>
              <td>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    name="email"
                    value={bank.email}
                    onChange={(e) => {
                      // this.handleInputBank(e)
                      this.setState({ bntAdd: false });
                      let dataInput = { ...this.state.representativeList };
                      dataInput.email = e.target.value;
                      // dataInput

                      const uniqueObjects = dataInput;

                      this.setState({
                        representativeList: uniqueObjects,
                        // Array.from(new Set(dataInput?.map(JSON.stringify)), JSON.parse)
                      });

                      let newBank = this.state.representativeList.map((item, index) => {
                        if (
                          item.name == bank.name &&
                          item.role == bank.role &&
                          item.phoneNumber == bank.phoneNumber &&
                          item.email == bank.email
                        ) {
                          return {
                            ...item,
                            email: e.target.value,
                          };
                        }
                        return item;
                      });
                      if (
                        newBank.name != "" &&
                        newBank.role != "" &&
                        newBank.phoneNumber != "" &&
                        newBank.email != ""
                      ) {
                        newBank.push({
                          name: "",
                          role: "",
                          phoneNumber: "",
                          email: "",
                        });
                      }
                      this.setState({
                        representativeList: Array.from(new Set(newBank?.map(JSON.stringify)), JSON.parse),
                      });
                    }}
                    placeholder="Email"
                  />
                  <span className={this.state.checkVal ? "text-form-err" : "d-none"}>
                    {bank.email == ""
                      ? null
                      : /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(bank.email) || bank.email == null
                      ? null
                      : "Email không hợp lệ"}
                  </span>
                </Form.Floating>
              </td>

              <td className="align-middle text-center">
                {Number(bank.name == 0 && bank.role == 0 && bank.phoneNumber == 0 && bank.email == 0) ? (
                  ""
                ) : (
                  <>
                    {new Check().permission(["14"]) ? (
                      <Button
                        variant="danger"
                        size="sm"
                        type="button"
                        title="Xóa người đại diện"
                        onClick={() => {
                          this.removeSelectRepre(
                            bank.name,
                            bank.role
                            // ingredient.id_ingredent
                          );
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </Button>
                    ) : null}
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  }
  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      this.getRoles();
    };

    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1"></i> Thông tin đơn vị
          </Card.Title>
        </Card.Header>

        <Card.Body>{this.state.checkload ? this.loading() : this.dataTable()}</Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Hệ thống</Breadcrumb.Item>
            <Breadcrumb.Item active>Thông tin đơn vị</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CompanyIndex;
