import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Modal,
  Accordion,
  Table,
  Form,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx-js-style";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RegisterBlockFormModal from "../registerBlock/RegisterBlockFormModal";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { ReactToPrint } from "react-to-print";
// import { com } from "./compoenttoPrint";
// import { ComponentToPrint } from "./ComponentToPrint";
// import { ComponentToPrint } from "./ComponentToPrint";
import PrintButton from "./PrintButton";

class ScheduleDaily extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.updateItem = this.updateItem.bind(this);

    this.state = {
      roles: [],
      deps: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
      selectedRows: [],
      checkload: true,
      itemschedule: {},
      dateSelect: 0,
      dataDaily: [],
      idBroadList: 0,
      start_: new Date(),
      startDate: new Date(),
      // new Date(),
      endDate: new Date(),
      end_: new Date(),
      show: false,
      link_location: window.location.pathname,
      isCashSelected: false,
      dataExport: [],
      date: 0,
      detailDate: "",
      countDuration: 0,
      isNotify: false,
      showRegisterBlock: false,
      dataRes: [],
      idRes: null,
      isSocial: false,
    };
  }

  async componentDidMount() {
    document.title =
      this.props.element == "notification"
        ? "Thông báo hàng ngày"
        : "Lịch quảng cáo hàng ngày";
    this.setState({
      startDate: this.changeStartMonth(this.state.startDate),
      endDate: this.changeEndMonth(this.state.endDate),
    });

    this.getRoles(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).setHours(23, 59, 59)
      )
    );
  }
  handlePrint = () => {
    this.printRef.handlePrint();
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.element !== prevProps.element) {
  //     this.setState({startDate:
  //       new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0))
  //       // this.changeStartMonth(new Date())
  //       ,endDate:new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59))
  //       // this.changeEndMonth(new Date())
  //     })
  //     this.getRoles(this.changeStartMonth(new Date()), this.changeEndMonth(new Date()));
  //   }
  // }
  updateItem(newState) {
    this.setState({ role: newState });
  }
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  getSchedule = (item) => {
    this.setState({ showRegisterBlock: true, idRes: item?.scheduleId });
    axios
      .get(`/schedule/${item?.scheduleId}?month=${parseInt(new Date() / 1000)}`)
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ dataRes: res.data.data });
        }
        // this.showDate(this.state.monthChoice)
      });
  };
  CloseRes = () => {
    this.setState({ showRegisterBlock: false });
    this.getRole(this.state.dateSelect);
    this.setState({ detailDate: new Date(this.state.dateSelect * 1000) });
  };
  getRoles = (start, end) => {
    this.setState({ checkload: true });
    axios
      .get(
        `/schedule/daily?startDay=${parseInt(start / 1000)}&endDay=${parseInt(
          end / 1000
        )}&type=${
          this.props.element == "notification"
            ? 1
            : this.state.isNotify
            ? 3
            : this.state.isSocial
            ? 4
            : 2
          //  this.state.isNotify ? 3 : 2
        }`
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({ checkload: false });
          this.setState({ roles: res.data.data });
        }
      });
  };

  getRole = (item) => {
    if (item !== "null") {
      axios
        .get(
          `/schedule/daily/${item}?type=${
            this.props.element == "notification"
              ? 1
              : this.state.isNotify
              ? 3
              : this.state.isSocial
              ? 4
              : 2
          }`
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              itemschedule: res.data.data,
              dateSelect: item,
              // detailDate: new Date(item * 1000),
            });

            this.setState({ modalRoleShow: true });
            this.setState({
              dataDaily: res.data.data?.filter((e) =>
                e?.adPrice?.some((broad) => broad?.id == this.state.idBroadList)
              ),
            });
          }
        });
    }
  };
  getRoleExport = (item, duration) => {
    if (item !== "null") {
      axios
        .get(
          `/schedule/daily/${item}?type=${
            this.props.element == "notification"
              ? 1
              : this.state.isNotify
              ? 3
              : this.state.isSocial
              ? 4
              : 2
          }`
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.setState(
              {
                dataExport: res.data.data,
                date: item,
                countDuration: duration,
              },
              () => {
                this.countRow();
              }
            );

            // this.setState({
            //   dataDaily: res.data.data?.filter((e) =>
            //     e.adPrice?.some((broad) => broad.id == this.state.idBroadList)
            //   ),
            // });
          }
        });
    }
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  formcloseaddRe = () => {
    this.setState({ modalRoleShow: false });
  };
  dataTable() {
    const columns = [
      {
        name: "Thứ",
        selector: (row) => row.date,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDayfromDate(row.date)}
          </div>
        ),
      },
      {
        name: "Ngày",
        selector: (row) => row.date,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {this.getDate(row.date)}
          </div>
        ),
        // sortable: true,
      },
      {
        name: "Số spot",
        selector: (row) => row.totalSpot,
        right: true,
        // sortable: true,
      },
      {
        name: "Thời lượng",
        selector: (row) => row.totalDuration,
        right: true,

        // sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.roles.length > 0) {
      this.state.roles.map((item, i) => {
        data.push({
          id: i + 1,
          date: item.date,
          totalSpot: item.totalSpot,
          totalDuration: item.totalDuration,
          setting: (
            <>
              {/* {new Check().permission(['62']) ? */}
              <Button
                size="sm"
                variant="warning me-2"
                type="button"
                className="btn-setting"
                onClick={() => {
                  this.setState(
                    {
                      detailDate: new Date(item.date * 1000),
                    },
                    () => this.getRole(item.date)
                  );
                }}
                title="Chi tiết lịch"
              >
                <i className="fa-solid fa-info"></i>
              </Button>
              {/* : null} */}
              {/* {new Check().permission(['65']) ? */}
            </>
          ),
        });

        return item;
      });
    }
    const totalQCValue = data.reduce(
      (total, record) => total + record.totalSpot,
      0
    );
    const totalValue = data.reduce(
      (total, record) => total + record.totalDuration,
      0
    );

    const totalRow = {
      id: "total-student",
      date: "Tổng",
      date: "",
      // contractCode: "Tổng",
      totalSpot: totalQCValue,
      totalDuration: totalValue,
      setting: "",
    };
    const handleSelectedRowsChange = (selectedRows) => {
      // this.setState({date:  selectedRows?.selectedRows[0]?.date})
      this.getRoleExport(
        selectedRows?.selectedRows[0]?.date,
        selectedRows?.selectedRows[0]?.totalDuration
      );
      this.setState({ selectedRows: selectedRows?.selectedRows });
    };

    const selectableRowDisabled = (row) => {
      return row?.id == "total-student";
    };
    const conditionalRowStyles = [
      {
        when: (row) =>
          this.state.selectedRows?.some(
            (selectedRow) => selectedRow.id === row.id
          ),
        style: {
          backgroundColor: "rgba(0, 123, 255, 0.2)",
        },
      },
    ];
    const temporaryData = [...data, totalRow];
    return (
      <DataTables
        data={temporaryData}
        columns={columns}
        // selectedRows={selectedRows}
        fixedHeaderScrollHeight={"unset"}
        paginationPerPage={temporaryData?.length}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectableRows
        conditionalRowStyles={conditionalRowStyles}
        selectableRowsSingle
        selectableRowDisabled={selectableRowDisabled}
        paginationRowsPerPageOptions={[temporaryData?.length, 50, 100, 500]}
      />
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.dataDaily !== this.state.dataDaily) {
      // this.getRole(this.state.dateSelect)
    }
  }
  UpdateAll = () => {
    let data = [];
    this.state.itemschedule?.map((item) => {
      item?.broadcastList?.map((broadcastListItem, index) => {
        data.push({
          id: broadcastListItem.id,
          position: index + 1,
          note: broadcastListItem.note,
        });
      });
    });
    const formattedData = {
      broadcastList: data,
    };
    axios
      .put(`/schedule/sort-schedule`, formattedData)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, {
            type: "success",
            autoClose: 1000,
          });
          // this.getRole(this.state.dateSelect)
        } else {
          this.setState({ err: res.data.errorMessage });

          // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });
  };
  updateDaily = (broadcastList) => {
    this.setState({
      dataDaily: this.state.itemschedule?.filter(
        (e) => e.adPrice?.id == this.state.idBroadList
      )[0]?.broadcastList,
    });
    // var data = this.state.itemschedule?.filter(
    //   (e) => e.adPrice?.id == this.state.idBroadList
    // )[0]?.broadcastList;
    var data = broadcastList;
    var arrPost = [];
    data?.map((item, index) => {
      arrPost.push({
        id: item.id,
        position: index + 1,
        note: item.note,
      });
    });

    const formattedBroadcastList = arrPost.map((item) => {
      return {
        id: parseInt(item.id),
        position: item.position,
        note: item.note,
      };
    });
    const formattedData = {
      broadcastList: formattedBroadcastList,
    };
    const dataPost = JSON.stringify(formattedData, null, 2);
    // swal({
    //   title: "Bạn muốn cập nhật lịch ?",
    //   icon: "warning",
    //   buttons: ["Đóng", "Cập nhật"],
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    // this.setState({dataDaily:[]})

    axios
      .put(`/schedule/sort-schedule`, formattedData)
      .then((res) => {
        if (res.data.status == 200) {
          toast(`${res.data.message}`, {
            type: "success",
            autoClose: 1000,
          });
          // this.getRole(this.state.dateSelect)
        } else {
          this.setState({ err: res.data.errorMessage });

          // toast(`${res.data.errorMessage.name}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log(error);
        toast(`Hệ thống xảy ra lỗi!`, { type: "error", autoClose: 1000 });
      });

    // });
  };
  handleOnDragEnd = (result, id) => {
    let data = this.state.itemschedule?.filter((e) => e.adPrice?.id == id)[0]
      ?.broadcastList;
    if (!result.destination) return;
    const items = Array.from(
      this.state.itemschedule?.filter((e) => e.adPrice?.id == id)[0]
        ?.broadcastList
    );
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    // this.setState({
    //   dataDaily: items
    // }, () => {
    // });
    // this.setState({itemschedule:[...itemschedule],broadcastList=items})
    this.state.itemschedule.forEach((item) => {
      var newItem = this.state.itemschedule.find(
        (newItem) => newItem.adPrice?.id == id

        // item.adPrice?.id
      );

      if (newItem && item.adPrice?.id == id) {
        item.broadcastList = items;
        return;
      }
    });

    this.setState({ itemschedule: [...this.state.itemschedule] });
  };

  dataTableDate(broadcastList, idPrice, priceType) {
    return (
      <>
        <DragDropContext
          onDragEnd={(e) => this.handleOnDragEnd(e, idPrice)}
          onDragStart={(e) => this.handleOnDragEnd(e, idPrice)}
        >
          <Droppable droppableId={"Array.from(this.state.dataDaily)"}>
            {(provided) => (
              <>
                {/* <Button
                  variant="warning"
                  style={{ float: "right" }}
                  className="me-2"
                  onClick={() => {
                    this.updateDaily(broadcastList);
                  }}
                >
                  Cập nhật
                </Button> */}
                <Table striped bordered>
                  <thead>
                    <tr>
                      {this.props.element == "notification" ? (
                        <th>Nội dung</th>
                      ) : (
                        <>
                          <th>Sản phẩm</th>
                          <th>Mã băng</th>
                          <th>Nội dung</th>
                          <th>Thời lượng</th>
                        </>
                      )}
                      <th style={{ width: "20px" }}>STT</th>
                      <th style={{ width: "200px" }}>Ghi chú</th>
                    </tr>
                  </thead>
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {
                      // this.state.dataDaily &&
                      //   this.state.itemschedule
                      //     ?.filter(
                      //       (e) => e.adPrice?.id == this.state.idBroadList
                      //     )[0]
                      //     ?.broadcastList?
                      broadcastList?.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // key={item.id}
                                >
                                  {this.props.element == "notification" ? (
                                    <td
                                      onClick={() =>
                                        this.props.element == "notification"
                                          ? this.getSchedule(item)
                                          : null
                                      }
                                    >
                                      {" "}
                                      {priceType == 1
                                        ? item.adBlock?.content
                                        : item.adBlock?.tapeCode?.name}{" "}
                                    </td>
                                  ) : (
                                    <>
                                      <td>
                                        {" "}
                                        {
                                          item.adBlock?.tapeCode?.product?.name
                                        }{" "}
                                      </td>
                                      <td> {item.adBlock?.tapeCode?.code} </td>
                                      <td>
                                        {" "}
                                        {priceType == 1
                                          ? item.adBlock?.content
                                          : item.adBlock?.tapeCode?.name}{" "}
                                      </td>
                                      <td>
                                        {item.adBlock?.tapeCode?.duration}
                                      </td>
                                    </>
                                  )}

                                  <td>{index + 1}</td>
                                  <td>
                                    {/* {item.note} */}

                                    <input
                                      type="text"
                                      style={{ width: "100%" }}
                                      displayType="text"
                                      // defaultValue={item.note || ''}
                                      value={item.note ?? item?.adBlock?.note}
                                      onChange={(e) => {
                                        const { value } = e.target;

                                        this.setState((prevState) => {
                                          const updatedItemschedule =
                                            prevState.itemschedule.map(
                                              (ite) => {
                                                if (
                                                  ite.broadcastList &&
                                                  ite.broadcastList?.some(
                                                    (broadcastItem) =>
                                                      broadcastItem.id ==
                                                      item.id
                                                  )
                                                ) {
                                                  return {
                                                    ...ite,
                                                    broadcastList:
                                                      ite.broadcastList.map(
                                                        (broadcastItem) => {
                                                          if (
                                                            broadcastItem.id ==
                                                            item.id
                                                          ) {
                                                            return {
                                                              ...broadcastItem,
                                                              note: value,
                                                            };
                                                          }
                                                          return broadcastItem;
                                                        }
                                                      ),
                                                  };
                                                }
                                                return ite;
                                              }
                                            );

                                          return {
                                            itemschedule: updatedItemschedule,
                                            dataDaily:
                                              updatedItemschedule?.filter(
                                                (e) =>
                                                  e.adPrice?.id ==
                                                  this.state.idBroadList
                                              )[0]?.broadcastList,
                                          };
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                          </Draggable>
                        );
                      })
                    }
                    {provided.placeholder}
                  </tbody>
                </Table>
              </>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }
  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };
  getDate_ = (data) => {
    if (data) {
      let date = data;
      let result = `${date?.getDate()}/${
        date?.getMonth() + 1
      }/${date?.getFullYear()}`;
      return result;
    } else return "";
  };
  getDate = (data) => {
    if (data > 0) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear()
      );
    } else return data;
  };
  getDateforExcel = (data) => {
    if (data > 0) {
      const date = new Date(data * 1000);
      // date.setHours(0, 0, 0, 0);
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear()
      );
    } else return data;
  };
  getDayfromDateForExcel(data) {
    if (data > 0) {
      var daysOfWeek = [
        "Chủ nhật, ",
        "Thứ hai, ",
        "Thứ ba, ",
        "Thứ tư, ",
        "Thứ năm, ",
        "Thứ sáu, ",
        "Thứ bảy, ",
      ];

      var rs = daysOfWeek[new Date(data * 1000)?.getDay()];
      return rs;
    } else return data;
  }
  getDayfromDate(data) {
    if (data > 0) {
      var daysOfWeek = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

      var rs = daysOfWeek[new Date(data * 1000)?.getDay()];
      return rs;
    } else return data;
  }
  getSumDuration = (data) => {
    let tong = 0;
    data?.map((item) => (tong += item?.adBlock?.tapeCode?.duration || 0));
    return tong;
  };
  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };
  printPdf = () => {
    return (
      <div
        id="exportPDFSchedule"
        style={{
          fontFamily: "Times New Roman, Times, serif",
          color: "#000",
          // padding: "38px 38px 38px 57px",
        }}
        className="d-none"
      >
        <div
          style={{
            display: "flex",
            fontSize: "16px",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          LỊCH{" "}
          {this.props.element == "notification" ? "THÔNG BÁO" : "QUẢNG CÁO"}{" "}
          HÀNG NGÀY KÊNH CTV
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "16px",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {this.getDayfromDateForExcel(this.state.date) +
            " " +
            this.getDate(this.state.date)}
        </div>
        <div style={{ height: "20px" }}></div>
        <div
          style={{
            //  border: '2px solid',
            // borderTop:'1px solid',

            display: "contents",
          }}
          className="tableExport"
        >
          {/* <thead> */}
          <tr
            style={{
              borderBottom: "1px solid #000",
              borderBottomWidth: "1px !important",
              height: "40px",
            }}
          >
            <th
              className="text-center align-middle bordertd"
              style={{ width: "67px" }}
            >
              Mã giờ
            </th>
            <th className="text-center  align-middle" style={{ width: "77px" }}>
              Giờ phát
            </th>
            <th className="text-center  align-middle" style={{ width: "43px" }}>
              Vị trí
            </th>
            <th
              className="text-center  align-middle"
              style={{ width: "295px" }}
            >
              Nội dung
            </th>
            {this.props.element == "notification" ? null : (
              <>
                <th className="text-center  align-middle">Thời lượng</th>
                <th
                  className="text-center  align-middle"
                  style={{ width: "105px" }}
                >
                  Mã băng
                </th>
              </>
            )}

            <th
              className="text-center  align-middle"
              style={{
                width: this.props.element == "notification" ? "197px" : "69px",
              }}
            >
              Ghi chú
            </th>
          </tr>
          {/* </thead> */}
          {/* <tbody> */}
          {this.state.dataExport?.map((item) => {
            return (
              <>
                <tr className="fw-bold align-middle">
                  <td className="bordertd"> {item?.adPrice?.timeCode}</td>
                  <td>{item?.adPrice?.timeStart}</td>
                  <td>{}</td>
                  <td className="text-center"> {item?.adPrice?.name}</td>
                  <td>{}</td>
                  {this.props.element == "notification" ? null : (
                    <>
                      <td></td> <td></td>
                    </>
                  )}
                </tr>
                {item.broadcastList?.map((broadcastItem) => {
                  return (
                    <tr className="align-middle">
                      <td className="bordertd align-middle">{}</td> <td>{}</td>
                      <td className="text-center align-middle">
                        {broadcastItem.position}
                      </td>
                      <td className="align-middle">
                        {this.props.element == "notification" &&
                        item?.adPrice?.priceType != 1
                          ? broadcastItem?.adBlock?.tapeCode?.name
                          : this.props.element == "notification" &&
                            item?.adPrice?.priceType == 1
                          ? broadcastItem?.adBlock?.content
                          : broadcastItem?.adBlock?.tapeCode?.name}
                      </td>
                      {this.props.element == "notification" ? null : (
                        <>
                          <td className="text-center align-middle">
                            {broadcastItem?.adBlock?.tapeCode?.duration}
                          </td>
                          <td className="text-wrap align-middle">
                            {broadcastItem?.adBlock?.tapeCode?.name}
                          </td>
                        </>
                      )}
                      <td>{broadcastItem?.note}</td>
                    </tr>
                  );
                })}
                {this.props.element == "notification" ? null : (
                  <>
                    <tr className="fw-bold">
                      <td colSpan={4} className="text-end bordertd">
                        Tổng thời lượng đoạn:
                      </td>
                      <td className="text-center">
                        {this.getSumDuration(item?.broadcastList)}
                      </td>
                      <td></td> <td></td>
                    </tr>
                  </>
                )}
              </>
            );
          })}
          {this.props.element == "notification" ? null : (
            <tr>
              <td colSpan={4} className="text-end  fw-bold bordertl ">
                Tổng thời lượng:
              </td>

              <td className="text-center borderth  fw-bold">
                {this.state.countDuration}
              </td>
              <td className="text-center borderth"></td>
              <td className="text-center borderth"></td>
            </tr>
          )}
          {/* </tbody> */}
        </div>
        <div>
          {this.props.element == "notification" ? (
            <>
              <div></div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                  marginTop: "15px",
                  border: "none !important",
                }}
              >
                <Col className="text-center">BIÊN TẬP CT TB-QC</Col>
                <Col className="text-center">TP.KH-TC & DV</Col>
                <Col className="text-center">KT.THỦ TRƯỞNG ĐƠN VỊ</Col>
              </Row>
              <div style={{ height: "80px" }}>
                <div></div>
              </div>{" "}
              <div></div> <div></div> <div></div>{" "}
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                  alignItems: "center",
                }}
              >
                <Col></Col>
                <Col className="text-center">Nguyễn Hoàng Sấy</Col>
                <Col className="text-center">Hồ Tấn Lộc</Col>
              </Row>
            </>
          ) : (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                  marginTop: "15px",
                }}
              >
                <Col className="text-center">BIÊN TẬP CT TB-QC</Col>
                <Col className="text-center">TP.KH-TC & DV</Col>
                <Col className="text-center">KT.THỦ TRƯỞNG ĐƠN VỊ</Col>
              </Row>
              <div style={{ height: "80px" }}></div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                  alignItems: "center",
                }}
              >
                <Col></Col>
                <Col className="text-center">Nguyễn Hoàng Sấy</Col>
                <Col className="text-center">Hồ Tấn Lộc</Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  };
  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false });
      this.refreshRole();
      this.getRoles(this.state.startDate, this.state.endDate);
    };

    return (
      <>
        <Card.Header className=" cardheaderfix bg-light">
          <Card.Title className="d-md-flex align-items-center">
            <i className="fas fa-list me-1"></i>
            {this.props.element == "notification"
              ? "Thông báo hàng ngày "
              : "Lịch quảng cáo hàng ngày "}
            {/* {this.state.startDate ? this.getDate_(this.state.startDate) : ""} -{" "}
            {this.state.endDate ? this.getDate_(this.state.endDate) : "--"}{" "} */}
            <div className="d-flex">
              <div style={{ zIndex: 3 }} className="ms-1">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) =>
                    this.setState(
                      { startDate: new Date(date.setHours(0, 0, 0)) },
                      () => {
                        this.getRoles(
                          new Date(this.state.startDate),
                          new Date(this.state.endDate)
                        );
                      }
                    )
                  }
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>{" "}
              <div style={{ zIndex: 2, marginLeft: "5px" }}>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) =>
                    this.setState(
                      { endDate: new Date(date.setHours(23, 59, 59)) },
                      () => {
                        this.getRoles(
                          new Date(this.state.startDate),
                          new Date(this.state.endDate)
                        );
                      }
                    )
                  }
                  dateFormat="dd/MM/yyyy"
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                />
              </div>
            </div>
            <div className="d-flex ms-0">
              {this.props.element == "notification" ? null : (
                <>
                  <Form.Check
                    inline
                    label="Phát thanh"
                    name="group1"
                    style={{ whiteSpace: "nowrap" }}
                    className="m-1 mt-2"
                    id={`inline-1`}
                    onChange={() =>
                      this.setState({ isNotify: !this.state.isNotify }, () =>
                        this.getRoles(this.state.startDate, this.state.endDate)
                      )
                    }
                    checked={this.state.isNotify}
                  />
                  <Form.Check
                    inline
                    label="Mạng xã hội"
                    name="group1"
                    style={{ whiteSpace: "nowrap" }}
                    className="m-1 mt-2"
                    id={`inline-1`}
                    onChange={() =>
                      this.setState({ isSocial: !this.state.isSocial }, () =>
                        this.getRoles(this.state.startDate, this.state.endDate)
                      )
                    }
                    checked={this.state.isSocial}
                  />
                </>
              )}
              {/* <Button
                variant="danger"
                size="sm"
                style={{ float: "right", whiteSpace: "nowrap" }}
                className="m-1"
                // className="btn-setting"
                disabled={this.state.selectedRows?.length < 1}
                title={this.props.element == "notification" ? "Xuất PDF" : "Xuất PDF"}
                onClick={() => this.xportPDF()}
              >
                <i className="fa-solid fa-file-export"></i> Xuất PDF
              
      
              </Button> */}
              <PrintButton
                data={this.state.dataExport}
                page={this.props.element}
                date={this.state.date}
                countDuration={this.state.countDuration}
              />

              <Button
                variant="success"
                size="sm"
                className="m-1"
                style={{ float: "right", whiteSpace: "nowrap" }}
                // className="btn-setting"
                disabled={this.state.selectedRows?.length < 1}
                title={
                  this.props.element == "notification"
                    ? "Xuất thông báo hàng ngày"
                    : "Xuất quảng cáo hàng ngày"
                }
                onClick={() => this.xport()}
              >
                <i className="fa-solid fa-file-export"></i> Xuất{" "}
                {this.props.element == "notification"
                  ? "thông báo hàng ngày "
                  : "quảng cáo hàng ngày "}
              </Button>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body className="col-md-8 cardbodyfixShedule">
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>
      </>
    );
  }
  xportPDF = () => {
    const element = document.getElementById("exportPDFSchedule"); // Replace with the ID of the HTML element you want to convert
    var clonedElement = element.cloneNode(true);

    clonedElement.classList.remove("d-none");

    const opt = {
      margin: 15,
      filename: `Lich  ${
        (this.props.element == "notification" ? "TB " : "QC ") +
        this.getDate(this.state.date)
      }.pdf`,
      // image: { type: "jpeg", quality: 1 },
      // html2canvas: { scale: 2 },
      html2canvas: {
        dpi: 600,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(clonedElement).set(opt).save();

    // html2pdf(element, opt);

    clonedElement.remove();
  };
  xport = () => {
    // swal({
    //   title:
    //     this.props.element == "notification"
    //       ? "Xác nhận xuất thông báo hàng ngày"
    //       : "Xác nhận xuất quảng cáo hàng ngày",
    //   icon: "info",
    //   buttons: true,
    //   successMode: true,
    // }).then((ok) => {
    //   if (ok) {
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(
      wb,
      this.listDailyExport(),
      this.props.element == "notification"
        ? "Thông báo hàng ngày"
        : "Quảng cáo hàng ngày"
    );

    XLSX.writeFile(
      wb,
      this.props.element == "notification"
        ? `Lich TB ${this.getDateforExcel(this.state.date)}.xlsx`
        : `Lich QC ${this.getDateforExcel(this.state.date)}.xlsx`
    );
    //   }
    // });
  };
  listDailyExport() {
    const table = document.getElementById("exportScheduleDaily");
    const wb = XLSX.utils.table_to_book(table, { raw: true });
    var ws = wb.Sheets["Sheet1"];
    let colAlpha = [];
    this.props.element == "notification"
      ? (colAlpha = ["A", "B", "C", "D", "E"])
      : (colAlpha = ["A", "B", "C", "D", "E", "F", "G"]);

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A2`].s = {
      font: {
        name: "Times New Roman",
        sz: 14,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    colAlpha?.map((col) => {
      ws[`${col}4`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
    });
    ws[
      `A${
        7 +
        this.countRow() -
        Number(this.props.element == "notification" ? 1 : 0)
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[
      `${this.props.element == "notification" ? "D" : "D"}${
        7 +
        this.countRow() -
        Number(this.props.element == "notification" ? 1 : 0)
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[
      `${this.props.element == "notification" ? "E" : "E"}${
        7 +
        this.countRow() -
        Number(this.props.element == "notification" ? 1 : 0)
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[
      `${this.props.element == "notification" ? "D" : "D"}${
        12 +
        this.countRow() -
        Number(this.props.element == "notification" ? 1 : 0)
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[
      `${this.props.element == "notification" ? "E" : "E"}${
        12 +
        this.countRow() -
        Number(this.props.element == "notification" ? 1 : 0)
      }`
    ].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws["!rows"] = [{ hpt: "" }, { hpt: "" }, { hpt: "" }, { hpt: 30 }];
    for (
      let i = 5;
      i <
      6 +
        this.countRow() -
        Number(this.props.element == "notification" ? 1 : 0);
      i++
    ) {
      colAlpha?.map((col) => {
        if (ws[`${col}${i}`].v == "empty") ws[`${col}${i}`].v = "";
        ws[`${col}${i}`].s = {
          font: {
            name: "Times New Roman",
            bold: ws[`A${i}`].v == "" ? false : true,
            sz: 12,
          },
          alignment: {
            wrapText:
              col == "D" || col == "F" || col == "A" || col == "G"
                ? true
                : false,
            vertical: "center",
            horizontal:
              col == "E" && this.props.element != "notification"
                ? "center"
                : col == "C"
                ? "center"
                : ws[`A${i}`].v == ""
                ? "left"
                : col === "D"
                ? "center"
                : col == "E" && this.props.element != "notification"
                ? "center"
                : col === "C"
                ? "center"
                : "left",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
      });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const fontSize = 12;
      context.font = fontSize + "px Times New Roman";
      const textWidth = context.measureText(ws[`D${i}`].v).width;
      let rowLabel = Math.ceil(textWidth / 208);

      let heightLabel = 30;
      if (rowLabel == 1) heightLabel = 30;
      else if (rowLabel == 2) heightLabel = 46.8;
      else heightLabel = heightLabel * rowLabel - 20;

      if (ws[`A${i}`].v != "" && this.props.element == "notification")
        ws["!rows"][i - 1] = { hpt: heightLabel };
    }

    ws["!cols"] = [
      { wch: 8.11 },
      { wch: 9.11 },
      { wch: 5 },
      { wch: this.props.element == "notification" ? 39.3 : 34.3 },
      { wch: this.props.element == "notification" ? 26.5 : 7 },
      { wch: 14 },
      { wch: 9 },
    ];

    const durationPositionList =
      this.findCellsByValue(ws, "Tổng thời lượng đoạn:") || [];

    durationPositionList.forEach((durationPositon) => {
      const numPos = this.extractNumber(durationPositon);
      ws["!merges"].push({
        s: { r: numPos - 1, c: 0 },
        e: { r: numPos - 1, c: 3 },
      });
      ws[durationPositon].s = {
        ...ws[durationPositon].s,
        alignment: {
          vertical: "right",
          horizontal: "right",
        },
      };
    });
    const totalDuration1 = this.findCellsByValue(ws, "Tổng thời lượng:") || [];

    totalDuration1.forEach((durationPositon) => {
      const numPos = this.extractNumber(durationPositon);
      ws["!merges"].push({
        s: { r: numPos - 1, c: 0 },
        e: { r: numPos - 1, c: 3 },
      });
      // ws["!merges"].push({
      //   s: { r: numPos - 1, c: 4 },
      //   e: { r: numPos - 1, c: 6 },
      // });

      ws[totalDuration1].s = {
        ...ws[totalDuration1].s,
        font: {
          bold: true,
          name: "Times New Roman",
          sz: 12,
        },
        alignment: {
          vertical: "right",
          horizontal: "right",
        },
      };
    });
    ws["!margins"] = {
      left: 1.5 / 2.54,
      right: 1.0 / 2.54,
      top: 1.0 / 2.54,
      bottom: 1.0 / 2.54,
    };

    return ws;
  }
  findCellByValue = (data, targetValue) => {
    for (const cellRef in data) {
      if (
        data.hasOwnProperty(cellRef) &&
        data[cellRef].t === "s" &&
        data[cellRef].v == targetValue
      ) {
        return cellRef;
      }
    }
    return null; // Return null if the target value is not found
  };

  findCellsByValue = (data, targetValue) => {
    const cellsWithTargetValue = [];

    for (const cellRef in data) {
      if (data.hasOwnProperty(cellRef) && data[cellRef].v === targetValue) {
        cellsWithTargetValue.push(cellRef);
      }
    }

    return cellsWithTargetValue.length > 0 ? cellsWithTargetValue : null;
  };
  extractNumber = (inputString) => {
    const numberPart = inputString.replace(/[^\d]/g, "");
    return parseInt(numberPart, 10);
  };

  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined" ||
      element == "aN/aN/NaN"
      ? "empty"
      : element;
  };
  countRow() {
    let data = this.state.dataExport;
    let tong = 0;
    this.props.element == "notification"
      ? (tong = data?.length)
      : (tong = data?.length * 2);
    data?.map((item) => (tong += item.broadcastList?.length));
    return tong;
  }
  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>
              {this.props.element == "notification" ? "Thông báo" : "Quảng cáo"}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {this.props.element == "notification"
                ? "Thông báo hàng ngày"
                : "Lịch quảng cáo hàng ngày"}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4 ">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
        {this.state.modalRoleShow ? (
          <Modal
            show={this.state.modalRoleShow}
            onHide={this.formcloseaddRe}
            size="xl"
            backdrop="static"
            keyboard={false}
            // className="modal-in-contract"
            // fullscreen={true}
          >
            <Modal.Header closeButton>
              <Modal.Title className="d-flex align-items-center">
                <span style={{ width: "120px", paddingTop: "1px" }}>
                  Chi tiết ngày
                </span>

                <span style={{ width: "", zIndex: 9 }}>
                  <Form.Floating className="m">
                    <input
                      type="date"
                      name="dateOfBirth"
                      value={moment(this.state.detailDate)?.format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        if (e.target.value)
                          this.setState(
                            {
                              detailDate: new Date(e.target.value),
                              dateSelect: new Date(e.target.value) / 1000,
                            },
                            () => this.getRole(new Date(e.target.value) / 1000)
                          );
                      }}
                    />
                  </Form.Floating>
                  {/* <DatePicker
                    selected={this.state.detailDate}
                    value={this.state.detailDate}
                    onChange={(date) =>
                      this.setState({ detailDate: date, dateSelect: date / 1000 }, () => this.getRole(date / 1000))
                    }
                    style={{ marginTop: "11px" }}
                    dateFormat={"dd/MM/yyyy"}
                    placeholderText="dd/mm/yyyy"
                  /> */}
                </span>

                {/* {" "}{ this.getDate(this.state.dateSelect)} */}
                <span className="d-none d-md-block">
                  {new Check().permission(["63"]) ? (
                    <Button
                      style={{ float: "right" }}
                      variant="warning"
                      className="ms-2"
                      title="Cập nhật tất cả"
                      onClick={() => {
                        this.UpdateAll();
                      }}
                    >
                      Cập nhật tất cả
                    </Button>
                  ) : null}
                </span>
                <span className="d-none d-md-block">
                  <PrintButton
                    data={this.state.itemschedule}
                    page={this.props.element}
                    date={this.state.detailDate / 1000}
                    countDuration={this.state.countDuration}
                  />
                </span>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <i className="text-end mb-2">Kéo thả để sắp xếp lịch</i>

              <Accordion
                className="schedule"
                activeKey={this.state.itemschedule?.map(
                  (item, i) => item.adPrice?.id
                )}
              >
                {this.state.itemschedule?.map((item, i) => {
                  return (
                    <Accordion.Item
                      eventKey={item.adPrice?.id}
                      key={item.id + i}
                      onClick={() => {
                        this.setState({
                          idBroadList: item.adPrice?.id,
                          dataDaily: item?.broadcastList?.map((it) => {
                            return { ...it, id: it.id.toString() };
                          }),
                        });
                      }}
                    >
                      <Accordion.Header
                        onClick={() => {
                          this.setState({ idBroadList: item.adPrice?.id });
                        }}
                      >
                        <span style={{ fontWeight: "700", color: "#000" }}>
                          {item.adPrice?.timeCode +
                            "-" +
                            item.adPrice?.name +
                            " (" +
                            item.adPrice?.timeStart +
                            ") "}
                        </span>
                        {new Check().permission(["63"]) ? (
                          <Button
                            variant="warning"
                            // style={{ float: "right" }}
                            className="me-2 p-1"
                            style={{ margin: "3px 0", width: "77px" }}
                            onClick={() => {
                              this.updateDaily(item.broadcastList);
                            }}
                          >
                            Cập nhật
                          </Button>
                        ) : null}
                      </Accordion.Header>
                      <Accordion.Body className="p-2">
                        {this.dataTableDate(
                          item?.broadcastList,
                          item?.adPrice?.id,
                          item?.adPrice?.priceType
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                onClick={() =>
                  this.setState({
                    modalRoleShow: false,
                    detailContractItem: {},
                  })
                }
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePicker
                size={{ height: "600px" }}
                selected={this.state.start_}
                onChange={(update) => {
                  this.setState({ start_: update[0] });
                  this.setState({ end_: update[1] });
                }}
                startDate={this.state.start_}
                endDate={this.state.end_}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: false });
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={this.state.start_ === null || this.state.end_ === null}
              onClick={() => {
                this.setState({ show: false });
                this.setState({
                  startDate: new Date(this.state.start_.setHours(0, 0, 0)),
                });
                this.setState({
                  endDate: new Date(this.state.end_?.setHours(23, 59, 59)),
                });
                this.getRoles(
                  new Date(this.state.start_.setHours(0, 0, 0)),
                  new Date(this.state.end_?.setHours(23, 59, 59))
                );
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.showRegisterBlock ? (
          <>
            <RegisterBlockFormModal
              show={this.state.showRegisterBlock}
              onHide={this.CloseRes}
              modal={
                this.state.role.itemShedule == ""
                  ? {
                      title: "Đăng ký lịch",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Đăng ký lịch",
                      button: (
                        <>
                          <i className="fa-solid fa-pencil" /> Cập nhật
                        </>
                      ),
                    }
              }
              datarole={this.state.dataRes}
              idRes={this.state.idRes}
              dateStart={this.state.detailDate}
              // submit={modalSubmit}
              onUpdateItem={this.updateItem}
            />
          </>
        ) : null}
        <Table className="d-none" id="exportScheduleDaily">
          <thead>
            <tr>
              <th colSpan={this.props.element == "notification" ? 5 : 7}>
                LỊCH{" "}
                {this.props.element == "notification"
                  ? "THÔNG BÁO"
                  : "QUẢNG CÁO"}{" "}
                HÀNG NGÀY KÊNH CTV
              </th>
            </tr>
            <tr>
              <th colSpan={this.props.element == "notification" ? 5 : 7}>
                {this.getDayfromDateForExcel(this.state.date) +
                  " " +
                  this.getDate(this.state.date)}
              </th>
            </tr>
            <tr>
              <th colSpan={this.props.element == "notification" ? 5 : 7}> </th>
            </tr>
            <tr>
              <th>Mã giờ</th>
              <th>Giờ phát</th>
              <th>Vị trí</th>
              <th>Nội dung</th>
              {this.props.element == "notification" ? null : (
                <>
                  <th>Thời lượng</th>
                  <th>Mã băng</th>
                </>
              )}

              <th>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {this.state.dataExport?.map((item) => {
              return (
                <>
                  <tr>
                    <td>{this.changeEmpty(item?.adPrice?.timeCode)}</td>
                    <td>{this.changeEmpty(item?.adPrice?.timeStart)}</td>
                    <td>{this.changeEmpty()}</td>
                    <td> {this.changeEmpty(item?.adPrice?.name)}</td>
                    <td>{this.changeEmpty()}</td>
                    {this.props.element == "notification" ? null : (
                      <>
                        <td>{this.changeEmpty()}</td>{" "}
                        <td>{this.changeEmpty()}</td>
                      </>
                    )}
                  </tr>
                  {item.broadcastList?.map((broadcastItem) => {
                    return (
                      <tr>
                        <td>{this.changeEmpty()}</td>{" "}
                        <td>{this.changeEmpty()}</td>
                        <td>{this.changeEmpty(broadcastItem.position)}</td>
                        <td>
                          {this.changeEmpty(
                            this.props.element == "notification" &&
                              item?.adPrice?.priceType != 1
                              ? broadcastItem?.adBlock?.tapeCode?.name
                              : this.props.element == "notification" &&
                                item?.adPrice?.priceType == 1
                              ? broadcastItem?.adBlock?.content
                              : broadcastItem?.adBlock?.tapeCode?.name
                          )}
                        </td>
                        {this.props.element == "notification" ? null : (
                          <>
                            <td>
                              {this.changeEmpty(
                                broadcastItem?.adBlock?.tapeCode?.duration
                              )}
                            </td>
                            <td>
                              {this.changeEmpty(
                                broadcastItem?.adBlock?.tapeCode?.name
                              )}
                            </td>
                          </>
                        )}
                        <td>
                          {this.changeEmpty(
                            broadcastItem?.note ?? broadcastItem.adBlock?.note
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {this.props.element == "notification" ? null : (
                    <>
                      <tr>
                        <td>Tổng thời lượng đoạn:</td>
                        <td>{this.changeEmpty()}</td>{" "}
                        <td>{this.changeEmpty()}</td>{" "}
                        <td>{this.changeEmpty()}</td>
                        <td>
                          {this.changeEmpty(
                            this.getSumDuration(item?.broadcastList)
                          )}
                        </td>
                        <td>{this.changeEmpty()}</td>{" "}
                        <td>{this.changeEmpty()}</td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
            {this.props.element == "notification" ? null : (
              <tr>
                <th>Tổng thời lượng:</th>
                <td>{this.changeEmpty()}</td> <td>{this.changeEmpty()}</td>{" "}
                <td>{this.changeEmpty()}</td>
                <th>{this.state.countDuration}</th>{" "}
                <td>{this.changeEmpty()}</td> <td>{this.changeEmpty()}</td>
              </tr>
            )}
            {this.props.element == "notification" ? (
              <>
                <tr>
                  <td colSpan={5}></td>
                </tr>
                <tr>
                  <td colSpan={3}>BIÊN TẬP CT TB-QC</td>
                  <td colSpan={1}>TP.KH-TC & DV</td>
                  <td colSpan={1}>KT.THỦ TRƯỞNG ĐƠN VỊ</td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                </tr>{" "}
                <tr>
                  <td colSpan={5}></td>
                </tr>{" "}
                <tr>
                  <td colSpan={5}></td>
                </tr>{" "}
                <tr>
                  <td colSpan={5}></td>
                </tr>{" "}
                <tr>
                  <td colSpan={3}></td>
                  <td colSpan={1}>Nguyễn Hoàng Sấy</td>
                  <td colSpan={1}>Hồ Tấn Lộc</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td colSpan={7}></td>
                </tr>{" "}
                <tr>
                  <td colSpan={3}>BIÊN TẬP CT TB-QC</td>
                  <td colSpan={1}>TP TC-HC VÀ DỊCH VỤ</td>
                  <td colSpan={3}>KT.THỦ TRƯỞNG ĐƠN VỊ</td>
                </tr>
                <tr>
                  <td colSpan={7}></td>
                </tr>{" "}
                <tr>
                  <td colSpan={7}></td>
                </tr>
                <tr>
                  <td colSpan={7}></td>
                </tr>
                <tr>
                  <td colSpan={7}></td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <td colSpan={1}>Nguyễn Hoàng Sấy</td>
                  <td colSpan={3}>Hồ Tấn Lộc</td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
        {/* <ReactToPrint
          trigger={() => <button>Print</button>}
          content={() => this.componentRef}
        /> */}

        {/* <ReactToPrint
          // content={this.printPdf}
          content={() => this.componentRef}
          documentTitle="AwesomeFileName"
          // onAfterPrint={this.handleAfterPrint}
          // onBeforeGetContent={this.handleOnBeforeGetContent}
          // onBeforePrint={this.handleBeforePrint}
          removeAfterPrint
          // trigger={this.reactToPrintTrigger}
        /> */}

        <div
          id="exportPDFSchedule"
          style={{
            fontFamily: "Times New Roman, Times, serif",
            color: "#000",
            // padding: "38px 38px 38px 57px",
          }}
          className="d-none"
          // ref={this.setComponentRef}
        >
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            LỊCH{" "}
            {this.props.element == "notification" ? "THÔNG BÁO" : "QUẢNG CÁO"}{" "}
            HÀNG NGÀY KÊNH CTV
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            {this.getDayfromDateForExcel(this.state.date) +
              " " +
              this.getDate(this.state.date)}
          </div>
          <div style={{ height: "20px" }}></div>
          <div
            style={{
              //  border: '2px solid',
              // borderTop:'1px solid',

              display: "contents",
            }}
            className="tableExport"
          >
            {/* <thead> */}
            <tr
              style={{
                borderBottom: "1px solid #000",
                borderBottomWidth: "1px !important",
                height: "40px",
              }}
            >
              <th
                className="text-center align-middle bordertd"
                style={{ width: "67px" }}
              >
                Mã giờ
              </th>
              <th
                className="text-center  align-middle"
                style={{ width: "77px" }}
              >
                Giờ phát
              </th>
              <th
                className="text-center  align-middle"
                style={{ width: "43px" }}
              >
                Vị trí
              </th>
              <th
                className="text-center  align-middle"
                style={{ width: "295px" }}
              >
                Nội dung
              </th>
              {this.props.element == "notification" ? null : (
                <>
                  <th className="text-center  align-middle">Thời lượng</th>
                  <th
                    className="text-center  align-middle"
                    style={{ width: "105px" }}
                  >
                    Mã băng
                  </th>
                </>
              )}

              <th
                className="text-center  align-middle"
                style={{
                  width:
                    this.props.element == "notification" ? "197px" : "69px",
                }}
              >
                Ghi chú
              </th>
            </tr>
            {/* </thead> */}
            {/* <tbody> */}
            {this.state.dataExport?.map((item) => {
              return (
                <>
                  <tr className="fw-bold align-middle">
                    <td className="bordertd"> {item?.adPrice?.timeCode}</td>
                    <td>{item?.adPrice?.timeStart}</td>
                    <td>{}</td>
                    <td className="text-center"> {item?.adPrice?.name}</td>
                    <td>{}</td>
                    {this.props.element == "notification" ? null : (
                      <>
                        <td></td> <td></td>
                      </>
                    )}
                  </tr>
                  {item.broadcastList?.map((broadcastItem) => {
                    return (
                      <tr className="align-middle">
                        <td className="bordertd align-middle">{}</td>{" "}
                        <td>{}</td>
                        <td className="text-center align-middle">
                          {broadcastItem.position}
                        </td>
                        <td className="align-middle">
                          {this.props.element == "notification" &&
                          item?.adPrice?.priceType != 1
                            ? broadcastItem?.adBlock?.tapeCode?.name
                            : this.props.element == "notification" &&
                              item?.adPrice?.priceType == 1
                            ? broadcastItem?.adBlock?.content
                            : broadcastItem?.adBlock?.tapeCode?.name}
                        </td>
                        {this.props.element == "notification" ? null : (
                          <>
                            <td className="text-center align-middle">
                              {broadcastItem?.adBlock?.tapeCode?.duration}
                            </td>
                            <td className="text-wrap align-middle">
                              {broadcastItem?.adBlock?.tapeCode?.name}
                            </td>
                          </>
                        )}
                        <td>{broadcastItem?.note}</td>
                      </tr>
                    );
                  })}
                  {this.props.element == "notification" ? null : (
                    <>
                      <tr className="fw-bold">
                        <td colSpan={4} className="text-end bordertd">
                          Tổng thời lượng đoạn:
                        </td>
                        <td className="text-center">
                          {this.getSumDuration(item?.broadcastList)}
                        </td>
                        <td></td> <td></td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
            {this.props.element == "notification" ? null : (
              <tr>
                <td colSpan={4} className="text-end  fw-bold bordertl ">
                  Tổng thời lượng:
                </td>

                <td className="text-center borderth  fw-bold">
                  {this.state.countDuration}
                </td>
                <td className="text-center borderth"></td>
                <td className="text-center borderth"></td>
              </tr>
            )}
            {/* </tbody> */}
          </div>
          <div>
            {this.props.element == "notification" ? (
              <>
                <div></div>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    marginTop: "15px",
                    border: "none !important",
                  }}
                >
                  <Col className="text-center">BIÊN TẬP CT TB-QC</Col>
                  <Col className="text-center">TP.KH-TC & DV</Col>
                  <Col className="text-center">KT.THỦ TRƯỞNG ĐƠN VỊ</Col>
                </Row>
                <div style={{ height: "80px" }}>
                  <div></div>
                </div>{" "}
                <div></div> <div></div> <div></div>{" "}
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Col></Col>
                  <Col className="text-center">Nguyễn Hoàng Sấy</Col>
                  <Col className="text-center">Hồ Tấn Lộc</Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    marginTop: "15px",
                  }}
                >
                  <Col className="text-center">BIÊN TẬP CT TB-QC</Col>
                  <Col className="text-center">TP.KH-TC & DV</Col>
                  <Col className="text-center">KT.THỦ TRƯỞNG ĐƠN VỊ</Col>
                </Row>
                <div style={{ height: "80px" }}></div>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <Col></Col>
                  <Col className="text-center">Nguyễn Hoàng Sấy</Col>
                  <Col className="text-center">Hồ Tấn Lộc</Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ScheduleDaily;
