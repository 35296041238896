import axios from "axios";
import React, { useState, useEffect } from "react";
import Footer from "../../../layouts/frontend/Footer";
import Navbar from "../../../layouts/frontend/Navbar";
import { toast } from "react-toastify";
import { Button, Card, Col, Container, Form, InputGroup, Nav, Row } from "react-bootstrap";
// import 'react-toastify/dist/ReactToastify.css';
// import Loader from "../../../layouts/frontend/Loader";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
function Login() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Đăng nhập";
        // checkLogin();
    }, []);

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [loginInput, setLogin] = useState({
        type: 2,
        account: "",
        //  localStorage.getItem("auth_account") || "",
        numberphone: "",
        //  localStorage.getItem("auth_phone") || "",
        password: "",
        error_list: [],
    });

    const setType = (num) => {
        setLogin({ ...loginInput, type: num });
    };

    const handleInput = (e) => {
        e.persist();

        let field = e.target.name;
        let value = e.target.value;

        setLogin({ ...loginInput, [field]: value });
    };

    const loginSubmit = (e) => {
        e.preventDefault();
        let data = {
            userName: loginInput.account,
            password: loginInput.password,
        };

        axios
            .post("/account/login", data)
            .then((res) => {
                if (res.data.status == 200) {
                    toast(res.data.message, { type: "success", autoClose: 1000 });
                    localStorage.setItem("auth_name_ctv", res.data.data?.account.user?.fullName);
                    localStorage.setItem("role_id_ctv", res.data.data.account?.roleId);
                    localStorage.setItem("user_id_ctv", res.data.data.account?.userId);
                    localStorage.setItem("detail_user_ctv", JSON.stringify(res.data.data));

                    localStorage.setItem("access_token_ctv", res.data.data.token);
                    localStorage.setItem(
                        "permission_ctv",
                        res.data.data?.account?.permissions?.map((item) => {
                            return item.permissionId;
                        })
                    );
                    window.location.href = "/admin";
                } else {
                    toast(res.data.errorMessage, { type: "error", autoClose: 1000 });
                }
            })
            .catch((error) => {
                toast("Hệ thống xảy ra lỗi", { type: "error", autoClose: 1000 });

                // toast(error.message, { type: "error", autoClose: 1000 });
                // console.log(error);
            });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Navbar />
            <div></div>
            <div id="content" style={{ flexGrow: "1" }}>
                <main>
                    <Container className="py-5" id="login">
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <Card className="text-center">
                                    <Form onSubmit={loginSubmit}>
                                        <Card.Header>
                                            <center>
                                                <Nav.Link
                                                    style={{ fontWeight: "bold", textAlign: "center" }}
                                                    eventKey={"user1"}
                                                    onClick={() => setType(1)}
                                                >
                                                    Đăng nhập
                                                </Nav.Link>
                                            </center>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Floating className="mb-3">
                                                <Form.Control
                                                    onChange={handleInput}
                                                    // defaultValue={loginInput.account}
                                                    // defaultValue={""}
                                                    value={loginInput.account == "" ? "" : loginInput.account}
                                                    name="account"
                                                    type={loginInput.type === 1 ? "hidden" : "text"}
                                                    required={loginInput.type === 1 ? false : true}
                                                    placeholder="Tài khoản"
                                                    autoComplete="off"
                                                />
                                                <Form.Label>
                                                    <i className="fa-solid fa-user" /> Tài khoản
                                                </Form.Label>
                                            </Form.Floating>

                                            <Form.Floating className="mb-3">
                                                <InputGroup className="has-validation w-100 is-invalid">
                                                    <Form.Floating style={{ width: "calc(100% - 46px)" }}>
                                                        <Form.Control
                                                            onChange={handleInput}
                                                            defaultValue={loginInput.password}
                                                            type={passwordShown ? "text" : "password"}
                                                            name="password"
                                                            className="border-end-0"
                                                            placeholder="Mật Khẩu"
                                                            autoComplete="off"
                                                            // autoComplete="new-password"
                                                            required
                                                        />
                                                        <Form.Label>
                                                            <i className="fa-solid fa-key" /> Mật khẩu
                                                        </Form.Label>
                                                    </Form.Floating>

                                                    <InputGroup.Text onClick={togglePassword} style={{ width: "46px" }}>
                                                        <i
                                                            className={
                                                                passwordShown
                                                                    ? "fa-regular fa-eye"
                                                                    : "fa-regular fa-eye-slash"
                                                            }
                                                        />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Floating>
                                        </Card.Body>

                                        <Card.Footer>
                                            <Col sm={6} className="d-grid gap-2 mx-auto">
                                                <Button size="sm" type="submit" variant="success">
                                                    Đăng nhập <i className="fa-solid fa-right-to-bracket" />
                                                </Button>
                                            </Col>
                                        </Card.Footer>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <div className="text-center" style={{ fontSize: "15px" }}>
                            {" "}
                            <Link to={`https://katec.vn/`} target="_blank" className="">
                                Copyright © 2023 by Katec.
                            </Link>
                            {/* <a href="tel:+84889881010"> Liên hệ hotline: 0889881010</a> */}
                        </div>
                    </Container>
                </main>
            </div>

            <Footer />
        </div>
    );
}

export default Login;
